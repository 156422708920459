import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Element from "element-ui";
import axios from "axios";
import Request from './api/axios';
import "element-ui/lib/theme-chalk/index.css";
import "./assets/style/index.css"

Vue.config.productionTip = false;
Vue.prototype.$axios = axios;
Vue.prototype.$request = Request;
Vue.use(Element);

const vm = new Vue({
  router,
  store,
  axios,
  render: (h) => h(App),
}).$mount("#app");
