import Vue from "vue";
import VueRouter from "vue-router";
import Element from "element-ui";
import store from "../store";
import { isLogin as _isLogin } from "../utils/auth";

import Frame from "../components/frame.vue";
import Home from "../views/home/home.vue";
import UserAgreement from "../views/home/userAgreement.vue";
import PrivacyPolicy from "../views/home/privacyPolicy.vue";
import BannerIntroPage from "../views/home/bannerIntroPage.vue";

import StudyCenter from "../views/manageCenter";
import MyCourse from "../views/manageCenter/myCourse.vue";
import MyCourseDetail from "../views/manageCenter/courseDetail.vue";
import MyInfo from "../views/manageCenter/myInfo.vue";
import ClassList from "../views/manageCenter/classList.vue";
import ClassCourseList from "../views/manageCenter/classCourseList.vue";
import StudentList from "../views/manageCenter/studentList.vue";
import CourseOrder from "../views/manageCenter/courseOrder.vue";
import PayPage from "../views/manageCenter/payPage.vue";
import DemandList from "../views/manageCenter/demandList.vue";
import StudentConsume from "../views/manageCenter/studentConsume.vue";
import ConsumeDetail from "../views/manageCenter/consumeDetail.vue";
import ChangeCode from "../views/manageCenter/changeCode.vue";

import CourseCenter from "../views/courseCenter/index.vue";
import CourseDetail from "../views/courseCenter/courseDetail.vue";
import Demand from "../views/courseCenter/demand.vue";
import PlayRoom from "../views/courseCenter/playRoom.vue";
import PayOrder from "../views/courseCenter/payOrder.vue";
import PayResult from "../views/courseCenter/payResult.vue";

Vue.use(VueRouter);
//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push;
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const routes = [
  {
    path: "/user-agreement",
    name: "userAgreement",
    component: UserAgreement,
  },
  {
    path: "/privacy-policy",
    name: "privacyPolicy",
    component: PrivacyPolicy,
  },
  {
    path: "/",
    redirect: "/frame",
  },
  {
    path: "/frame",
    name: "frame",
    redirect: "/home",
    component: Frame,
    children: [
      {
        path: "/home",
        name: "home",
        component: Home,
      },
      {
        path: "/banner-page",
        name: "bannerPage",
        component: BannerIntroPage,
      },
      {
        path: "/course-center",
        name: "courseCenter",
        component: CourseCenter,
        children: [
          {
            path: "/course-center/detail",
            name: "courseDetail",
            component: CourseDetail,
          },
          {
            path: "/course-center/demand",
            name: "demand",
            component: Demand,
            meta: { needLogin: true },
          },
          {
            path: "/course-center/play-room",
            name: "playRoom",
            component: PlayRoom,
            meta: { needLogin: true },
          },
          {
            path: "/course-center/pay-order",
            name: "payOrder",
            component: PayOrder,
            meta: { needLogin: true },
          },
          {
            path: "/course-center/pay-result",
            name: "payResult",
            component: PayResult,
            meta: { needLogin: true },
          },
        ],
      },
      {
        path: "/manage-center",
        name: "studyCenter",
        redirect: "/manage-center/my-course",
        component: StudyCenter,
        meta: { needLogin: true },
        children: [
          {
            path: "/manage-center/my-course",
            name: "myCourse",
            meta: { needLogin: true },
            component: MyCourse,
          },
          {
            path: "/manage-center/course-detail",
            name: "courseDetail",
            meta: { needLogin: true },
            component: MyCourseDetail,
          },
          {
            path: "/manage-center/my-info",
            name: "myInfo",
            meta: { needLogin: true },
            component: MyInfo,
          },
          {
            path: "/manage-center/class-list",
            name: "classList",
            meta: { needLogin: true },
            component: ClassList,
          },
          {
            path: "/manage-center/class-course-list",
            name: "courseList",
            meta: { needLogin: true },
            component: ClassCourseList,
          },
          {
            path: "/manage-center/student-list",
            name: "studentList",
            meta: { needLogin: true },
            component: StudentList,
          },
          {
            path: "/manage-center/course-order",
            name: "courseOrder",
            meta: { needLogin: true },
            component: CourseOrder,
          },
          {
            path: "/manage-center/pay-page",
            name: "payPage",
            meta: { needLogin: true },
            component: PayPage,
          },
          {
            path: "/manage-center/demand-list",
            name: "demandList",
            meta: { needLogin: true },
            component: DemandList,
          },
          {
            path: "/manage-center/student-consume",
            name: "studentConsume",
            meta: { needLogin: true },
            component: StudentConsume,
          },
          {
            path: "/manage-center/consume-detail",
            name: "consumeDetail",
            meta: { needLogin: true },
            component: ConsumeDetail,
          },
          {
            path: "/manage-center/change-code",
            name: "changeCode",
            meta: { needLogin: true },
            component: ChangeCode,
          },
        ],
      },
    ],
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  const _frame = document.getElementsByClassName("frame-wrp")[0];
  _frame && _frame.scrollTo(0, 0);
  const path = to.path || "";
  const isLogin = _isLogin();
  if (!isLogin && to.meta.needLogin) {
    Element.Message.warning("请先登录账号再访问");
    return next("/");
  }
  if (to.path.includes("/manage-center")) {
    store.commit("changePath", path.slice(path.lastIndexOf("/") + 1));
  }

  next();
});

export default router;
