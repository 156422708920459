<template>
  <div class="manage-center-wrp">
    <div class="aside">
      <div class="title">学习中心</div>
      <img src="@/assets/images/avatar2.png" alt="" class="avatar" />
      <div class="user-name">{{ userName }}</div>
      <!-- <img src="@/assets/images/lv-img1.png" alt="" class="lv" /> -->
      <div class="menu-block" v-for="block in menuList" :key="block.id">
        <div class="type" v-if="block.type">{{ block.type }}</div>
        <div
          class="item"
          :class="{ active: $store.state.route.path === item.value }"
          v-for="item in block.list"
          :key="item.value"
          @click="onClickMenu(item)"
        >
          <span class="text">{{ item.text }}</span>
          <div class="icon"></div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="panel">
        <router-view class="panel-wrp"></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import { getUserInfo } from "../../utils/auth";
export default {
  name: "studyCenter",
  data() {
    return {
      userName: "",
      menuList: [
        {
          id: 1,
          type: "课程",
          list: [
            {
              text: "我的课程",
              value: "my-course",
            },
          ],
        },
        {
          id: 2,
          type: "学生管理",
          list: [
            {
              text: "班级列表",
              value: "class-list",
            },
            {
              text: "学生列表",
              value: "student-list",
            },
          ],
        },
        {
          id: 3,
          type: "订单管理",
          list: [
            {
              text: "课程订单",
              value: "course-order",
            },
            {
              text: "需求清单",
              value: "demand-list",
              outPath: "/course-center/demand"
            },
          ],
        },
        {
          id: 4,
          type: "账户信息",
          list: [
            {
              text: "基本资料",
              value: "my-info",
            },
            {
              text: "修改密码",
              value: "change-code",
            },
          ],
        },
        // {
        //   id: 5,
        //   type: "数据统计",
        //   list: [
        //     {
        //       text: "学生消费",
        //       value: "student-consume",
        //     },
        //   ],
        // },
      ],
    };
  },
  created() {
    this.userName = getUserInfo().organInfo
      ? getUserInfo().organInfo.organName || ""
      : "";
  },
  methods: {
    onClickMenu(item) {
      if (item.outPath) {
        this.$router.push(item.outPath);
        return;
      }
      this.$router.push(`/manage-center/${item.value}`);
    },
  },
};
</script>

<style lang="less">
.manage-center-wrp {
  display: flex;
  margin: 0 auto 60px;
  width: 1200px;
  .aside {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 180px;
    height: max-content;
    border-left: 1px solid rgba(255, 95, 25, 0.08);
    border-right: 1px solid rgba(255, 95, 25, 0.08);
    .title {
      width: 140px;
      height: 34px;
      line-height: 34px;
      text-align: center;
      font-size: 16px;
      color: #fff;
      background-color: #ff5f19;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }
    .avatar {
      margin-top: 20px;
      width: 61px;
      height: 67px;
    }
    .user-name {
      margin-top: 10px;
      margin-bottom: 36px;
      padding: 0 10px;
      font-size: 14px;
      color: #1a1a1a;
    }
    .lv {
      margin-bottom: 30px;
      width: 48px;
      height: 15px;
    }

    .menu-block {
      padding-top: 17px;
      width: 100%;
      border-bottom: 1px solid rgba(255, 95, 25, 0.08);
      .type {
        margin-bottom: 18px;
        padding: 0 16px 0 20px;
        width: 100%;
        font-size: 14px;
        color: #666;
      }
      .item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 18px;
        padding: 0 16px 0 20px;
        cursor: pointer;
        .text {
          line-height: 1;
          font-size: 16px;
          color: #333;
        }
        .icon {
          width: 17px;
          height: 17px;
          background-image: url("@/assets/images/arrow-right.png");
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
        }
        &:hover,
        &.active {
          .text {
            color: #ff5f19;
          }
          .icon {
            background-image: url("@/assets/images/arrow-right-light.png");
          }
        }
      }
    }
  }
  .content {
    position: relative;
    flex: 1;
    padding-top: 84px;
    padding-left: 40px;
    height: 818px;
    background-image: url("@/assets/images/study-center-bg.png");
    background-repeat: no-repeat;
    background-size: 1200px 244px;
    background-position: -180px 0px;
    box-sizing: border-box;
    .panel {
      height: 734px;
      width: 978px;
      background-color: #fff;
      border-radius: 10px;
      box-shadow: 0px 0px 6px rgba(219, 124, 65, 0.1);
      overflow: hidden;
      .panel-wrp {
        display: flex;
        flex-direction: column;
        height: 100%;
        .module-title {
          padding-left: 20px;
          height: 62px;
          line-height: 62px;
          font-size: 22px;
          color: #1a1a1a;
          border-bottom: 1px solid rgba(255, 95, 25, 0.1);
          box-shadow: 0 0 4px rgba(255, 161, 120, 0.1);
        }
        .panel-cont {
          flex: 1;
          overflow: auto;
          scroll-behavior: smooth;
        }
      }
    }
  }
}
/deep/ .el-pagination {
  margin-top: 40px;
  margin-bottom: 40px;
  text-align: center;
}
</style>
