<template>
  <div class="course-detail-wrp">
    <img :src="courseInfo.tags?.detailImage" alt="" class="course-img" />
    <div class="cont">
      <div class="info">
        <div class="left">
          <div class="name">{{ courseInfo.name }}</div>
          <div class="prop">
            建议授课方式：<span>{{ courseTypes[courseInfo.tags.mode] }}</span>
            <!-- 课时数：<span>{{ courseInfo.lessonCount }}</span> -->
            课程类别：<span>{{ courseInfo.tags.subCategory }}</span>
          </div>
        </div>
        <div class="btn" @click="onAddShopCart">加入需求清单</div>
      </div>
      <div class="detail" ref="detail"></div>
    </div>
  </div>
</template>

<script>
import CourseIntro from "./courseIntro.vue";
import { courseTypes as _options } from "../../utils/options";
export default {
  name: "courseDetail",
  components: { CourseIntro },
  data() {
    return {
      courseId: "",
      courseInfo: {tags: {}},
      moreCourseDetailData: {},
    };
  },
  computed: {
    courseTypes() {
      return _options;
    },
  },
  created() {
    this.courseId = this.$route.query.courseId;
    this.initCourseInfoData();
  },
  methods: {
    initCourseInfoData() {
      this.$request
        .getCourseGoodsDetailApi({ id: this.courseId })
        .then((res) => {
          this.courseInfo = res.result.product || {};
          this.$refs.detail.innerHTML = decodeURIComponent(
            this.courseInfo.tags.readMe
          );
        });
    },
    onAddShopCart() {
      this.$store.dispatch("addShopCart", {
        productId: this.courseId,
        count: 1,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.course-detail-wrp {
  .course-img {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    margin: 0 auto 20px;
    width: 100vw;
    height: 500px;
  }
  .cont {
    width: 1200px;
    .info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 60px;
      padding-right: 120px;
      width: 1200px;
      height: 140px;
      background: #fff;
      box-shadow: 0px 0px 20px 1px rgba(255, 84, 10, 0.1);
      border-radius: 8px;
      box-sizing: border-box;
      .left {
        .name {
          font-size: 36px;
          color: #1a1a1a;
        }
        .prop {
          margin-top: 20px;
          line-height: 1;
          font-size: 20px;
          color: #808080;
          span {
            margin-right: 30px;
            color: #ff6505;
          }
        }
      }
      .btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 160px;
        height: 42px;
        line-height: 1;
        font-size: 20px;
        color: #ff6505;
        background-color: #fff;
        border: 1px solid rgba(255, 101, 5, 0.3);
        border-radius: 21px;
        box-sizing: border-box;
        cursor: pointer;
        transition: all 0.15s;
        &:hover {
          opacity: 1;
          background-color: #ffb88c;
          border-color: transparent;
          color: #fff;
        }
      }
    }
  }
  .detail {
    margin: 20px 0;
    width: 100%;
    color: #333;
    /deep/ img {
      width: 100%;
    }
  }
}
</style>
