<template>
  <div class="student-list-wrp">
    <div class="module-title">学生列表</div>
    <div class="panel-cont">
      <div class="query">
        <div class="left">
          <div class="item">
            <span class="label">姓名：</span>
            <el-input v-model="searchName" placeholder="请输入姓名"></el-input>
          </div>
          <div class="item">
            <span class="label">班级：</span>
            <el-select v-model="classId" placeholder="请选择班级">
              <el-option
                v-for="item in classList"
                :key="item.classId"
                :label="item.className"
                :value="item.classId"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="right">
          <div class="btn search" @click="resetIndex()">查询</div>
          <div
            class="btn reset"
            :class="{ light: searchName || classId }"
            @click="onReset()"
          >
            重置
          </div>
        </div>
      </div>
      <div class="table-wrp" v-if="sourceData.length">
        <el-table :data="sourceData" border="" style="width: 100%">
          <el-table-column prop="name" label="姓名" width="160" align="center">
          </el-table-column>
          <el-table-column
            prop="serialNo"
            label="学号"
            width="150"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="className"
            label="班级"
            width="160"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="gender"
            label="性别"
            width="150"
            align="center"
          >
            <template slot-scope="scope">
              {{
                scope.row.gender === 0
                  ? "未知"
                  : scope.row.gender === 1
                  ? "男"
                  : "女"
              }}
            </template>
          </el-table-column>
          <el-table-column
            prop="status"
            label="是否绑定账号"
            width="150"
            align="center"
          >
            <template slot-scope="scope">
              {{ scope.row.status === 0 ? "未绑定" : "已绑定" }}
            </template>
          </el-table-column>
          <el-table-column prop="" label="操作" align="center">
            <template slot-scope="scope">
              <div class="btn" @click="onCheckStudentDetail(scope.row.id)">
                详情
              </div>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          v-if="pageData.total > pageData.size"
          layout="prev, pager, next"
          :total="pageData.total"
          :current-page.sync="pageData.index"
          :page-size="pageData.size"
          @current-change="onPageChange"
        >
        </el-pagination>
      </div>
      <div class="empty" v-if="!sourceData.length && show">
        <img src="@/assets/images/empty-student.png" alt="" />
        <div class="text">{{ emptyText }}</div>
      </div>
    </div>

    <el-dialog title="学生详情" :visible.sync="studentModelVsb" width="964px">
      <div class="student-model-table-wrp">
        <el-table :data="studentBaseData" border style="width: 100%">
          <el-table-column prop="col1" label="姓名"> </el-table-column>
          <el-table-column prop="col2" label="学号"> </el-table-column>
          <el-table-column prop="col3" label="手机号"> </el-table-column>
          <el-table-column prop="col4" label="身份证"> </el-table-column>
        </el-table>
        <el-table :data="studentCourseProgresslData" border style="width: 100%">
          <el-table-column prop="course.name" label="课程名称" width="220px">
          </el-table-column>
          <el-table-column prop="" label="课程类型">
            <template slot-scope="scope">
              {{ courseTypes[scope.row.course.mode] }}
            </template>
          </el-table-column>
          <el-table-column
            prop="lastStudyTime"
            label="最后学习时间"
            width="220px"
          >
            <template slot-scope="scope">
              {{ scope.row.lastStudyTime || "--" }}
            </template>
          </el-table-column>
          <el-table-column prop="" label="学习进度">
            <template slot-scope="scope">
              {{ scope.row.lessonLearnedCompletedCount || 0 }} /
              {{ scope.row.lessonCount }}
            </template>
          </el-table-column>
          <el-table-column prop="homeworkStatus" label="操作">
            <template slot-scope="scope">
              <div class="btn" @click="onToCourseDetail(scope.row.id)">
                查看详情
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>

    <el-dialog title="课程详情" :visible.sync="courseModelVsb" width="996px">
      <div class="course-model-table-wrp">
        <el-table :data="courseData" border style="width: 100%">
          <el-table-column prop="title" label="课时" align="center">
            <template slot-scope="scope">
              {{ scope.row.title }}
            </template>
          </el-table-column>
          <el-table-column
            prop=""
            label="课程状态"
            align="center"
            width="130px"
          >
            <template slot-scope="scope">
              <div
                :class="{
                  light: scope.row.studyLog.studyStatus !== 2,
                  gray: scope.row.studyLog.studyStatus === 1,
                }"
              >
                {{ scope.row.studyLog.studyStatus === 1 ? "学习中" : "已完成" }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="studyLog.studyFinishTime"
            label="完成时间"
            align="center"
          >
            <template slot-scope="scope">{{
              scope.row.studyLog.studyStatus === 1
                ? "--"
                : scope.row.studyLog.studyFinishTime
            }}</template>
          </el-table-column>
          <el-table-column
            prop=""
            label="作业状态"
            align="center"
            width="130px"
          >
            <template slot-scope="scope">
              <div
                :class="{
                  light: scope.row.studyLog.homeworkStatus === 13,
                  gray: scope.row.studyLog.homeworkStatus === 1,
                }"
              >
                {{
                  scope.row.studyLog.hasHomework
                    ? scope.row.studyLog.homeworkStatus === 1
                      ? "已完成"
                      : scope.row.studyLog.homeworkStatus === 0
                      ? "未完成"
                      : "已批阅"
                    : "无作业"
                }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="studyLog.grade"
            label="作业成绩"
            align="center"
            width="130px"
          >
            <template slot-scope="scope">
              {{ grades[scope.row.studyLog.grade] || "--" }}
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  courseTypes as _courseTypes,
  grades as _grades,
} from "../../utils/options";
export default {
  name: "studentList",

  data() {
    return {
      emptyText: "还没有添加任何学生哦~",
      show: false,
      classId: "",
      searchName: "",
      classList: [],
      loading: false,
      selectedStudentId: "",
      pageData: {
        index: 1,
        size: 7,
        total: 1,
      },
      sourceData: [],
      studentModelVsb: false,
      studentBaseData: [],
      studentCourseProgresslData: [],
      courseModelVsb: false,
      courseData: [],
    };
  },
  computed: {
    courseTypes() {
      return _courseTypes;
    },
    grades() {
      return _grades;
    },
  },
  created() {
    this.classId = this.$route.query.classId || "";
    this.$request
      .onGetClassListApi({
        offset: 0,
        size: 999,
      })
      .then((res) => {
        this.classList = res.result.organClassInfos || [];
        this.onGetStudentList();
      });
  },
  methods: {
    resetIndex() {
      this.pageData.index = 1;
      this.onGetStudentList();
    },
    onGetStudentList() {
      if (this.loading) return;
      this.loading = true;
      this.$request
        .onGetStudentListApi({
          offset: (this.pageData.index - 1) * this.pageData.size,
          size: this.pageData.size,
          classId: this.classId,
          name: this.searchName,
        })
        .then((res) => {
          this.loading = false;
          this.sourceData = res.result.studentInfos || [];
          this.sourceData.forEach((item) => {
            const _find = this.classList.find(
              (v) => item.classId === v.classId
            );
            if (_find) {
              item.className = _find.className;
            }
          });
          this.emptyText = this.searchName
            ? "暂无该学生哦~"
            : "还没有添加任何学生哦~";
          this.pageData.total = res.result.total;
          this.show = true;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    onPageChange(index) {
      this.pageData.index = index;
      this.onGetStudentList();
    },
    onReset() {
      this.searchName = "";
      this.classId = "";
      this.resetIndex();
    },
    onCheckStudentDetail(studentId) {
      if (this.loading) return;
      this.loading = true;
      this.selectedStudentId = studentId;
      this.$request.onGetStudentDetailApi({ studentId }).then((res) => {
        this.studentModelVsb = true;
        const _studentInfo = res.result.studentInfo || {};
        this.studentBaseData = [
          {
            col1: _studentInfo.name,
            col2: _studentInfo.serialNo,
            col3: _studentInfo.phone,
            col4: _studentInfo.idCardNo,
          },
          // {
          //   col1: "入学时间",
          //   col2: "结业时间",
          //   col3: "学习课程",
          //   col4: "完成课程",
          // },
          // {
          //   col1: _studentInfo.organJoinTime.slice(0, 10),
          //   col2: _studentInfo.organExitTime.slice(0, 10),
          //   col3: _studentInfo.studyedCourseCount,
          //   col4: _studentInfo.courseFinishedCount,
          // },
        ];
      });
      this.$request
        .onGetStudentProgressApi({ offset: 0, size: 999, studentId })
        .then((res) => {
          this.loading = false;
          this.studentCourseProgresslData = res.result.progresses || [];
        })
        .catch(() => {
          this.loading = false;
        });
    },
    onToCourseDetail(progressId, studentId) {
      if (this.loading) return;
      this.loading = true;
      this.$request
        .onGetStudentCourseProgressApi({
          progressId,
          studentId: this.selectedStudentId,
          size: 999,
          offset: 0,
        })
        .then((res) => {
          this.loading = false;
          let chapterInfos = res.result.chapters || [];
          let _data = [];
          chapterInfos.forEach((item) => {
            item.lessonDataPacks.forEach((v) => {
              v.pTitle = item.title;
              if (v.studyLog) {
                _data.push(v);
              }
            });
          });

          this.courseData = _data;
          this.courseModelVsb = true;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="less" scoped>
.student-list-wrp {
  .query {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0;
    .left {
      display: flex;
      align-items: center;
      .item {
        display: flex;
        align-items: center;
      }
      .label {
        white-space: nowrap;
        margin-left: 20px;
        line-height: 1;
        font-size: 16px;
        color: #1a1a1a;
      }
    }
    .right {
      display: flex;
      .btn {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 20px;
        width: 62px;
        height: 30px;
        box-sizing: border-box;
        border-radius: 10px;
        cursor: pointer;
        &.search {
          font-size: 16px;
          color: #fff;
          background-color: #ffaf8c;
          transition: all 0.15s;
          &:hover {
            background-color: #ff5f19;
          }
        }
        &.reset {
          border: 1px solid #ccc;
          color: #ccc;
          &.light {
            border-color: #ffaf8c;
            color: #ffaf8c;
            transition: all 0.15s;
            &:hover {
              border-color: #ff5f19;
              color: #ff5f19;
            }
          }
        }
      }
    }
  }
  .table-wrp {
    padding: 0 20px;
  }
  .empty img {
    width: 105px;
    height: 118px;
  }
  /deep/ .el-table {
    border-radius: 10px;
    border-color: rgba(255, 95, 25, 0.1);
    tr:last-child td:last-child {
      border-bottom-right-radius: 10px;
    }
    &::after,
    &::before {
      display: none;
    }
    tr:hover td.el-table__cell {
      background-color: #fff;
    }
    td,
    th {
      font-size: 16px;
      border-color: rgba(255, 95, 25, 0.1);
      .cell {
        padding: 0;
      }
    }
    th.is-leaf {
      border-color: rgba(255, 95, 25, 0.1);
    }
    th {
      height: 85px;
      line-height: 75px;
      color: #1a1a1a;
      background-color: #fff6f1;
    }
    td {
      padding: 0 20px;
      height: 60px;
      color: #4d4d4d;
      .btn {
        color: #ffaf8c;
        cursor: pointer;
        transition: all 0.15s;
        &:hover {
          color: #ff5f19;
        }
      }
      .light {
        color: #ff5f19;
      }
      .gray {
        color: #808080;
      }
    }
  }
  .student-model-table-wrp {
    padding: 20px;
    /deep/ .el-table {
      &:first-child {
        margin-bottom: 20px;
        tr:nth-child(2) td {
          font-weight: bold;
          color: #1a1a1a;
          background-color: #fff6f1;
        }
      }
      th,
      td {
        padding: 0 20px;
        height: 56px;
        line-height: 56px;
      }
    }
  }
  .course-model-table-wrp {
    padding: 20px;
  }
  /deep/ .el-pagination {
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
  }
}
</style>
