<template>
  <div class="my-order-wrp">
    <div class="module-title">订单管理</div>
    <div class="panel-cont">
      <div class="menu-wrp">
        <div
          class="item"
          :class="{ active: selectedMenu === item.value }"
          v-for="item in menuList"
          :key="item.orderId"
          @click="onClickMenu(item)"
        >
          {{ item.text }}
          <span class="line" v-if="selectedMenu == item.value"></span>
        </div>
      </div>
      <template v-if="show && dataSource.length">
        <div class="order-item" v-for="item in dataSource" :key="item.orderId">
          <div class="head-info">
            <div class="order-info">
              <span class="time">{{ item.createTime }}</span>
              <span class="order-id">订单号：{{ item.orderId }}</span>
            </div>
            <div class="th-wrp">
              <div class="goods">商品</div>
              <div class="human-num">人数</div>
              <div class="spec">单价</div>
              <div class="num">数量</div>
              <!-- <div class="type">授课方式</div> -->
              <!-- <div class="course-num">课时数</div> -->
              <div class="money">
                {{ item.status === 3 ? "实付款" : "待付款" }}
              </div>
              <div class="pay-info"></div>
            </div>
          </div>
          <div class="table-wrp">
            <table>
              <tr v-for="(v, i) in item.subOrders" :key="v.id">
                <td class="base-info">
                  <div class="base-info-wrp">
                    <img :src="v.product.thumb" alt="" />
                    <div class="detail">
                      <div class="name">{{ v.product.name }}</div>
                      <!-- <div class="intro">
                        {{ v.product.tags.briefDescribe }}
                      </div> -->
                    </div>
                  </div>
                </td>
                <td class="human-num">
                  {{ v.product.price.studentLimit }}人
                </td>
                <td class="spec">￥{{ v.product.price.price }}</td>
                <td class="num">×{{ v.count }}</td>
                <!-- <td class="type">{{ courseTypes[v.product.mode] }}</td> -->
                <!-- <td class="course-num">{{ v.lessonCount }}</td> -->
                <td
                  class="money"
                  v-if="i === 0"
                  :rowspan="item.subOrders.length"
                >
                  ￥{{ item.orderAmountNeedPay }}
                </td>

                <td
                  v-if="i === 0"
                  :rowspan="item.subOrders.length"
                  class="pay-info"
                >
                  <div class="pay-time" v-if="item.status === 3">
                    <div>交易时间：</div>
                    <div>{{ item.finishTime }}</div>
                  </div>
                  <div
                    class="to-pay"
                    v-else-if="item.status === 97 || item.status === 2"
                    @click="openPayModel(item.orderId, item.orderAmountTotal)"
                  >
                    去付款
                  </div>
                  <div v-else>{{ orderStatus[item.status] }}</div>
                  <div
                    v-if="
                      item.status === 97 ||
                      item.status === 2 ||
                      item.status === 1
                    "
                    class="handle-btn to-cancel"
                    @click="onCancelOrder(item.orderId)"
                  >
                    取消订单
                  </div>
                  <div
                    class="handle-btn to-del"
                    v-if="item.status === 99 || item.status === 4"
                    @click="onDelOrder(item.orderId)"
                  >
                    删除订单
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <el-pagination
          v-if="pageData.total > pageData.size"
          layout="prev, pager, next"
          :total="pageData.total"
          :current-page.sync="pageData.index"
          :page-size="pageData.size"
          @current-change="onPageChange"
        >
        </el-pagination>
      </template>
      <div class="empty" v-if="!dataSource.length && show">
        <img src="@/assets/images/empty-order.png" alt="" />
        <div class="text">{{ emptyText }}</div>
      </div>
    </div>

    <el-dialog
      :visible.sync="isPayModelVsb"
      :before-close="closePayModel"
      width="396px"
    >
      <div class="model-cont">
        <img
          src="@/assets/images/pay-model-bg-img.png"
          alt=""
          class="model-bg-img"
        />
        <div class="title">请选择支付方式</div>
        <div class="menu">
          <div
            class="item"
            v-for="item in payTypes"
            :key="item.value"
            @click="onChoosePayType(item.value)"
          >
            <div class="name">{{ item.text }}</div>
            <img
              src="@/assets/images/weixin-img.png"
              alt=""
              class="pay-type-icon1"
              v-if="item.value === 2"
            />
            <img
              src="@/assets/images/zhifubao-img.png"
              alt=""
              class="pay-type-icon2"
              v-if="item.value === 1"
            />
          </div>
        </div>

        <!-- <el-button type="primary" @click="onChoosePayType(3)"
          >测试的支付方式</el-button
        >
        <el-button type="default" @click="onTestPay()"
          >跳转测试支付页面完成支付</el-button
        > -->
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  courseTypes as _options,
  orderStatus as _orderStatus,
} from "../../utils/options";
export default {
  name: "myOrder",
  data() {
    return {
      show: false,
      menuList: [
        {
          text: "全部",
          value: undefined,
        },
        {
          text: "待付款",
          value: 97,
        },
        {
          text: "已付款",
          value: 3,
        },
        {
          text: "已取消",
          value: 99,
        },
      ],
      selectedMenu: undefined,
      dataSource: [{}],
      pageData: {
        index: 1,
        size: 5,
        total: 1,
      },
      isPayModelVsb: false,
      payTypes: [
        {
          text: "微信",
          value: 2,
        },
        {
          text: "支付宝",
          value: 1,
        },
      ],
      selectedOrderId: "",
      tradeNo: "",
      loading: false,
      jumpUrl: "",
    };
  },
  computed: {
    courseTypes() {
      return _options;
    },
    orderStatus() {
      return _orderStatus;
    },
    emptyText() {
      return !this.selectedMenu ? "还没有下过单，暂无数据哦~" : "暂无数据哦~";
    },
  },
  created() {
    this.resetIndex();
  },
  methods: {
    onClickMenu(item) {
      this.pageData.index = 1;
      this.selectedMenu = item.value;
      this.resetIndex();
    },
    resetIndex() {
      this.index = 1;
      this.onGetOrderList();
    },
    onPageChange(index) {
      this.pageData.index = index;
      this.$store.commit("panelScrollToTop");
      this.onGetOrderList();
    },
    onGetOrderList() {
      this.$request
        .getOrderListApi({
          orderStatus: this.selectedMenu,
          size: this.pageData.size,
          offset: (this.pageData.index - 1) * this.pageData.size,
        })
        .then((res) => {
          this.pageData.total = res.result.total;
          this.dataSource = res.result.orderInfos || [];
          this.show = true;
        });
    },
    onToPay(orderId) {
      this.$router.push("/course-center/pay-order?orderId=" + orderId);
    },
    onCancelOrder(orderId) {
      this.$confirm("确认取消该订单吗？", "确认取消", {
        confirmButtonText: "确定",
        showCancelButton: false,
      }).then(() => {
        this.$request.onCancleOrderApi({ orderId }).then(() => {
          this.resetIndex();
          this.$message.success("订单取消成功");
        });
      });
    },
    onDelOrder(orderId) {
      this.$confirm("确认删除该订单吗？", "确认删除", {
        confirmButtonText: "确定",
        showCancelButton: false,
      }).then(() => {
        this.$request.onDelOrderApi({ orderId }).then(() => {
          this.resetIndex();
          this.$message.success("订单删除成功");
        });
      });
    },

    openPayModel(orderId, money) {
      this.selectedOrderId = orderId;
      if (money === 0) {
        this.onChoosePayType(0, money === 0);
      } else {
        this.isPayModelVsb = true;
      }
    },
    closePayModel() {
      this.onGetOrderList();
      this.isPayModelVsb = false;
    },
    onChoosePayType(type, isFree) {
      if (this.loading) return;
      this.loading = true;
      this.$request
        .onGetPayUrlApi({
          orderId: this.selectedOrderId,
          payChannel: type,
        })
        .then((res) => {
          this.loading = false;
          if (isFree) {
            this.onGetOrderList();
            return this.$message.success("支付成功");
          }
          if (type === 3) {
            this.jumpUrl = res.result.jumpPage;
            this.$message.success("接口调成功了,已重新唤起支付");
            return;
          }
          const payQrCode = res.result.pageBody || "";
          localStorage.setItem("payForm", payQrCode);

          // const path = location.origin + "/#/manage-center/pay-page";
          // window.open(path, "_blank");
          this.$router.push(
            `/course-center/pay-result?payResult=wait&payType=${type}&orderId=${this.selectedOrderId}`
          );
        })
        .catch(() => {
          this.loading = false;
        });
    },
    onTestPay() {
      window.open(this.jumpUrl, "_blank");
    },
  },
};
</script>

<style lang="less" scoped>
.my-order-wrp {
  /deep/ .el-pagination {
    margin-bottom: 40px;
    text-align: center;
  }
  /deep/ .el-dialog {
    overflow: visible;
    .el-dialog__header {
      display: none;
    }
    .el-dialog__body {
      overflow: visible;
      .model-cont {
        display: flex;
        flex-direction: column;
        text-align: center;
        .model-bg-img {
          position: relative;
          margin-top: -40px;
          width: 100%;
        }
        .title {
          padding-bottom: 40px;
          font-size: 24px;
          color: #1a1a1a;
          background-color: #fff;
        }
        .menu {
          display: flex;
          align-items: center;
          justify-content: space-around;
          padding-bottom: 46px;
          background-color: #fff;
          border-radius: 10px;

          .item {
            display: inline-flex;
            flex-direction: column;
            align-items: center;
            width: max-content;
            cursor: pointer;
            .name {
              margin-bottom: 30px;
              font-size: 24px;
              color: #1a1a1a;
            }
            .pay-type-icon1 {
              width: 76px;
              height: 63px;
            }
            .pay-type-icon2 {
              width: 61px;
              height: 63px;
            }
          }
        }
      }
    }
  }
  .menu-wrp {
    display: flex;
    align-items: flex-end;
    padding: 20px;
    .item {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 30px;
      line-height: 1;
      font-size: 16px;
      color: #808080;
      cursor: pointer;
      &.active {
        font-size: 18px;
        color: #e85900;
      }
      .line {
        margin-top: 3px;
        width: 100%;
        height: 2px;
        background-color: #e85900;
        border-radius: 1px;
      }
    }
  }
  .order-item {
    margin-bottom: 40px;
    .head-info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 86px;
      background-color: #fff6f1;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      .order-info {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        padding: 0 20px;
        font-size: 14px;
        color: #666;
        .time {
          margin-right: 20px;
        }
      }
      .th-wrp {
        display: flex;
        align-items: center;
      }
    }
    .goods {
      flex: 1;
      text-align: center;
      font-size: 14px;
      color: #1a1a1a;
    }
    .human-num,
    .num,
    .type,
    .course-num {
      padding-right: 1px;
      width: 90px;
      text-align: center;
      font-size: 14px;
      color: #1a1a1a;
    }
    .spec,
    .money {
      width: 110px;
      text-align: center;
      font-size: 14px;
      color: #1a1a1a;
    }
    .pay-info {
      width: 110px;
      text-align: center;
      font-size: 14px;
      color: #1a1a1a;
      .to-pay,
      .to-cancel,
      .to-del {
        font-size: 16px;
        cursor: pointer;
      }
      .to-pay {
        margin-bottom: 8px;
        color: #ffaf8c;
        transition: all 0.15s;
        &:hover {
          color: #ff5f19;
        }
      }
      .to-cancel {
        margin-top: 8px;
        transition: all 0.15s;
        &:hover {
          color: #ffaf8c;
        }
      }
      .to-del {
        margin-top: 8px;
        color: #ff8c8c;
        transition: all 0.15s;
        &:hover {
          color: #ff1919;
        }
      }
      .order-other-status {
        font-size: 16px;
        color: #333;
      }
    }
    .table-wrp {
      border-bottom: 1px solid rgba(255, 95, 25, 0.1);
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }
    table {
      width: 100%;
      tr {
        border-bottom: 1px solid rgba(255, 95, 25, 0.1);
        &:last-child {
          border-bottom: none;
        }
      }
      td {
        font-size: 14px;
        border-right: 1px solid rgba(255, 95, 25, 0.1);
        text-align: center;
        vertical-align: middle;
        box-sizing: border-box;
        &.base-info {
          flex: 1;
          .base-info-wrp {
            display: flex;
            align-items: center;
            padding: 10px;
            img {
              margin-right: 10px;
              width: 170px;
              height: 100px;
              border-radius: 8px;
            }
            .detail {
              flex: 1;
              .name {
                // margin-bottom: 20px;
                text-align: left;
                font-size: 18px;
                color: #1a1a1a;
                text-overflow: -o-ellipsis-lastline;
                overflow: hidden; //溢出内容隐藏
                text-overflow: ellipsis; //文本溢出部分用省略号表示
                display: -webkit-box; //特别显示模式
                -webkit-line-clamp: 2; //行数
                line-clamp: 2;
                -webkit-box-orient: vertical; //盒子中内容竖直排列
              }
              .intro {
                text-overflow: -o-ellipsis-lastline;
                overflow: hidden; //溢出内容隐藏
                text-overflow: ellipsis; //文本溢出部分用省略号表示
                display: -webkit-box; //特别显示模式
                -webkit-line-clamp: 2; //行数
                line-clamp: 2;
                -webkit-box-orient: vertical; //盒子中内容竖直排列
                text-align: left;
                color: #808080;
              }
            }
          }
        }
        &.human-num,
        &.spec,
        &.num,
        &.type,
        &.course-num,
        &.money,
        &.pay-info {
          color: #666;
        }
        &.pay-info {
          line-height: 1.8;
        }
      }
    }
  }
  .empty img {
    width: 150px;
    height: 104px;
  }
}
</style>
