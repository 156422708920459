<template>
  <div class="banner-intro-page-wrp">
    <template v-if="index === 1">
      <img src="@/assets/images/bannerPage/1-1.png" alt="" />
      <img src="@/assets/images/bannerPage/1-2.png" alt="" />
    </template>
    <template v-if="index === 2">
      <img src="@/assets/images/bannerPage/2-1.png" alt="" />
      <img src="@/assets/images/bannerPage/2-2.png" alt="" />
    </template>
    <template v-if="index === 3">
      <img src="@/assets/images/bannerPage/3-1.png" alt="" />
      <img src="@/assets/images/bannerPage/3-2.png" alt="" />
    </template>
  </div>
</template>

<script>
export default {
  name: "bannerIntroPage",
  data() {
    return {
      index: null,
    };
  },
  created() {
    this.index = +this.$route.query.index;
  },
};
</script>

<style lang="less" scoped>
.banner-intro-page-wrp {
  font-size: 0;
  text-align: center;
  img {
    width: 100%;
  }
}
</style>
