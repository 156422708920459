<template>
  <div class="my-info-wrp">
    <div class="module-title">个人资料</div>
    <div class="form">
      <div class="item">
        <span class="label">账号：</span>
        <div class="value account">{{ form.loginName }}</div>
      </div>
      <div class="item">
        <span class="label">单位名称：</span>
        <div class="value">{{ form.organName }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { getUserInfo } from "../../utils/auth";
export default {
  name: "myInfo",

  data() {
    return {
      form: {
        loginName: "",
        organName: "",
      },
    };
  },

  created() {
    const userInfo = getUserInfo();
    if (userInfo.organInfo) {
      this.form.loginName = userInfo.operatorInfo.loginName || "";
      this.form.organName = userInfo.organInfo.organName || "";
    }
  },
};
</script>

<style lang="less" scoped>
.my-info-wrp {
  .form {
    margin-top: 58px;
    margin-bottom: 12px;
    padding-left: 20px;
    .item {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      .label {
        margin-right: 10px;
        min-width: 96px;
        text-align: right;
        font-size: 16px;
        color: #1a1a1a;
        .star {
          color: #ff5f19;
        }
      }
      .value {
        display: flex;
        align-items: center;
        box-sizing: border-box;
        padding-left: 22px;
        width: 206px;
        height: 24px;
        font-size: 16px;
        color: #1a1a1a;
        border: 1px solid #e6e6e6;
        background-color: #f5f5f5;
        border-radius: 2px;
        &.account {
          background-color: #fff;
        }
      }
      .text {
        margin-left: 20px;
        font-size: 14px;
        color: #808080;
      }
    }
  }
}
</style>