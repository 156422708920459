<template>
  <div class="pay-order-wrp">
    <div class="title-text">
      {{ orderTitle }}
      <!-- <span
        class="text"
        v-if="orderInfo.status === 1 || orderInfo.status === 97"
        >请在{{ restTimeStr }}内完成支付，否则订单会被自动取消</span
      > -->
    </div>
    <div class="order-cont">
      <div class="order-base-info">
        <div class="title">订单信息</div>
        <span>订单号: {{ orderId }}</span>
        <span>{{ orderInfo.createTime }}</span>
      </div>
      <div class="table-wrp">
        <table>
          <tr>
            <th>商品</th>
            <th>课程类型</th>
            <th>单价</th>
          </tr>
          <tr v-for="item in goodsList" :key="item.id">
            <td class="base-info">
              <div class="base-info-wrp">
                <img :src="item.courseThumb" alt="" />
                <div class="detail">
                  <div class="name-wrp">
                    <span class="name">{{ item.courseName }}</span>
                  </div>
                  <div class="intro">
                    {{ item.briefDescribe }}
                  </div>
                </div>
              </div>
            </td>

            <td class="course-type">{{ courseTypes[item.courseMode] }}课</td>
            <td class="spec">
              <div class="value">￥{{ item.minPrice }}</div>
            </td>
          </tr>
        </table>
      </div>
      <div class="settle-wrp">
        <div class="title">总金额结算</div>
        <div class="info">
          <div class="item">
            已选择{{ goodsList.length }}件商品，商品金额￥{{
              orderInfo.orderAmountTotal
            }}
          </div>
          <!-- <div class="item">使用两张优惠券，优惠￥200</div> -->
          <div
            class="item"
            v-if="orderInfo.status === 1 || orderInfo.status === 97"
          >
            应付<span>￥{{ orderInfo.orderAmountTotal }}</span>
          </div>
          <div
            class="pay-btn"
            v-if="orderInfo.status === 1 || orderInfo.status === 97"
            @click="onOpenPayModel()"
          >
            立即结算
          </div>
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="payModelVsb" width="396px">
      <div class="model-cont">
        <img
          src="@/assets/images/pay-model-bg-img.png"
          alt=""
          class="model-bg-img"
        />
        <div class="title">请选择支付方式</div>
        <div class="menu">
          <div
            class="item"
            v-for="item in payTypes"
            :key="item.value"
            @click="onChoosePayType(item.value)"
          >
            <div class="name">{{ item.text }}</div>
            <img
              src="@/assets/images/weixin-img.png"
              alt=""
              class="pay-type-icon1"
              v-if="item.value === 2"
            />
            <img
              src="@/assets/images/zhifubao-img.png"
              alt=""
              class="pay-type-icon2"
              v-if="item.value === 1"
            />
          </div>
        </div>
        <!-- <el-button type="primary" @click="onChoosePayType(3)"
          >测试的支付方式</el-button
        >
        <el-button type="default" @click="onPay()">测试模拟用户支付</el-button>
        <el-button
          type="primary"
          @click="
            $router.push(
              '/course-center/pay-result?payResult=wait&orderId=' + orderId
            )
          "
          >跳转到等待页面</el-button
        > -->
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  courseTypes as _options,
  orderStatus as _orderStatus,
} from "../../utils/options";
export default {
  name: "payOrder",
  data() {
    return {
      orderId: "",
      payModelVsb: false,
      restSeconds: 0,
      restTimeStr: "00:00:00",
      orderInfo: {},
      goodsList: [],
      payTypes: [
        {
          text: "微信",
          value: 2,
        },
        {
          text: "支付宝",
          value: 1,
        },
      ],
      tradeNo: "",
    };
  },
  created() {
    this.orderId = this.$route.query.orderId || "";
    this.$request.onGetOrderDetailApi({ orderId: this.orderId }).then((res) => {
      this.goodsList = res.result.courseInfos || [];
      this.orderInfo = res.result.orderInfo || {};
      const _restSeconds =
        (new Date(this.orderInfo.overTime).getTime() - new Date().getTime()) /
        1000;
      this.setRestTime(_restSeconds);
    });
  },
  computed: {
    courseTypes() {
      return _options;
    },
    orderTitle() {
      if (this.orderInfo.status === 1 || this.orderInfo.status === 97) {
        return "确认订单";
      } else {
        return _orderStatus[this.orderInfo.status];
      }
    },
  },
  methods: {
    setRestTime(restSeconds) {
      let _second = restSeconds;
      const timeId = setInterval(() => {
        if (restSeconds >= 0) {
          let h = Math.floor(_second / 60 / 60);
          let m = Math.floor((_second - h * 60) / 60);
          let s = Math.floor(_second - h * 60 * 60 - m * 60);
          h = h ? (h < 10 ? "0" + h : h) + ":" : "00:";
          m = m ? (m < 10 ? "0" + m : m) + ":" : "00:";
          s = s ? (s < 10 ? "0" + s : s) : "00";
          this.restTimeStr = h + m + s;
          _second--;
        } else {
          this.restTimeStr = "00:00:00";
          clearTimeout(timeId);
        }
      }, 1000);
    },
    onOpenPayModel() {
      if (this.orderInfo.orderAmountTotal === 0) {
        this.onChoosePayType(0, this.orderInfo.orderAmountTotal === 0);
      } else {
        this.payModelVsb = true;
      }
    },
    onChoosePayType(type, isFree) {
      if (this.loading) return;
      this.loading = true;
      this.$request
        .onGetPayUrlApi({
          orderId: this.orderId,
          payChannel: type,
        })
        .then((res) => {
          this.loading = false;
          if (isFree) {
            this.$router.push("/course-center/pay-result?payResult=success");
            return;
          }
          const payQrCode = res.result.pageBody || "";
          localStorage.setItem("payForm", payQrCode);

          // const path = location.origin + "/#/course-center/pay-page";
          // window.open(path, "_blank");
          this.$router.push(
            `/course-center/pay-result?payResult=wait&payType=${type}&orderId=${this.orderId}`
          );
        })
        .catch(() => {
          this.loading = false;
        });
    },
    onPay() {
      this.$request
        .testPay({ tradeNo: this.tradeNo, isSuccess: false })
        .then((res) => {
          console.log(res);
        });
    },
  },
};
</script>

<style lang="less" scoped>
.pay-order-wrp {
  background-image: url("@/assets/images/cart-bg.png");
  background-size: 1200px, 244px;
  background-repeat: no-repeat;
  background-position: top;
  overflow: hidden;
  /deep/ .el-dialog {
    overflow: visible;
    .el-dialog__header {
      display: none;
    }
    .el-dialog__body {
      overflow: visible;
      .model-cont {
        display: flex;
        flex-direction: column;
        text-align: center;
        .model-bg-img {
          position: relative;
          margin-top: -40px;
          width: 100%;
        }
        .title {
          padding-bottom: 40px;
          font-size: 24px;
          color: #1a1a1a;
          background-color: #fff;
        }
        .menu {
          display: flex;
          align-items: center;
          justify-content: space-around;
          padding-bottom: 46px;
          background-color: #fff;
          border-radius: 10px;

          .item {
            display: inline-flex;
            flex-direction: column;
            align-items: center;
            width: max-content;
            cursor: pointer;
            .name {
              margin-bottom: 30px;
              font-size: 24px;
              color: #1a1a1a;
            }
            .pay-type-icon1 {
              width: 76px;
              height: 63px;
            }
            .pay-type-icon2 {
              width: 61px;
              height: 63px;
            }
          }
        }
      }
    }
  }

  .title-text {
    margin-top: 84px;
    margin-bottom: 18px;
    padding-left: 60px;
    line-height: 1;
    font-size: 26px;
    color: #1a1a1a;
    .text {
      margin-left: 20px;
      font-size: 20px;
      color: #664c3e;
    }
  }
  .order-cont {
    box-sizing: border-box;
    margin: 0 auto 60px;
    width: 1160px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 0 6px rgba(219, 124, 65, 0.1);
    .order-base-info {
      display: flex;
      align-items: flex-end;
      padding: 16px 0 16px 40px;
      line-height: 1;
      .title {
        font-size: 22px;
        color: #333;
      }
      span {
        margin-left: 30px;
        font-size: 16px;
        color: #808080;
      }
    }
    .table-wrp {
      table {
        width: 100%;
      }
      th {
        line-height: 40px;
        font-size: 16px;
        color: #333;
      }
      td {
        vertical-align: middle;
        height: 140px;
        text-align: center;
        font-size: 18px;
        color: #1a1a1a;
        .value {
          font-weight: bold;
          color: #ff5f19;
        }
      }
      .base-info {
        width: 560px;
      }
      .base-info-wrp {
        display: flex;
        padding-left: 40px;
        width: 466px;
        box-sizing: content-box;
        img {
          margin-right: 20px;
          width: 180px;
          height: 104px;
          border-radius: 8px;
          overflow: hidden;
        }
        .detail {
          overflow: hidden;
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .name-wrp {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .name {
              text-align: left;
              font-size: 18px;
              color: #1a1a1a;
            }
            .type {
              font-size: 12px;
              color: #ff5f19;
            }
          }
          .intro {
            text-align: left;
            font-size: 14px;
            color: #808080;
            line-height: 1.6;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden; //溢出内容隐藏
            text-overflow: ellipsis; //文本溢出部分用省略号表示
            display: -webkit-box; //特别显示模式
            -webkit-line-clamp: 2; //行数
            line-clamp: 2;
            -webkit-box-orient: vertical; //盒子中内容竖直排列
          }
        }
      }
      .course-type {
        width: 120px;
      }
      .spec {
        padding: 0 40px;
        width: 200px;
      }
      .num {
        width: 140px;
        .num-handle {
          display: flex;
          align-items: center;
          margin: 0 auto;
          height: 20px;
          border-radius: 6px;
          border: 1px solid #ff5f19;
          overflow: hidden;
          width: max-content;
          span {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            width: 30px;
            line-height: 1;
            font-size: 16px;
            color: #ff5f19;
            cursor: pointer;
            &:nth-child(2) {
              padding: 0 10px;
              width: max-content;
              border-left: 1px solid #ff5f19;
              border-right: 1px solid #ff5f19;
              cursor: default;
            }
          }
        }
      }
      .money {
        width: 140px;
      }
      .handle {
        width: 120px;
        cursor: pointer;
        transition: all 0.15s;
        &:hover {
          color: #ff1919;
        }
      }
    }
  }
  .settle-wrp {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 20px 40px 30px;
    .title,
    .info {
      font-size: 18px;
      color: #1a1a1a;
    }
    .info {
      text-align: right;
      .item {
        margin-bottom: 6px;
        span {
          color: #ff5f19;
        }
      }
      .pay-btn {
        margin-top: 26px;
        margin-left: auto;
        width: 140px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        font-size: 22px;
        color: #fff;
        background-color: #ff5f19;
        border-radius: 20px;
        cursor: pointer;
      }
    }

    .submit-btn {
      width: 180px;
      height: 50px;
      line-height: 50px;
      text-align: center;
      font-size: 24px;
      color: #fff;
      background-color: #ffb080;
      border-radius: 25px;
      cursor: pointer;
      transition: all 0.15s;
      &:hover {
        background-color: #ff5f19;
      }
    }
  }
}
</style>
