<template>
  <div class="demand-list-wrp">
    <div class="module-title">需求清单管理</div>
    <div class="panel-cont">
      <template v-if="show && dataSource.length">
        <div class="order-item" v-for="item in dataSource" :key="item.orderId">
          <div class="head-info">
            <div class="time">提交时间：{{ item.createTime }}</div>
            <div class="type">授课方式</div>
            <div class="course-num">课时数</div>
            <div class="demand">需求详情</div>
            <div class="status">状态</div>
          </div>
          <div class="table-wrp">
            <table>
              <tr v-for="(v, i) in item.courseInfos" :key="v.id">
                <td class="base-info">
                  <div class="base-info-wrp">
                    <img :src="v.courseThumb" alt="" />
                    <div class="detail">
                      <div class="name">{{ v.courseName }}</div>
                      <div class="intro">
                        {{ v.briefDescribe }}
                      </div>
                    </div>
                  </div>
                </td>
                <td class="type">{{ courseTypes[v.courseMode] }}</td>
                <td class="course-num">{{ v.lessonCount }}</td>
                <td
                  class="demand"
                  v-if="i === 0"
                  :rowspan="item.courseInfos.length"
                >
                  <div>{{ item.description || "--" }}</div>
                </td>
                <td
                  class="status"
                  :rowspan="item.courseInfos.length"
                  v-if="i === 0"
                >
                  待确认
                  <div class="to-cancel" @click="onCancelOrder(item.orderId)">
                    取消需求
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <el-pagination
          v-if="pageData.total > pageData.size"
          layout="prev, pager, next"
          :total="pageData.total"
          :current-page.sync="pageData.index"
          :page-size="pageData.size"
          @current-change="onPageChange"
        >
        </el-pagination>
      </template>
      <div class="empty" v-if="!dataSource.length && show">
        <img src="@/assets/images/empty-order.png" alt="" />
        <div class="text">还没有提交过需求清单，暂无数据哦~</div>
      </div>
    </div>
  </div>
</template>

<script>
import { courseTypes as _options } from "../../utils/options";
export default {
  name: "demandList",
  data() {
    return {
      show: false,
      menuList: [
        {
          text: "全部",
          value: undefined,
        },
        {
          text: "待付款",
          value: 1,
        },
        {
          text: "已付款",
          value: 3,
        },
        {
          text: "已取消",
          value: 99,
        },
      ],
      selectedMenu: undefined,
      dataSource: [{}],
      pageData: {
        index: 1,
        size: 5,
        total: 1,
      },
    };
  },
  computed: {
    courseTypes() {
      return _options;
    },
  },
  created() {
    this.resetIndex();
  },
  methods: {
    onClickMenu(item) {
      this.pageData.index = 1;
      this.selectedMenu = item.value;
      this.resetIndex();
    },
    resetIndex() {
      this.index = 1;
      this.onGetOrderList();
    },
    onPageChange(index) {
      this.pageData.index = index;
      this.$store.commit("panelScrollToTop");
      this.onGetOrderList();
    },
    onGetOrderList() {
      this.$request
        .getOrderListApi({
          orderStatus: 1,
          size: this.pageData.size,
          offset: (this.pageData.index - 1) * this.pageData.size,
        })
        .then((res) => {
          this.pageData.total = res.result.total;
          this.dataSource = res.result.orderInfos || [];
          this.show = true;
        });
    },
    onToPay(orderId) {
      this.$router.push("/course-center/pay-order?orderId=" + orderId);
    },
    onCancelOrder(orderId) {
      this.$confirm("确认取消该订单吗？", "确认取消", {
        confirmButtonText: "确定",
        showCancelButton: false,
      }).then(() => {
        this.$request.onCancleOrderApi({ orderId }).then(() => {
          this.resetIndex();
          this.$message.success("订单取消成功");
        });
      });
    },
    onDelOrder(orderId) {
      this.$confirm("确认删除该订单吗？", "确认删除", {
        confirmButtonText: "确定",
        showCancelButton: false,
      }).then(() => {
        this.$request.onDelOrderApi({ orderId }).then(() => {
          this.resetIndex();
          this.$message.success("订单删除成功");
        });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.demand-list-wrp {
  /deep/ .el-pagination {
    margin-bottom: 40px;
    text-align: center;
  }
  .module-title {
    margin-bottom: 20px;
  }
  .order-item {
    margin-bottom: 20px;
    .head-info {
      display: flex;
      align-items: center;
      text-align: center;
      height: 56px;
      background-color: #fff6f1;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      .time {
        flex: 1;
        padding-left: 20px;
        font-size: 16px;
        color: #1a1a1a;
        text-align: left;
      }
    }
    .type {
      padding-right: 1px;
      width: 135px;
      color: #1a1a1a;
      box-sizing: border-box;
    }
    .class-type,
    .course-num,
    .status {
      padding-right: 1px;
      width: 110px;
      color: #1a1a1a;
      box-sizing: border-box;
    }
    .money,
    .demand {
      padding-right: 1px;
      width: 200px;
      color: #1a1a1a;
      box-sizing: border-box;
    }
    .demand div {
      padding: 8px;
      width: 200px;
      overflow: hidden;
      word-break: break-all;
    }
    .table-wrp {
      border-bottom: 1px solid rgba(255, 95, 25, 0.1);
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }
    table {
      width: 100%;
      tr {
        border-bottom: 1px solid rgba(255, 95, 25, 0.1);
        &:last-child {
          border-bottom: none;
        }
      }
      td {
        font-size: 14px;
        border-right: 1px solid rgba(255, 95, 25, 0.1);
        text-align: center;
        vertical-align: middle;
        box-sizing: border-box;
        &.base-info {
          .base-info-wrp {
            display: flex;
            align-items: center;
            padding: 10px;
            img {
              margin-right: 10px;
              width: 170px;
              height: 100px;
              border-radius: 8px;
            }
            .detail {
              flex: 1;
              .name {
                text-overflow: -o-ellipsis-lastline;
                overflow: hidden; //溢出内容隐藏
                text-overflow: ellipsis; //文本溢出部分用省略号表示
                display: -webkit-box; //特别显示模式
                -webkit-line-clamp: 1; //行数
                line-clamp: 1;
                -webkit-box-orient: vertical; //盒子中内容竖直排列
                margin-bottom: 20px;
                text-align: left;
                font-size: 18px;
                color: #1a1a1a;
              }
              .intro {
                text-overflow: -o-ellipsis-lastline;
                overflow: hidden; //溢出内容隐藏
                text-overflow: ellipsis; //文本溢出部分用省略号表示
                display: -webkit-box; //特别显示模式
                -webkit-line-clamp: 2; //行数
                line-clamp: 2;
                -webkit-box-orient: vertical; //盒子中内容竖直排列
                text-align: left;
                color: #808080;
              }
            }
          }
        }
        &.type,
        &.course-num,
        &.demand,
        &.money,
        &.status {
          color: #666;
        }
        .to-cancel {
          margin-top: 8px;
          font-size: 16px;
          color: #ffa67f;
          cursor: pointer;
          transition: all 0.15s;
          &:hover {
            color: #ff5f19;
          }
        }
      }
    }
  }
  .empty img {
    width: 150px;
    height: 104px;
  }
}
</style>
