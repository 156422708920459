<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "app",
  created() {
    // this.$request.getCourseCategoriesApi().then((res) => {
    //   const categoryList = res.result.categoies || [];
    //   this.$store.commit("setCategoryList", categoryList);
    // });
  },
};
</script>

<style lang="less">
.empty {
  width: max-content;
  margin: 180px auto 0;
  text-align: center;
  img {
    width: 115px;
    height: 105px;
  }
  .text {
    margin-top: 18px;
    font-size: 12px;
    color: #1a1a1a;
  }
}
</style>
