<template>
  <div class="userAgreementWrp">
    <div class="title">用户协议</div>
    <div class="intro">
      欢迎申请使用竞游在线及其他合作运营主体（下列统称为“竞游在线”）提供的产品和服务。请您（下列统称为“用户”）仔细阅读、充分理解以下全部内容。
    </div>
    <div class="node-title">一、服务条款的确认和接纳</div>
    <div class="text">
      1.1本服务条款是用户与<span class="bold">竞游在线</span
      >之间关于其注册以及使用<span class="bold">竞游在线</span
      >提供的相关产品和服务所订立的协议。<span class="bold"
        >如用户选择以页面确认或其他任何方式明示或者默示表示接受本服务条款，或以下载、安装、登录等方式使用竞游在线提供的相关产品和服务，即表示用户与竞游在线已达成协议，自愿接受本服务条款的所有内容。</span
      >如用户不同意本服务条款任意内容，请勿注册手机帐号或使用<span class="bold"
        >竞游在线</span
      >提供的相关产品和服务。
    </div>
    <div class="text">
      <span class="bold"
        >1.2竞游在线有权在必要时通过在网页上发出公告等合理方式修改本服务条款以及各单项条款。用户在享受各单项服务时，应当及时查阅了解修改的内容，并自觉遵守本服务条款以及该单项服务的相关条款。用户如继续使用本服务条款涉及的产品和服务，则视为对修改内容的同意，当发生有关争议时，以最新的条款内容为准；用户在不同意修改内容的情况下，有权停止使用本服务条款涉及的产品和服务。</span
      >
    </div>
    <div class="node-title">二、竞游在线手机帐号服务简介</div>
    <div class="text">
      <span class="bold"
        >2.1本服务条款所称的“竞游在线手机帐号”是指用户以手机号码作为帐号注册的合法、有效的帐号，竞游在线手机帐号注册成功后帐号即为手机号。</span
      >
    </div>
    <div class="text">
      <span class="bold"
        >2.2用户理解并确认，竞游在线手机帐号是竞游在线按照本服务条款授权注册用户使用竞游在线服务的数字标识。在法律法规允许的范围内，竞游在线手机帐号的所有权（手机号码本身除外），以及注册、使用竞游在线手机帐号相关的服务数据和记录，包括但不限于所有注册、登录、消费记录、产品日志、客服记录和相关的使用统计数据等，归竞游在线或具体产品运营主体所有。</span
      >
    </div>
    <div class="text">
      2.3用户完成<span class="bold">竞游在线</span>手机帐号申请注册程序后，<span
        class="bold"
        >竞游在线</span
      >按照本服务条款授权用户使用<span class="bold">竞游在线</span
      >服务，或者以<span class="bold">竞游在线</span
      >明确许可的其他目的，依照<span class="bold">竞游在线</span
      >的服务规则使用该帐号。
    </div>
    <div class="node-title">三、竞游在线手机帐号注册规则</div>
    <div class="text">
      3.1用户确认，在注册或使用<span class="bold">竞游在线</span
      >服务时，应当具备中华人民共和国法律规定的与其行为相适应的民事行为能力，确保有能力对所使用<span
        class="bold"
        >竞游在线</span
      >服务的一切行为独立承担责任。<span class="bold"
        >若用户不具备前述主体资格，请在监护人的陪同下阅读本服务条款，并在取得监护人对本服务条款及对使用竞游在线服务的行为的同意之后，方可注册并使用竞游在线服务；竞游在线在依据法律规定或本服务条款要求用户承担责任时，有权向其监护人追偿。</span
      >
    </div>
    <div class="text">
      3.2<span class="bold">竞游在线</span
      >手机帐号注册资料包括但不限于：用户的手机号码、密码及注册<span
        class="bold"
        >竞游在线</span
      >手机帐号或更新<span class="bold">竞游在线</span
      >手机帐号注册资料时输入的所有信息或更新<span class="bold">竞游在线</span
      >手机帐号时输入的所有信息以及用户使用<span class="bold">竞游在线</span
      >各单项服务时输入的名称、头像等所有信息。用户在注册<span class="bold"
        >竞游在线</span
      >手机帐号时承诺遵守法律法规、社会主义制度、国家利益、公民合法权益、公共秩序、社会道德风尚和信息真实性等底线和准则，不得在<span
        class="bold"
        >竞游在线</span
      >手机帐号注册资料中出现违法和不良信息，且用户保证其在注册和使用帐号时，不得有以下情形：
      <div class="ul-item">（1）违反宪法或法律法规规定的；</div>
      <div class="ul-item">
        （2）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；
      </div>
      <div class="ul-item">（3）损害国家荣誉和利益的，损害公共利益的；</div>
      <div class="ul-item">
        （4）歪曲、丑化、亵渎、否定英雄烈士事迹和精神，以侮辱、诽谤或者其他方式侵害英雄烈士的姓名、肖像、名誉、荣誉的；
      </div>
      <div class="ul-item">
        （5）宣扬恐怖主义、极端主义或者煽动实施恐怖活动、极端主义活动的；
      </div>
      <div class="ul-item">（6）煽动民族仇恨、民族歧视，破坏民族团结的；</div>
      <div class="ul-item">（7）破坏国家宗教政策，宣扬邪教和封建迷信的；</div>
      <div class="ul-item">（8）散布谣言，扰乱社会秩序，破坏社会稳定的</div>
      <div class="ul-item">
        （9）散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；
      </div>
      <div class="ul-item">（10）侮辱或者诽谤他人，侵害他人合法权益的；</div>
      <div class="ul-item">（11）含有法律、行政法规禁止的其他内容的。</div>
      若用户提供给<span class="bold">竞游在线</span
      >的帐号注册资料不准确，不真实，含有违法或不良信息的，<span class="bold"
        >竞游在线</span
      >有权不予注册，并保留终止用户使用<span class="bold">竞游在线</span
      >手机帐号及<span class="bold">竞游在线</span
      >服务的权利。若用户以虚假信息骗取帐号注册或帐号头像、个人简介等注册资料存在违法和不良信息的，<span
        class="bold"
        >竞游在线</span
      >有权采取通知限期改正、暂停使用、注销登记等措施。对于冒用关联机构或社会名人注册帐号名称的，<span
        class="bold"
        >竞游在线</span
      >有权注销该帐号，并向政府主管部门进行报告。
    </div>
    <div class="text">
      3.3 根据相关法律、法规规定以及考虑到<span class="bold">竞游在线</span
      >产品服务的重要性，用户同意：
      <div class="ul-item">
        （1）在注册<span class="bold">竞游在线</span
        >手机帐号时提交个人有效身份信息进行实名认证（包括但不限于手机号码、身份证件号码等）；
      </div>
      <div class="ul-item">
        （2）提供必要、准确的帐户注册资料，并不断更新注册资料，以便<span
          class="bold"
          >竞游在线</span
        >向用户提供帮助，或更好地为用户提供服务。
      </div>
      若用户提交的信息不完整或不准确，则可能无法使用<span class="bold"
        >竞游在线</span
      >服务或在使用过程中受到限制。<span class="bold"
        >竞游在线提醒用户，用户注册竞游在线手机帐号或更新注册信息时填写的证件号码，在注册竞游在线手机帐号成功或补充填写后将无法进行修改，请用户慎重填写各类注册信息。</span
      >
    </div>
    <div class="text">
      3.4用户同意，其提供的真实、准确、合法的<span class="bold">竞游在线</span
      >手机帐号注册资料是作为认定用户与其<span class="bold">竞游在线</span
      >手机帐号的关联性以及用户身份的唯一证据。用户在享用<span class="bold"
        >竞游在线</span
      >各项服务的同时，同意接受<span class="bold">竞游在线</span
      >提供的各类信息服务。发生争议时，用户同意以<span class="bold"
        >竞游在线</span
      >或具体产品运营主体的系统数据为准，<span class="bold">竞游在线</span
      >保证该数据的真实性。但是如果单项条款存在与前述不同的约定，则以单项条款约定为准。
    </div>
    <div class="node-title">四、服务的变更、中止、取消或终止</div>
    <div class="text">
      <span class="bold"
        >4.1竞游在线始终在不断变更和改进服务。竞游在线可能会增加或删除功能，也可能暂停或彻底停止某项服务。用户同意竞游在线根据自身商业决策等原因可能会选择终止竞游在线手机帐号服务或其他竞游在线服务。如有此等情形发生，竞游在线会采取网站公告等合理形式通知用户。除法律有明确规定的情况外，竞游在线有权不经用户申请并直接为其办理余额退款（如有）。
      </span>
    </div>
    <div class="text">
      <span class="bold"
        >4.2经国家行政或司法机关的生效法律文书确认用户存在违法或侵权行为，或者竞游在线根据自身的判断，认为用户的行为涉嫌违反本服务条款或竞游在线不时公布的使用规则等内容，或涉嫌违反法律法规的规定的，则竞游在线有权中止、中断或终止向用户提供服务，并不承担任何责任。</span
      >
    </div>
    <div class="node-title">五、用户隐私制度</div>
    <div class="text">
      <span class="bold"
        >尊重用户隐私是竞游在线的一项基本政策。竞游在线将按照竞游在线网站上公布的隐私政策收集、存储、使用、披露和保护用户的个人信息。请用户完整阅读上述隐私政策，以帮助用户更好地保护自身个人信息。</span
      >
    </div>
    <div class="node-title">六、帐号安全和保障</div>
    <div class="text">
      6.1用户的帐号为其自行设置并由用户自行保管。用户应维持密码及<span
        class="bold"
        >竞游在线</span
      >手机帐号的机密安全，如果用户未保管好自己的帐号和密码而对用户、<span
        class="bold"
        >竞游在线</span
      >或第三方造成损害，用户将负全部责任。
    </div>
    <div class="text">
      6.2<span class="bold">竞游在线</span>根据本服务条款对<span class="bold"
        >竞游在线</span
      >手机帐号的使用授权，仅限于初始申请注册人。未经<span class="bold"
        >竞游在线</span
      >明确许可，初始申请注册人不得赠与、借用、租用、转让或售卖<span
        class="bold"
        >竞游在线</span
      >手机帐号或者以其他方式许可其他主体使用<span class="bold">竞游在线</span
      >手机帐号。否则，用户应当自行承担由此产生的任何责任和后果；同时，<span
        class="bold"
        >竞游在线</span
      >保留在未经通知的情况下中止、中断或终止使用该帐号，并追究上述行为人法律责任的权利。
    </div>
    <div class="text">
      6.3用户同意，若发现任何非法使用用户帐号或安全漏洞的情况，有义务立即通告<span
        class="bold"
        >竞游在线</span
      >。<span class="bold">竞游在线</span
      >将协助用户冻结帐号、更改密码或进行其他安全设置。用户理解并同意，<span
        class="bold"
        >竞游在线</span
      >对其请求采取行动需要合理的时间，对在采取行动前已经产生的以及由用户引发的后果（包括但不限于用户的任何损失）不承担任何责任。
    </div>
    <div class="text">
      6.4为了保护用户帐号的安全，使用户可以更加安全地使用<span class="bold"
        >竞游在线</span
      >服务，<span class="bold">竞游在线</span
      >可能会不断升级、完善我们的安全技术防护措施。若<span class="bold"
        >竞游在线</span
      >根据自己的判断发现用户的帐号存在或可能存在被盗号等安全隐患时，为了保障用户的帐号安全，财产免受损失等，可能会采取向用户发出一定的提示、中止用户对帐号的使用，或者在用户登录或使用帐号时采取一定的措施，包括但不限于要求用户验证/更改/绑定手机号码、修改密码等，用户需配合<span
        class="bold"
        >竞游在线</span
      >进行相应的帐号保护操作。用户理解并同意，如果拒绝<span class="bold"
        >竞游在线</span
      >的帐号保护操作，或未能通过上述帐号保护操作的，中<span class="bold"
        >竞游在线</span
      >有权不予恢复该帐号的使用权，或限制该帐号的部分或全部功能。用户可以向<span
        class="bold"
        >竞游在线</span
      >申请修复上述帐号。为了用户的帐号安全，用户应配合如实提供身份证明及相关资料，以便<span
        class="bold"
        >竞游在线</span
      >进行核实。用户应充分理解其帐号修复申请并不必然被通过，<span class="bold"
        >竞游在线</span
      >有权决定是否同意用户的帐号修复申请。
    </div>
    <div class="node-title">七、用户行为规范</div>
    <div class="text">
      7.1用户理解并同意，<span class="bold">竞游在线</span
      >一直致力于为用户提供文明健康、规范有序的网络环境，用户对<span
        class="bold"
        >竞游在线</span
      >服务的使用必须遵守所有适用于服务以及用户所属和/或所处国家或地区的的地方法律、国家法律和国际法律，不得利用<span
        class="bold"
        >竞游在线</span
      >服务从事违法违规、干扰<span class="bold">竞游在线</span
      >服务正常运营、以及侵犯其他用户或第三方合法权益的行为。
    </div>
    <div class="text">
      <span class="bold"
        >7.2用户不得在竞游在线服务的页面上或利用竞游在线服务制作、复制、发布、传播以下信息：</span
      >
      <div class="ul-item">（1）违反宪法确定的基本原则的；</div>
      <div class="ul-item">
        （2）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；
      </div>
      <div class="ul-item">（3）损害国家荣誉和利益的；</div>
      <div class="ul-item">
        （4）歪曲、丑化、亵渎、否定英雄烈士事迹和精神，以侮辱、诽谤或者其他方式侵害英雄烈士的姓名、肖像、名誉、荣誉的；
      </div>
      <div class="ul-item">
        （5）宣扬恐怖主义、极端主义或者煽动实施恐怖活动、极端主义活动的；
      </div>
      <div class="ul-item">（6）煽动民族仇恨、民族歧视，破坏民族团结的；</div>
      <div class="ul-item">（7）破坏国家宗教政策，宣扬邪教和封建迷信的；</div>
      <div class="ul-item">（8）散布谣言，扰乱社会秩序，破坏社会稳定的；</div>
      <div class="ul-item">
        （9）散布淫秽、色情、赌博、暴力、恐怖或者教唆犯罪的；
      </div>
      <div class="ul-item">（10）侮辱或者诽谤他人，侵害他人合法权益的；</div>
      <div class="ul-item">
        （11）煽动非法集会、结社、游行、示威、聚众扰乱社会秩序的；
      </div>
      <div class="ul-item">（12）以非法民间组织名义活动的；</div>
      <div class="ul-item">
        （13）可能引发未成年人模仿不安全行为和违反社会公德行为、诱导未成年人不良嗜好等损害未成年人身心健康的；
      </div>
      <div class="ul-item">
        （14）散布商业广告，或类似的商业招揽信息、过度营销信息及垃圾信息的；
      </div>
      <div class="ul-item">
        （15）所发表的信息毫无意义的，或刻意使用字符组合以逃避技术审核的；
      </div>
      <div class="ul-item">
        （16）含有法律、行政法规禁止或其他违反社会公序良俗的内容的。
      </div>
    </div>
    <div class="text">
      <span class="bold">7.3用户不得利用竞游在线服务从事以下行为：</span>
      <div class="ul-item">
        （1）故意制作、传播计算机病毒等破坏性程序的，以及非法侵入他人网络、干扰他人网络正常功能、窃取网络数据等危害网络安全的行为；
      </div>
      <div class="ul-item">
        （2）向他人提供专门用于从事侵入网络、干扰网络正常功能及防护措施、窃取网络数据等危害网络安全活动的程序、工具，或明知他人从事危害网络安全的活动的，为其提供技术支持、广告推广、支付结算等帮助的行为
      </div>
      <div class="ul-item">
        （3）设立用于实施诈骗，传授犯罪方法，制作或者销售违禁物品、管制物品等违法犯罪活动的网站、通讯群组的行为；
      </div>
      <div class="ul-item">
        （4）发布、传送、传播、储存侵害他人名誉权、肖像权、个人隐私、知识产权、商业秘密等合法权利的内容，以及从事侵犯他人合法权利的其他行为；
      </div>
      <div class="ul-item">（5）发表、传送、传播骚扰、广告信息及垃圾信息；</div>
      <div class="ul-item">
        （6）干扰或破坏<span class="bold">竞游在线</span
        >服务的正常运行，以及其他危害计算机信息网络安全的行为；
      </div>
      <div class="ul-item">
        （7）其他违反任何相关的法律、法规、规章、条例等其他具有法律效力的规范，以及竞游在线的所有其他规定和程序的行为。
      </div>
    </div>
    <div class="text">
      <span class="bold"
        >7.4除非法律允许或竞游在线书面许可，用户使用竞游在线服务的过程中不得从事下列行为：</span
      >
      <div class="ul-item">
        （1）删除<span class="bold">竞游在线</span
        >服务的应用程序及其副本上关于知识产权的信息；
      </div>
      <div class="ul-item">
        （2）对<span class="bold">竞游在线</span
        >服务的应用程序进行反向工程、反向汇编、反向编译，或者以其他方式尝试发现<span
          class="bold"
          >竞游在线</span
        >服务的应用程序的源代码；
      </div>
      <div class="ul-item">
        （3）对<span class="bold">竞游在线</span
        >拥有知识产权的内容进行使用、出租、出借、复制、修改、链接、转载、汇编、发表、出版、建立镜像站点等；
      </div>
      <div class="ul-item">
        （4）进入<span class="bold">竞游在线</span
        >服务的计算机信息网络、系统或者使用计算机信息网络资源的；
      </div>
      <div class="ul-item">
        （5）对<span class="bold">竞游在线</span
        >服务的应用程序和功能、运行中的指令、存储/处理或者传输的数据进行复制、删除、修改、增加、挂接运行或创作任何衍生作品，形式包括但不限于使用插件、外挂或非<span
          class="bold"
          >竞游在线</span
        >经授权的第三方工具/服务接入<span class="bold">竞游在线</span
        >服务和相关系统；或者将用于上述用途的软件、方法进行运营或向公众传播，无论这些行为是否为商业目的的；
      </div>
      <div class="ul-item">
        （6）通过非<span class="bold">竞游在线</span
        >开发、授权的第三方软件、插件、外挂、系统，登录或使用<span class="bold"
          >竞游在线</span
        >服务，或制作、发布、传播上述工具；
      </div>
      <div class="ul-item">
        （7）自行或者授权他人、第三方软件对<span class="bold">竞游在线</span
        >的应用程序及其组件、模块、数据进行干扰；
      </div>
      <div class="ul-item">
        （8）其他未经<span class="bold">竞游在线</span>明示授权的行为。
      </div>
    </div>
    <div class="text">
      <span class="bold"
        >7.5用户承诺，非经竞游在线同意，用户不能利用竞游在线各项服务进行销售或其他商业用途。如用户有需要将服务用于商业用途，应书面通知竞游在线并获得竞游在线的明确授权。</span
      >
    </div>
    <div class="node-title">八、责任承担和责任限制</div>
    <div class="text">
      <span class="bold"
        >8.1用户须为自己竞游在线手机帐号下的一切行为承担法律责任，包括用户所发表的任何内容以及其它人使用用户的电脑、手机、帐号而产生的任何后果。如用户或使用用户帐号的其他人利用竞游在线的服务侵害他人民事权益的，用户应当承担侵权等法律责任。用户承担法律责任的形式包括但不限于：对受到侵害者进行赔偿，以及在竞游在线首先承担了因用户行为导致的行政处罚或侵权损害赔偿责任后，用户应给予竞游在线等额的赔偿。</span
      >
    </div>
    <div class="text">
      <span class="bold"
        >8.2用户理解，如竞游在线发现或者收到被侵权人举报或投诉用户的行为存在违反相关法律法规、本服务条款规定情形的，竞游在线有权根据自己的判断不经通知随时采取限制、中止、冻结或终止用户对竞游在线服务的使用等措施，并依据法律法规保存有关信息向国家有关机关报告等；由此给用户带来的一切损失（包括但不限于通信中断，用户资料、邮件和虚拟财产及相关数据、增值服务、产品或服务等的清空或无法使用等），由用户自行承担且竞游在线不退还用户已支付但尚未使用完毕的费用。</span
      >
    </div>
    <div class="text">
      <span class="bold"
        >8.3用户理解，为保证竞游在线服务的安全性及产品功能的一致性，竞游在线将不定期对竞游在线服务进行更新。用户应将软件更新到最新版本，如用户未及时更新到最新版本，竞游在线不保证用户一定能正常使用竞游在线服务。</span
      >
    </div>
    <div class="text">
      <span class="bold"
        >8.4 竞游在线对竞游在线手机帐号上所有服务将尽力维护其安全性及方便性，但对服务中出现的信息（包括但不限于用户发布的信息）删除或储存失败不承担任何责任。</span
      >
    </div>
    <div class="text">
      <span class="bold"
        >8.5除非由于竞游在线原因导致，否则竞游在线将不承担任何退还已收取费用的义务。</span
      >
    </div>
    <div class="text">
      <span class="bold"
        >8.6在法律法规允许的范围内，竞游在线不对与本服务条款有关或由本服务条款引起的任何间接的、惩罚性的、特殊的、派生的损失承担赔偿责任。</span
      >
    </div>
    <div class="node-title">九、不可抗力及其他免责事由</div>
    <div class="text">
      <psan class="bold"
        >用户理解并同意，鉴于互联网的特殊性，在使用竞游在线服务的过程中可能会遇到以下情况使服务发生中断，出现下述情况时，不视为竞游在线违约，若由此给用户造成的损失竞游在线将予以免责。</psan
      >
      <div class="ul-item bold">
        （1）不可抗力，包括但不限于自然灾害、疫情、政府行为、法律法规颁布调整、战争动乱、通信线路中断、网络攻击、病毒入侵、网路故障、电信部门技术调整和政府管制等不能预见、不能克服并不能避免的客观情况；
      </div>
      <div class="ul-item bold">
        （2）竞游在线在进行机房维护、设备检修、系统升级或优化时，需要中断服务的；
      </div>
      <div class="ul-item bold">
        （3）由于用户自身或第三方的原因导致的服务中断、终止，包括用户操作不当、第三方产品或服务提供者的软硬件故障等。
      </div>
    </div>
    <div class="node-title">十、通知</div>
    <div class="text">
      10.1所有发给用户的通知都可通过电子邮件、常规的信件或在网站显著位置公告等方式进行传送。<span
        class="bold"
        >竞游在线</span
      >将通过上述方法之一将消息传递给用户，告知他们服务条款的修改、服务变更、或其它重要事情。
    </div>
    <div class="text">
      10.2为使用户及时、全面了解<span class="bold">竞游在线</span
      >提供的各项服务，用户同意<span class="bold">竞游在线</span>可以利用<span
        class="bold"
        >竞游在线</span
      >服务多次、长期地发布商业性广告和商业性短信息，包括但不限于在<span
        class="bold"
        >竞游在线</span
      >服务的登录页面及登录后任何页面内放置商业广告、向<span class="bold"
        >竞游在线</span
      >服务的用户发送商业性广告邮件、商业性短信息以及在<span class="bold"
        >竞游在线</span
      >服务的用户发出的电子邮件内附上商业性广告及／或广告链接等形式。
    </div>
    <div class="node-title">十一、知识产权声明</div>
    <div class="text">
      11.1<span class="bold">竞游在线</span>提供服务时涉及到的<span class="bold"
        >竞游在线</span
      >产品的所有权以及相关软件的知识产权归<span class="bold">竞游在线</span
      >所有。
      <div class="ul-item">
        （1）<span class="bold">竞游在线</span
        >提供的内容包括但不限于：非用户上传/提供的文字、软件、图片、音频、视频、图表、程序代码、界面设计、版面框架、数据资料、账号等，所有这些内容的知识产权及相关智力成果的权利均属于<span
          class="bold"
          >竞游在线</span
        >，并受版权、商标、专利和其它相关法律的保护。未经<span class="bold"
          >竞游在线</span
        >授权，用户不得使用上述内容，并且用户未经许可，不得对上述内容进行包括但不限于复制、销售、重制、再创作等行为。
      </div>
      <div class="ul-item">
        （2）中竞（浙江）教育注册的<span class="bold">竞游在线</span>，以及<span
          class="bold"
          >竞游在线</span
        >的其他标志及<span class="bold">竞游在线</span
        >产品、服务的名称，均为<span class="bold">竞游在线</span
        >之商标（以下简称“<span class="bold">竞游在线</span>标识”）。未经<span
          class="bold"
          >竞游在线</span
        >事先书面同意，用户不得将<span class="bold">竞游在线</span>标识以及<span
          class="bold"
          >竞游在线</span
        >享有相关权利的其他标识以不合法或不合理的任何方式使用或作其他处理，也不得向他人表明用户有权使用<span
          class="bold"
          >竞游在线</span
        >标识。
      </div>
    </div>
    <div class="text">
      <sapn class="bold"
        >11.2竞游在线尊重知识产权并注重保护用户享有的各项权利。在竞游在线服务中，用户可能需要通过上传、发布等各种方式向竞游在线提供内容。在此情况下，用户仍然享有此等内容的完整知识产权。用户承诺在使用竞游在线提供的服务时发布上传的内容（包括但不限于文字、图片、音视频、软件代码等一切受知识产权保护的内容）均由用户原创或已获得合法授权。用户在提供内容时将授予竞游在线一项全球性的免费许可，允许竞游在线使用、传播、复制、修改、再许可、翻译、改编、创作衍生作品、出版、表演及展示此等内容。用户特别授权竞游在线以自己名义或委托第三方对侵权用户上传发布的享有知识产权内容的行为采取法律行动，包括但不限于投诉、诉讼等必要的维权措施。</sapn
      >
    </div>
    <div class="node-title">十二、法律适用及争议解决</div>
    <div class="text">
      <span class="bold"
        >12.1本服务条款适用中华人民共和国的法律，并且排除一切冲突法规定的适用。</span
      >
    </div>
    <div class="text">
      <span class="bold"
        >12.2如使用本服务条款项下服务中出现纠纷的，用户与竞游在线应尽量友好协商解决，若协商不成的，任何一方均有权向被告住所地人民法院提起诉讼。</span
      >
    </div>
    <div class="node-title">十三、青少年用户特别提示</div>
    <div class="text">青少年用户必须遵守全国青少年网络文明公约：</div>
    <div class="text">
      要善于网上学习，不浏览不良信息；要诚实友好交流，不侮辱欺诈他人；要增强自护意识，不随意约会网友；要维护网络安全，不破坏网络秩序；要有益身心健康，不沉溺虚拟时空。
    </div>
    <div class="node-title">十四、其他</div>
    <div class="text">
      15.1<span class="bold">竞游在线</span
      >不行使、未能及时行使或者未充分行使本服务条款或者按照法律规定所享有的权利，不应被视为放弃该权利，也不影响<span
        class="bold"
        >竞游在线</span
      >在将来行使该权利。
    </div>
    <div class="text">
      15.2如本服务条款中的任何条款无论因何种原因完全或部分无效或不具有执行力，本服务条款的其余条款仍应有效且具有约束力，<span
        class="bold"
        >竞游在线</span
      >及用户均应尽力使该条款设定的本意得到实现。
    </div>
  </div>
</template>

<script>
export default {
  name: "SoulProBUserAgreement",

  data() {
    return {};
  },

  created() {
    document.title = "用户协议";
  },

  methods: {},
};
</script>

<style lang="less" scoped>
.userAgreementWrp {
  margin: 0 auto;
  padding: 50px 0 100px;
  width: 1200px;
  overflow: hidden;
  .title {
    display: block;
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    color: #1a1a1a;
  }
  .intro {
    margin-top: 18px;
    margin-bottom: 36px;
    font-weight: bold;
    font-size: 18px;
    color: #333;
  }
  .node-title {
    margin-bottom: 8px;
    font-weight: bold;
    font-size: 22px;
    color: #1a1a1a;
  }
  .text {
    margin-bottom: 30px;
    line-height: 1.7;
    font-size: 18px;
    color: #4c4c4c;
    .ul-item {
      font-size: 16px;
      color: #4c4c4c;
    }
    .bold {
      font-weight: bold;
      font-size: 18px;
      color: #333;
    }
  }
}
</style>