import { render, staticRenderFns } from "./bannerIntroPage.vue?vue&type=template&id=605f8c5e&scoped=true&"
import script from "./bannerIntroPage.vue?vue&type=script&lang=js&"
export * from "./bannerIntroPage.vue?vue&type=script&lang=js&"
import style0 from "./bannerIntroPage.vue?vue&type=style&index=0&id=605f8c5e&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "605f8c5e",
  null
  
)

export default component.exports