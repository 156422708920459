<template>
  <div class="frame-wrp">
    <CHeader></CHeader>
    <div class="container">
      <router-view />
    </div>
    <CFooter></CFooter>
  </div>
</template>

<script>
import CHeader from "./header.vue";
import CFooter from "./footer.vue";
export default {
  name: "Frame",
  data() {
    return {};
  },
  components: {
    CHeader,
    CFooter,
  },
  mounted() {
  },
};
</script>

<style scoped lang="less">
.frame-wrp {
  display: flex;
  flex-direction: column;
  height: 100vh;
  min-width: 1240px;
  overflow-y: auto;
  overflow-x: hidden;
  scroll-behavior: smooth;
  .container {
    position: relative;
    background-color: #fff;
  }
}
</style>