<template>
  <div class="change-code-wrp">
    <div class="module-title">修改密码</div>
    <div class="change-cont-wrp">
      <!-- <div class="menu-wrp">
        <div
          class="item"
          :class="{ active: selectedMenu === item.value }"
          v-for="item in menuList"
          :key="item.value"
          @click="onChooseMenu(item)"
        >
          <div class="text">{{ item.text }}</div>
          <div class="line"></div>
        </div>
      </div> -->
      <div class="form">
        <div class="item">
          <span class="label"
            ><span class="star">*</span
            >{{ selectedMenu === "codeType" ? "旧密码" : "手机号" }}：</span
          >
          <input
            v-model="form.input1"
            :type="selectedMenu === 'codeType' ? 'password' : 'text'"
            minlength="6"
            maxlength="12"
            :placeholder="
              selectedMenu === 'codeType' ? '请输入旧密码' : '请输入手机号'
            "
            autocomplete="off"
          />
        </div>
        <div class="item">
          <span class="label"
            ><span class="star">*</span
            >{{
              selectedMenu === "codeType" ? "新密码" : "手机号验证码"
            }}：</span
          >
          <input
            :class="{ 'small-size': selectedMenu === 'phoneType' }"
            v-model="form.input2"
            maxlength="12"
            :type="selectedMenu === 'codeType' ? 'password' : 'text'"
            :placeholder="
              selectedMenu === 'codeType'
                ? '（6~12位，数字+字母）'
                : '请输入验证码'
            "
            autocomplete="off"
          />
          <div
            class="send-code-btn"
            :class="{ 'has-send-code': hasSendCode }"
            @click="onSendCode()"
            v-if="selectedMenu === 'phoneType'"
          >
            发送验证码
          </div>
        </div>
        <div class="item">
          <span class="label"
            ><span class="star">*</span
            >{{
              selectedMenu === "codeType" ? "再次输入新密码" : "新密码"
            }}：</span
          >
          <input
            v-model="form.input3"
            type="password"
            maxlength="12"
            :placeholder="
              selectedMenu === 'codeType'
                ? '请再次输入新密码'
                : '（6~12位，数字+字母）'
            "
            autocomplete="off"
          />
        </div>
      </div>
      <div
        class="confirm-btn"
        :class="{ light: form.input1 && form.input2 && form.input3 }"
        @click="onSubmit()"
      >
        提交
      </div>
    </div>
  </div>
</template>

<script>
import { getUserInfo } from "../../utils/auth";
export default {
  name: "changeCode",

  data() {
    return {
      menuList: [
        {
          text: "旧密码修改",
          value: "codeType",
        },
        {
          text: "手机号修改",
          value: "phoneType",
        },
      ],
      selectedMenu: "codeType",
      form: {
        input1: "",
        input2: "",
        input3: "",
      },
      hasSendCode: false,
      sendCodeWaitNum: 60,
      timeId: null,
      loading: false,
    };
  },
  methods: {
    onChooseMenu(item) {
      this.selectedMenu = item.value;
      this.form.input1 = "";
      this.form.input2 = "";
      this.form.input3 = "";
      if (item.value === "phoneType") {
        this.form.input1 = getUserInfo() ? getUserInfo().bindedPhone || "" : "";
      }
    },
    onSendCode() {
      if (!this.form.input1.trim()) {
        return this.$message.warning("请先输入手机号");
      }
      const myreg = /^[1][3,4,5,7,8,9][0-9]{9}$/;
      if (!myreg.test(this.form.input1)) {
        return this.$message.warning("请输入正确的手机号");
      }
      if (this.hasSendCode) return;

      this.hasSendCode = true;
      this.$request
        .sendChangeCodeCodeApi({ phone: this.form.input1 })
        .then((res) => {
          this.timeId = setInterval(() => {
            this.sendCodeWaitNum--;
            if (this.sendCodeWaitNum === -1) {
              clearInterval(this.timeId);
              this.hasSendCode = false;
              this.sendCodeWaitNum = 60;
            }
          }, 1000);
        })
        .catch(() => {
          this.hasSendCode = false;
        });
    },
    onSubmit() {
      if (
        !this.form.input1.trim() ||
        !this.form.input2.trim() ||
        !this.form.input3.trim()
      ) {
        return this.$message.warning("请先填写完整信息");
      }
      if (this.selectedMenu === "codeType") {
        if (
          !/^[A-Za-z0-9]+$/.test(this.form.input2) ||
          !/[A-Za-z].*[0-9]|[0-9].*[A-Za-z]/.test(this.form.input2)
        ) {
          return this.$message.warning("密码必须为数字+字母组合");
        }
        if (this.form.input2.length < 6) {
          return this.$message.warning("新密码长度不正确");
        }
      } else {
        const myreg = /^[1][3,4,5,7,8,9][0-9]{9}$/;
        if (!myreg.test(this.form.input1)) {
          return this.$message.warning("请输入正确的手机号");
        }
        if (
          !/^[A-Za-z0-9]+$/.test(this.form.input3) ||
          !/[A-Za-z].*[0-9]|[0-9].*[A-Za-z]/.test(this.form.input3)
        ) {
          return this.$message.warning("密码必须为数字+字母组合");
        }
        if (this.form.input3.length < 6) {
          return this.$message.warning("新密码长度不正确");
        }
      }
      if (this.loading) return;

      const api =
        this.selectedMenu === "codeType"
          ? this.$request.changePasswordApi
          : this.$request.changeCodeByPhoneCodeApi;
      const params =
        this.selectedMenu === "codeType"
          ? {
              oldPwd: this.form.input1,
              newPwd: this.form.input2,
              rePwd: this.form.input3,
            }
          : {
              phone: this.form.input1,
              vCode: this.form.input2,
              newPwd: this.form.input3,
            };
      api(params)
        .then(() => {
          this.$message.success("修改成功");
          this.loading = false;
          this.form = {
            input1: "",
            input2: "",
            input3: "",
          };
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="less" scoped>
.change-code-wrp {
  .change-cont-wrp {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .menu-wrp {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 60px;
    margin-bottom: 50px;
    .item {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 66px;
      cursor: pointer;
      .text {
        font-size: 22px;
        color: #808080;
      }
      .line {
        opacity: 0;
        margin-top: 2px;
        width: 118px;
        height: 2px;
        background-color: #e85900;
        border-radius: 1px;
      }
      &.active {
        .text {
          color: #ff5f19;
        }
        .line {
          opacity: 1;
        }
      }
    }
  }
  .form {
    margin-top: 140px;
    margin-right: 30px;
    .item {
      display: flex;
      align-items: center;
      margin-bottom: 40px;
      text-align: right;
      .label {
        min-width: 136px;
        font-size: 16px;
        color: #1a1a1a;
        .star {
          color: #ff5f19;
        }
      }
      input {
        padding-left: 12px;
        width: 206px;
        height: 24px;
        font-size: 16px;
        color: #1a1a1a;
        background-color: #f5f5f5;
        border: 1px solid #e6e6e6;
        border-radius: 2px;
        &.small-size {
          width: 106px;
        }
      }
      .send-code-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 18px;
        width: 80px;
        height: 24px;
        font-size: 14px;
        color: #ffaf8c;
        border: 1px solid #ffaf8c;
        border-radius: 4px;
        cursor: pointer;
        transition: all 0.15s;
        &:hover {
          color: #ff5f19;
          border: 1px solid #ff5f19;
        }
        &.has-send-code {
          color: #ccc;
          border: 1px solid #ccc;
          cursor: default;
        }
      }
    }
  }
  .confirm-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 34px;
    font-size: 18px;
    color: #fff;
    background-color: #ccc;
    border-radius: 17px;
    cursor: pointer;
    &.light {
      background-color: #ffaf8c;
      transition: all 0.15s;
      &:hover {
        background-color: #ff5f19;
      }
    }
  }
}
</style>
