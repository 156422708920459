<template>
  <div class="login-model-wrp">
    <div class="mask" @click="close" :class="{ light: isActive }"></div>
    <div class="model" :class="{ light: isActive }">
      <div class="menu-wrp">
        <div
          class="item"
          :class="{ active: item.value === selectedMenu }"
          v-for="item in menuList"
          :key="item.value"
          @click="onChooseMenu(item)"
        >
          {{ item.text }}
        </div>
      </div>
      <div class="login-form">
        <el-input
          v-model="account"
          :placeholder="selectedMenu === 'code' ? '请输入手机号' : '用户名'"
        ></el-input>
        <div class="code-wrp" :class="{ 'need-code': selectedMenu === 'code' }">
          <el-input
            type="password"
            v-model="code"
            :class="{ 'small-size': selectedMenu === 'code' }"
            :placeholder="
              selectedMenu === 'code' ? '请输入验证码' : '请输入密码'
            "
            @keyup.enter.native="login()"
          ></el-input>
          <div
            class="send-code"
            :class="{ hasSent: hasSent }"
            v-if="selectedMenu === 'code'"
            @click="sendCode()"
          >
            {{ hasSent ? timeNum + "秒" : "发送验证码" }}
          </div>
        </div>
      </div>
      <div class="confirm-btn" @click="login()">登录</div>
    </div>
  </div>
</template>

<script>
import { setToken, setUserInfo } from "../../utils/auth";
export default {
  name: "LoginModel",
  props: {
    close: {
      type: Function,
      default: null,
    },
    onLogin: {
      type: Function,
      default: null,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      menuList: [
        {
          text: "密码登录",
          value: "password",
        },
        // {
        //   text: "验证码登录",
        //   value: "code",
        // },
      ],
      selectedMenu: "password",
      account: "",
      code: "",
      hasSent: false,
      timeId: null,
      timeNum: 60,
      loading: false,
    };
  },
  methods: {
    onChooseMenu(item) {
      this.selectedMenu = item.value;
      this.account = "";
      this.code = "";
    },
    sendCode() {
      if (!this.account.trim()) {
        return this.$message.warning("请先输入手机号");
      }
      if (this.hasSent) return;
      this.hasSent = true;
      this.$request
        .sendLoginCodeApi({ phone: this.account })
        .then((res) => {
          this.timeId = setInterval(() => {
            this.timeNum--;
            if (this.timeNum === -1) {
              clearInterval(this.timeId);
              this.timeId = null;
              this.hasSent = false;
            }
          }, 1000);
        })
        .catch((err) => {
          this.hasSent = false;
        });
    },
    login() {
      if (!this.account.trim()) {
        return this.$message.warning(
          `请先输入${this.selectedMenu === "password" ? "用户名" : "手机号"}`
        );
      }
      if (!this.code.trim()) {
        return this.$message.warning(
          `请先输入${this.selectedMenu === "password" ? "密码" : "验证码"}`
        );
      }
      if (this.loading) return;
      this.loading = true;
      const params =
        this.selectedMenu === "password"
          ? {
              operatorName: this.account,
              password: this.code,
            }
          : {
              phone: this.account,
              vCode: this.code,
            };
      this.$request
        .loginApi(params)
        .then((res) => {
          console.log(res, "----登录成功----");
          this.close();
          setTimeout(() => {
            this.loading = false;
          }, 300);
          setToken(res.result.accesstoken);
          const info = {
            operatorInfo: res.result.operatorInfo || {},
            organInfo: res.result.organInfo || {},
          };
          // setUserInfo(info);
          this.$store.commit("changeUserInfo", info);
          this.$store.commit("checkIsLogin");
          this.$message.success("登录成功");
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped lang="less">
.login-model-wrp {
  z-index: 20;
  position: fixed;
  width: 100vw;
  height: 100vh;
  .mask {
    position: absolute;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.4);
    opacity: 0;
    transition: all 0.3s;
    &.light {
      opacity: 1;
      transition: all 0.3s;
    }
  }
  .model {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -52%, 0);
    padding: 0 57px;
    width: 416px;
    height: 466px;
    background-image: url("../../assets/images/login-model.png");
    box-sizing: border-box;
    opacity: 0;
    transition: all 0.3s;
    &.light {
      transition: all 0.3s;
      opacity: 1;
      transform: translate3d(-50%, -50%, 0);
    }
    .menu-wrp {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      margin-top: 132px;
      margin-bottom: 40px;
      .item {
        line-height: 1;
        font-size: 22px;
        color: #808080;
        cursor: pointer;
        &.active {
          text-decoration: underline;
          font-size: 26px;
          color: #ff6505;
        }
      }
    }
    .login-form {
      .code-wrp {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 30px;
        height: 42px;
        .send-code {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 116px;
          height: 42px;
          line-height: 1;
          font-size: 18px;
          color: #b3b3b3;
          border: 1px solid #e6e6e6;
          border-radius: 15px;
          cursor: pointer;
          transition: all 0.15s;
          &:hover {
            color: #ff6505;
            border-color: #ff6505;
          }
          &.hasSent {
            color: #ff6505;
            border-color: #ff6505;
          }
        }
      }
    }
    .confirm-btn {
      position: absolute;
      left: 50%;
      bottom: 58px;
      transform: translateX(-50%);
      width: 300px;
      height: 42px;
      line-height: 42px;
      text-align: center;
      font-size: 22px;
      color: #fff;
      background-color: #ffb88c;
      border-radius: 15px;
      cursor: pointer;
      transition: all 0.15s;
      &:hover {
        background-color: #ff6505;
      }
    }
  }
}
</style>
