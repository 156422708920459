<template>
  <div class="pay-result-wrp">
    <img
      class="icon"
      src="@/assets/images/pay-success-img.png"
      v-if="result === 'success'"
    />
    <img
      class="icon"
      src="@/assets/images/pay-wait-img.png"
      v-if="result === 'wait'"
    />
    <img
      class="icon"
      src="@/assets/images/pay-fail-img.png"
      v-if="result === 'fail'"
    />

    <div class="end-time" v-if="result === 'wait'">
      请在{{ endTimeStr }}前完成支付
    </div>

    <img class="pay-code" :src="payPageUrl" v-if="result === 'wait' && payType === '2'" />
    <iframe
      v-if="result === 'wait' && payType === '1'"
      id="codeIframe"
      ref="codeIframe"
      width="204px"
      height="220px"
      :src="payPageUrl"
    ></iframe>

    <div class="title" v-if="result !== 'wait'">{{ title }}</div>
    <div class="text">{{ text }}</div>
    <div class="btn" v-if="btn" @click="onHandle()">{{ btn }}</div>
  </div>
</template>

<script>
import QRcode from "qrcode";
export default {
  name: "payResult",
  data() {
    return {
      result: "wait",
      orderId: "",
      payType: "",
      payPageUrl: "",
      endTimeStr: " ",
    };
  },
  created() {
    this.result = this.$route.query.payResult;
    this.orderId = this.$route.query.orderId;
    this.payType = this.$route.query.payType;
    const _payPageUrl = localStorage.getItem("payForm") || "";
    if (this.payType === "2") {
      QRcode.toDataURL(
        _payPageUrl,
        {
          errorCorrectionLevel: "L",
          margin: 2,
          width: 226,
        },
        (err, res) => {
          if (err) {
            console.log('支付二维码链接：' + _payPageUrl);
          }
          this.payPageUrl = res;
        }
      );
    } else {
      this.payPageUrl = _payPageUrl;
    }
    this.$request
      .onGetOrderDetailApi({ orderId: this.orderId })
      .then((res) => {
        const status = res.result.orderInfo.status;
        this.endTimeStr = res.result.orderInfo.overTime.slice(11) || " ";
        if (status === 2) {
          this.result = "wait";
        } else if (status === 3) {
          this.result = "success";
        } else {
          this.result = "fail";
        }
      })
      .catch(() => {
        this.result = "fail";
      });
  },
  computed: {
    title() {
      return this.result === "success"
        ? "支付成功"
        : this.result === "wait"
        ? "等待中"
        : "支付失败";
    },
    text() {
      if (this.result !== "success") {
        return this.result === "wait"
          ? "如已支付请稍后，无需重新购买"
          : "如已支付，请稍后查看，无需重新购买";
      } else {
        return "您已成功购买课程";
      }
    },
    btn() {
      return this.result === "success"
        ? "查看订单"
        : this.result === "wait"
        ? "我已支付，点击确认"
        : "重新支付";
    },
  },
  methods: {
    onHandle() {
      if (this.result === "success") {
        this.$router.push("/manage-center/course-order");
      } else if (this.result === "fail") {
        this.$router.back();
      } else {
        this.$request
          .onGetOrderDetailApi({ orderId: this.orderId })
          .then((res) => {
            const status = res.result.orderInfo.status;
            if (status === 2) {
              this.$message.warning("订单支付中，请耐心等待");
              this.result = "wait";
            } else if (status === 3) {
              this.result = "success";
            } else {
              this.result = "fail";
            }
          })
          .catch(() => {
            this.result = "fail";
          });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.pay-result-wrp {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
  min-height: 680px;
  box-shadow: 0 0 6px rgba(219, 124, 65, 0.1);
  .icon {
    margin-left: 12px;
    margin-top: 93px;
    width: 162px;
    height: 162px;
  }
  .title {
    margin: 25px 0 60px;
    font-size: 30px;
    color: #f3640d;
  }

  .end-time {
    color: #f3640d;
    font-size: 20px;
  }

  .pay-code {
    margin: 10px 0;
  }
  iframe {
    margin-top: 24px;
  }
  .text {
    margin-bottom: 40px;
    font-size: 26px;
    color: #666;
  }
  .btn {
    padding: 0 40px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    font-size: 28px;
    color: #fff;
    background-color: #f3640d;
    border-radius: 25px;
    cursor: pointer;
  }
}
</style>
