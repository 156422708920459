<template>
  <div class="student-consume-wrp">
    <div class="module-title">数据统计</div>
    <div class="panel-cont">
      <div class="table-wrp" v-if="sourceData.length">
        <el-table :data="sourceData" style="width: 100%">
          <el-table-column prop="studentName" label="姓名" align="center">
          </el-table-column>
          <el-table-column prop="className" label="班级" align="center">
          </el-table-column>
          <el-table-column prop="time" label="时间" align="center">
          </el-table-column>
          <el-table-column prop="orderAmountTotal" label="消费金额" align="center">
          </el-table-column>
        </el-table>
        <el-pagination
          v-if="pageData.total > pageData.size"
          layout="prev, pager, next"
          :total="pageData.total"
          :current-page.sync="pageData.index"
          :page-size="pageData.size"
          @current-change="onPageChange"
        >
        </el-pagination>
      </div>
      <div class="empty" v-if="!sourceData.length && show">
        <img src="@/assets/images/empty-consume.png" alt="" />
        <div class="text">暂无消费记录哦~</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "classList",

  data() {
    return {
      show: false,
      year: "",
      month: "",
      pageData: {
        index: 1,
        size: 10,
        total: 1,
      },
      sourceData: [],
    };
  },

  created() {
    this.year = +this.$route.query.year;
    this.month = +this.$route.query.month;
    this.onGetSourceData();
  },

  methods: {
    resetIndex() {
      this.pageData.index = 1;
      this.onGetSourceData();
    },
    onGetSourceData() {
      this.$request
        .onGetConsumeDetailApi({
          year: this.year,
          month: this.month,
          offset: (this.pageData.index - 1) * this.pageData.size,
          size: this.pageData.size,
        })
        .then((res) => {
          this.sourceData = res.result.data || [];
          this.pageData.total = res.result.total;
          this.show = true;
        });
    },
    onPageChange(index) {
      this.pageData.index = index;
      this.$store.commit("panelScrollToTop");
      this.onGetSourceData();
    },
    onReset() {
      this.resetIndex();
    },
  },
};
</script>

<style lang="less" scoped>
.student-consume-wrp {
  .empty img {
    width: 100px;
    height: 135px;
  }
  /deep/ .el-table {
    &::after,
    &::before {
      display: none;
    }
    tr:hover td.el-table__cell {
      background-color: #fff;
    }
    td,
    th {
      height: 66px;
      font-size: 16px;
      border-color: rgba(255, 95, 25, 0.1);
      .cell {
        padding: 0;
      }
    }
    th.is-leaf,
    td.is-leaf {
      border-color: rgba(255, 95, 25, 0.1);
    }
    th {
      color: #1a1a1a;
    }
    td {
      color: #4d4d4d;
    }
  }
  /deep/ .el-pagination {
    margin-top: 40px;
    margin-bottom: 40px;
    text-align: center;
  }
}
</style>
