<template>
  <div class="footer-wrp">
    <div class="cont">
      <div class="left">
        <div class="company-icon">
          <img src="../assets/images/logo.png" alt="" />
        </div>
        <div class="text">
          竞游在线，是中竞教育倾力打造的在线课程学习平台，主要为学习者提供海量、优质的课程，课程结构严谨，用户可以根据自身的学习程度，自主安排学习进度。我们的宗旨是，为每一位想真真正正学到些实用知识、技能的学习者，提供贴心的一站式学习服务。
        </div>
        <div class="text">
          中竞（浙江）教育科技有限公司版权所有丨2022-2022 CESE - All Rights
          Reserved
        </div>
        <div class="text">备案号：浙ICP备17027602号-3</div>
      </div>
      <div class="right">
        <div class="item">
          <img src="../assets/images/地址.png" alt="" class="icon" />
          <span class="text"
            >地址：杭州市滨江区滨安路 435 号 电魂大厦 8 楼</span
          >
        </div>
        <div class="item">
          <img src="../assets/images/电话.png" alt="" class="icon" />
          <span class="text">联系电话：0571-86823011</span>
        </div>
        <div class="item">
          <img src="../assets/images/邮件.png" alt="" class="icon" />
          <span class="text">联系邮箱：liufj@zdjjy.com</span>
        </div>
        <div class="item">
          <span class="link-btn" @click="onToDianhun()">电魂网络</span>
          <span class="link-btn" @click="onToZhongjing()">中竞教育</span>
          <span class="link-btn" @click="onToUserAgreement()">用户协议</span>
          <span class="link-btn" @click="onToPrivacyPolicy()">隐私政策</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  methods: {
    onToDianhun() {
      window.open("https://www.dianhun.cn/");
    },
    onToZhongjing() {
      window.open("https://www.zdjjy.com/");
    },
    onToUserAgreement() {
      const path = location.origin + "/#/user-agreement";
      window.open(path, "_blank");
    },
    onToPrivacyPolicy() {
      const path = location.origin + "/#/privacy-policy";
      window.open(path, "_blank");
    },
  },
};
</script>

<style scoped lang="less">
.base-center {
  display: flex;
  align-items: center;
}
.footer-wrp {
  margin-top: auto;
  &:extend(.base-center);
  justify-content: center;
  height: 300px;
  background-color: #26160b;
  .cont {
    &:extend(.base-center);
    justify-content: space-between;
    width: 1200px;
    height: 100%;
    .left,
    .right {
      height: 100%;
      box-sizing: border-box;
    }
    .left {
      width: 616px;
      .company-icon {
        &:extend(.base-center);
        justify-content: center;
        margin-top: 30px;
        margin-bottom: 13px;
        width: 100px;
        height: 100px;
        border-radius: 10px;
        overflow: hidden;
        img {
          height: 90px;
          width: 92px;
        }
      }
      .text {
        line-height: 1.86;
        font-size: 14px;
        color: #ccc;
        &:nth-child(2) {
          margin-bottom: 6px;
        }
      }
    }
    .right {
      padding-top: 144px;
      width: max-content;
      .item {
        &:extend(.base-center);
        margin-bottom: 20px;
        .icon {
          margin-right: 7px;
          height: 16px;
          width: 16px;
        }
        .text {
          line-height: 1;
          font-size: 14px;
          color: #b3b3b3;
        }
        .link-btn {
          margin-right: 35px;
          font-size: 14px;
          color: #999;
          cursor: pointer;
          transition: all 0.15s;
          &:hover {
            color: #fff;
          }
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
}
</style>
