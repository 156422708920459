<template>
  <div class="my-course-detail-wrp">
    <div class="module-title">
      我的课程-<span>{{ courseName }}</span>
    </div>
    <div class="summary">
      <div class="item">
        <div class="value">1</div>
        <div class="label">总学习人数</div>
      </div>
      <div class="item">
        <div class="value">1</div>
        <div class="label">总完成人数</div>
      </div>
      <div class="item">
        <div class="value">1</div>
        <div class="label">完课率</div>
      </div>
    </div>
    <div class="query">
      <div class="left">
        <div class="item">
          <span class="label">学生姓名：</span>
          <el-input v-model="name" placeholder="请输入学生姓名"></el-input>
        </div>
        <div class="item">
          <span class="label">班级：</span>
          <el-select v-model="className" placeholder="请选择班级">
            <el-option
              v-for="item in classList"
              :key="item.classId"
              :label="item.className"
              :value="item.classId"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="right">
        <div class="btn search" @click="resetIndex()">查询</div>
        <div class="btn reset" :class="{ light: name }" @click="onReset()">
          重置
        </div>
      </div>
    </div>
    <div class="table-wrp">
      <el-table :data="sourceData" border style="width: 100%">
        <el-table-column prop="name" label="姓名" width="160">
        </el-table-column>
        <el-table-column prop="class" label="班级" width="160">
        </el-table-column>
        <el-table-column prop="time" label="最后学习时间" width="250">
        </el-table-column>
        <el-table-column prop="progress" label="学习进度"> </el-table-column>
        <el-table-column prop="" label="操作"
          ><div class="btn" @click="detailModelVsb = true">
            查看详情
          </div></el-table-column
        >
      </el-table>
    </div>
    <el-pagination
      v-if="pageData.total > pageData.size"
      layout="prev, pager, next"
      :total="pageData.total"
      :current-page.sync="pageData.index"
      :page-size="pageData.size"
      @current-change="onPageChange"
    >
    </el-pagination>
    <el-dialog title="课程详情" :visible.sync="detailModelVsb" width="964px">
      <div class="model-table-wrp">
        <el-table :data="modelSourceData" border style="width: 100%">
          <el-table-column prop="sections" label="章节"> </el-table-column>
          <el-table-column prop="classStatus" label="课程状态" width="110">
            <template slot-scope="scope">
              <div
                :class="{
                  light: scope.row.classStatus === 2,
                  gray: scope.row.classStatus === 3,
                }"
              >
                {{ scope.row.classStatus }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="finishTime" label="完成时间" width="200">
          </el-table-column>
          <el-table-column prop="homeworkStatus" label="作业状态" width="110">
            <template slot-scope="scope">
              <div
                :class="{
                  light: scope.row.classStatus === 2,
                  gray: scope.row.classStatus === 3,
                }"
              >
                {{ scope.row.classStatus }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="submitTime" label="提交时间" width="200">
          </el-table-column>
          <el-table-column prop="grade" label="作业成绩" width="110">
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "courseDetail",

  data() {
    return {
      courseId: "",
      courseName: "",
      name: "",
      className: "",
      classList: [],
      pageData: {
        index: 1,
        size: 6,
        total: 0,
      },
      loading: false,
      sourceData: [
        {
          name: "张三",
          class: "90",
          time: "2022-05-08 16:37:25",
          progress: "10",
        },
        {
          name: "张三",
          class: "90",
          time: "2022-05-08 16:37:25",
          progress: "10",
        },
      ],
      detailModelVsb: false,
      modelSourceData: [
        {
          sections: "第一章第一节",
          classStatus: 1,
          finishTime: "2022-05-08",
          homeworkStatus: 2,
          submitTime: "2022-05-08",
          grade: "A",
        },
        {
          sections: "第一章第一节",
          classStatus: 2,
          finishTime: "2022-05-08",
          homeworkStatus: 1,
          submitTime: "2022-05-08",
          grade: "A",
        },
      ],
    };
  },
  created() {
    this.courseName = this.$route.query.courseName || "";
    this.courseId = this.$route.query.courseId || "";
    this.classList = this.$store.state.course.classList;
    this.resetIndex();
  },
  methods: {
    onReset() {
      this.name = "";
      this.resetIndex();
    },
    resetIndex() {
      this.pageData.index = 1;
      this.onGetStudentProgressList();
    },
    onPageChange(index) {
      this.pageData.index = index;
      this.onGetStudentProgressList();
    },
    onGetStudentProgressList() {
      if (this.loading) return;
      this.loading = true;
      this.$request
        .onGetClassCourseProgressListApi({
          courseId: this.courseId,
          classId: this.classId,
          name: this.name,
        })
        .then((res) => {
        });
    },
  },
};
</script>

<style lang="less" scoped>
.my-course-detail-wrp {
  .summary {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 20px 60px;
    .item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 141px;
      height: 100px;
      border-radius: 10px;
      box-shadow: 0px 0px 6px 0px rgba(219, 124, 65, 0.1);
      .value {
        margin-bottom: 6px;
        font-size: 26px;
        color: #1a1a1a;
      }
      .label {
        font-size: 20px;
        color: #1a1a1a;
      }
    }
  }
  .query {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0;
    .left {
      display: flex;
      align-items: center;
      .item {
        display: flex;
        align-items: center;
      }
      .label {
        white-space: nowrap;
        margin-left: 20px;
        line-height: 1;
        font-size: 16px;
        color: #1a1a1a;
      }
    }
    .right {
      display: flex;
      .btn {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 20px;
        width: 62px;
        height: 30px;
        box-sizing: border-box;
        border-radius: 10px;
        cursor: pointer;
        &.search {
          font-size: 16px;
          color: #fff;
          background-color: #ffaf8c;
          transition: all 0.15s;
          &:hover {
            background-color: #ff5f19;
          }
        }
        &.reset {
          border: 1px solid #ccc;
          color: #ccc;
          &.light {
            border-color: #ffaf8c;
            color: #ffaf8c;
            transition: all 0.15s;
            &:hover {
              border-color: #ff5f19;
              color: #ff5f19;
            }
          }
        }
      }
    }
  }
  .table-wrp {
    padding: 0 20px 40px;
  }
  .model-table-wrp {
    padding: 20px;
  }
  /deep/ .el-table {
    border-radius: 10px;
    border-color: rgba(255, 95, 25, 0.1);
    tr:last-child td:last-child {
      border-bottom-right-radius: 10px;
    }
    tr:hover td.el-table__cell {
      background-color: #fff;
    }
    &::after,
    &::before {
      display: none;
    }
    td,
    th {
      padding-left: 20px;
      height: 55px;
      font-size: 16px;
      border-color: rgba(255, 95, 25, 0.1);
      .cell {
        padding: 0;
      }
    }
    th.is-leaf {
      border-color: rgba(255, 95, 25, 0.1);
    }
    th {
      color: #1a1a1a;
    }
    td {
      color: #4d4d4d;
      .btn {
        color: #ffaf8c;
        cursor: pointer;
        transition: all 0.15s;
        &:hover {
          color: #ff5f19;
        }
      }
      .light {
        color: #ff5f19;
      }
      .gray {
        color: #808080;
      }
    }
  }
}
</style>
