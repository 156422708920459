<template>
  <div class="my-course-wrp">
    <div class="module-title">我的课程</div>
    <div class="panel-cont">
      <div class="head-wrp" v-if="sourceData.length">
        <div class="thumb">课程</div>
        <div class="name">课程名称</div>
        <div class="type">课程类型</div>
        <div class="num">课时数</div>
        <div class="handle">操作</div>
      </div>
      <template v-if="sourceData.length">
        <div class="item" v-for="item in sourceData" :key="item.id">
        <div class="thumb">
          <img :src="item.course.thumb" alt="">
        </div>
          <div class="name">{{ item.course.name }}</div>
          <div class="type">{{ courseTypes[item.course.mode] }}</div>
          <div class="num">{{ item.course.lessonCount }}</div>
          <div class="handle">
            <div
              class="btn"
              @click.stop="onToClassCourse(item.id, item.course.name)"
            >
              查看班级
            </div>
          </div>
        </div>
        <el-pagination
          v-if="pageData.total > pageData.size"
          layout="prev, pager, next"
          :total="pageData.total"
          :current-page.sync="pageData.index"
          :page-size="pageData.size"
          @current-change="onPageChange"
        >
        </el-pagination>
      </template>
      <div class="empty" v-if="!sourceData.length && show">
        <img src="@/assets/images/empty-course.png" alt="" />
        <div class="text">还没有购买课程哦~</div>
      </div>
    </div>
  </div>
</template>

<script>
import { courseTypes as _courseTypes } from "../../utils/options";
export default {
  name: "SoulProCMyCourse",
  data() {
    return {
      show: false,
      activeName: "",
      pageData: {
        index: 1,
        size: 10,
        total: 1,
      },
      sourceData: [],
    };
  },
  computed: {
    courseTypes() {
      return _courseTypes;
    },
  },
  created() {
    this.getCourseList();
  },
  methods: {
    getCourseList() {
      this.$request
        .getMyCourseListDataApi({
          offset: (this.pageData.index - 1) * this.pageData.size,
          size: this.pageData.size,
        })
        .then((res) => {
          this.sourceData = res.result.organCourseInfos || [];
          this.show = true;
        });
    },
    onPageChange(index) {
      this.pageData.index = index;
      this.$store.commit("panelScrollToTop");
      this.getCourseList();
    },
    onOpen(item) {
      this.activeName = this.activeName === item ? "" : item;
    },
    onToClassCourse(courseId, courseName) {
      this.$router.push(
        `/manage-center/class-course-list?courseId=${courseId}&courseName=${courseName}`
      );
    },
  },
};
</script>

<style lang="less" scoped>
.my-course-wrp {
  /deep/ .el-pagination {
    margin-top: 40px;
    margin-bottom: 40px;
    text-align: center;
  }
  .head-wrp,
  .collapse-head,
  .item {
    display: flex;
    height: 60px;
    line-height: 60px;
    font-size: 16px;
    color: #666;
    overflow: hidden;
    .thumb {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 20px;
      width: 150px;
      img {
        width: 120px;
        height: 84px;
        border-radius: 6px;
      }
    }
    .name {
      flex: 1;
      width: 300px;
      text-align: left;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .type {
      width: 240px;
      text-align: center;
    }
    .num {
      width: 220px;
      text-align: center;
    }
    .handle {
      display: flex;
      justify-content: flex-end;
      padding-right: 20px;
      min-width: 120px;
    }
  }
  .head-wrp {
    font-weight: bold;
    font-size: 16px;
    color: #1a1a1a;
    border-bottom: 1px solid rgba(255, 95, 25, 0.1);
  }
  .item {
    height: 100px;
    line-height: 100px;
    border-bottom: 1px solid rgba(255, 95, 25, 0.1);
    .handle .btn {
      margin-right: 20px;
      color: #ffaf8c;
      cursor: pointer;
      transition: all 0.15s;
      &:last-child {
        margin-right: 0;
      }
      &:hover {
        color: #ff5f19;
      }
    }
  }
  .collapse-head {
    height: 58px;
    line-height: 58px;
    font-size: 18px;
    color: #1a1a1a;
    .handle .btn {
      color: #ffaf8c;
      cursor: pointer;
      transition: all 0.15s;
      &:first-child {
        margin-right: 10px;
      }
      &:hover {
        color: #ff5f19;
      }
    }
  }
  .class-list {
    margin: 0 auto 20px;
    padding: 20px 80px;
    width: 940px;
    border-radius: 10px;
    background-color: #fffaf7;
    box-sizing: border-box;
    .item {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      .left {
        display: flex;
        align-items: center;
        font-size: 16px;
        color: #1a1a1a;
        .class {
          display: flex;
          align-items: center;
          padding-left: 20px;
          width: 206px;
          height: 24px;
          border: 1px solid #e6e6e6;
          background-color: #f5f5f5;
          border-radius: 2px;
          font-size: 14px;
          color: #808080;
        }
      }
      .btn {
        font-size: 14px;
        color: #ffaf8c;
        cursor: pointer;
        transition: all 0.15s;
        &:hover {
          color: #ff5f19;
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  /deep/ .el-collapse-item {
    border-bottom: 1px solid rgba(255, 95, 25, 0.1);
  }
}
</style>
