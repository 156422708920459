<template>
  <div class="student-consume-wrp">
    <div class="module-title">学生消费</div>
    <div class="panel-cont">
      <div class="query">
        <div class="left">
          <div class="item">
            <span class="label">年份：</span>
            <el-date-picker
              v-model="year"
              type="year"
              @change="changeYear"
              placeholder="选择n年份"
            >
            </el-date-picker>
          </div>
        </div>
        <div class="right">
          <div class="btn search" @click="resetIndex()">查询</div>
          <div
            class="btn reset light"
            @click="onReset()"
          >
            重置
          </div>
        </div>
      </div>
      <div class="table-wrp" v-if="sourceData.length">
        <el-table :data="sourceData" style="width: 100%">
          <el-table-column prop="fullTime" label="月份" width="160">
          </el-table-column>
          <el-table-column prop="money" label="当月消费" align="center">
          </el-table-column>
          <el-table-column prop="" label="操作" width="200">
            <template slot-scope="scope">
              <div class="btns" v-if="scope.row.money">
                <div class="btn" @click="onToConsumeDetail(scope.row)">
                  查看详情
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          v-if="pageData.total > pageData.size"
          layout="prev, pager, next"
          :total="pageData.total"
          :current-page.sync="pageData.index"
          :page-size="pageData.size"
          @current-change="onPageChange"
        >
        </el-pagination>
      </div>
      <div class="empty" v-if="!sourceData.length && show">
        <img src="@/assets/images/empty-consume.png" alt="" />
        <div class="text">暂无消费记录哦~</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "classList",

  data() {
    return {
      show: false,
      year: null,
      pageData: {
        index: 1,
        size: 12,
        total: 1,
      },
      sourceData: [],
      baseData: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', ]
    };
  },

  created() {
    const _time = this.$route.query.year;
    this.year = _time ? new Date(_time) : new Date();
    this.onGetSourceData();
  },

  methods: {
    resetIndex() {
      this.pageData.index = 1;
      this.onGetSourceData();
    },
    onGetSourceData() {
      this.$request
        .onGetConsumeListApi({
          year: this.year.getFullYear(),
          offset: (this.pageData.index - 1) * this.pageData.size,
          size: this.pageData.size,
        })
        .then((res) => {
          const _data = res.result || [];
          this.sourceData = this.baseData.map(item => {
            const nowYear = this.year.getFullYear();
            const _month = _data.find(v => v.month === +(nowYear + item));
            return {
              month: +item,
              fullTime: `${this.year.getFullYear()}.${item}`,
              money: _month ? _month.orderAmountTotal : 0
            }
          }).filter(item => {
            const nowYear = new Date().getFullYear();
            const selectedYear = this.year.getFullYear();
            const nowMonth = new Date().getMonth() + 1;
            if (nowYear > selectedYear) {
              return true;
            } else if (nowYear < selectedYear) {
              return false;
            } else {
              return item.month <= nowMonth;
            }
          });
          this.show = true;
        });
    },
    onPageChange(index) {
      this.pageData.index = index;
      this.$store.commit("panelScrollToTop");
      this.onGetSourceData();
    },
    onReset() {
      this.year = new Date();
      this.resetIndex();
    },
    changeYear(value) {
      const originPath = location.origin + "/#";
      window.location.replace(
        originPath + `/manage-center/student-consume?year=${value.getFullYear()}`
      );
    },
    onToConsumeDetail(item) {
      this.$router.push(
        `/manage-center/consume-detail?year=${this.year.getFullYear()}&month=${item.month}`
      );
    },
  },
};
</script>

<style lang="less" scoped>
.student-consume-wrp {
  .empty img {
    width: 100px;
    height: 135px;
  }
  .query {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0;
    .left {
      display: flex;
      align-items: center;
      .item {
        display: flex;
        align-items: center;
      }
      .label {
        white-space: nowrap;
        margin-left: 20px;
        line-height: 1;
        font-size: 16px;
        color: #1a1a1a;
      }
    }
    .right {
      display: flex;
      .btn {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 20px;
        width: 62px;
        height: 30px;
        box-sizing: border-box;
        border-radius: 10px;
        cursor: pointer;
        &.search {
          font-size: 16px;
          color: #fff;
          background-color: #ffaf8c;
          transition: all 0.15s;
          &:hover {
            background-color: #ff5f19;
          }
        }
        &.reset {
          border: 1px solid #ccc;
          color: #ccc;
          &.light {
            border-color: #ffaf8c;
            color: #ffaf8c;
            transition: all 0.15s;
            &:hover {
              border-color: #ff5f19;
              color: #ff5f19;
            }
          }
        }
      }
    }
  }
  /deep/ .el-table {
    &::after,
    &::before {
      display: none;
    }
    tr:hover td.el-table__cell {
      background-color: #fff;
    }
    td,
    th {
      padding-left: 20px;
      height: 66px;
      font-size: 16px;
      border-color: rgba(255, 95, 25, 0.1);
      .cell {
        padding: 0;
      }
    }
    th.is-leaf,
    td.is-leaf {
      border-color: rgba(255, 95, 25, 0.1);
    }
    th {
      color: #1a1a1a;
    }
    td {
      color: #4d4d4d;
      .btns {
        display: flex;
        align-items: center;
      }
      .btn {
        margin-right: 20px;
        color: #ffaf8c;
        cursor: pointer;
        transition: all 0.15s;
        &:hover {
          color: #ff5f19;
        }
      }
      .light {
        color: #ff5f19;
      }
      .gray {
        color: #808080;
      }
    }
  }
  /deep/ .el-pagination {
    margin-top: 40px;
    margin-bottom: 40px;
    text-align: center;
  }
}
</style>
