<template>
  <div class="home-wrp">
    <div class="home-bg"></div>
    <div class="swiper-wrp">
      <div class="swiper-bg">
        <el-carousel ref="bgSwiper" height="max-content" :interval="5000">
          <img
            src="@/assets/images/banner-holder-img.png"
            class="place-holder-img"
          />
          <el-carousel-item v-for="item in bannerImgList" :key="item.bannerId">
            <img
              :src="item.imgPath"
              class="swiper-bg-item"
              @click="onToLink(item.jumpPage)"
            />
          </el-carousel-item>
        </el-carousel>
      </div>
      <!-- <div class="swiper-main">
        <el-carousel
          ref="mainSwiper"
          height="520px"
          :interval="5000"
          @change="mainSwiperChange($event)"
        >
          <el-carousel-item v-for="item in bannerImgList" :key="item.bannerId">
            <img
              :src="item.imgPath"
              class="swiper-item"
              
            />
          </el-carousel-item>
        </el-carousel>
      </div> -->
    </div>

    <template v-for="(cat, index) in categoryCourseList">
      <div
        class="course-wrp"
        :class="['course-wrp-type' + (index + 1)]"
        :key="cat.name"
        v-if="cat.list.length"
      >
        <div class="cont">
          <div class="title">{{ cat.name }}</div>
          <div class="list">
            <div
              class="item"
              v-for="item in cat.list"
              :key="item.id"
              @click="onToDetail(item.id)"
            >
              <img :src="item.tags.courseThumb" alt="" />
              <div class="detail">
                <div class="name">{{ item.name }}</div>
                <!-- <div class="desc">{{ item.briefDescribe }}</div> -->
                <!-- <div class="label">
                  课时数：<span>{{ item.lessonCount }}课时</span>
                </div> -->
                <div class="label">
                  课程类别：<span>{{ item.tags.subCategory }}</span>
                </div>
                <div class="label">
                  推荐授课方式：<span>{{ courseTypes[item.mode] }}</span>
                </div>
              </div>
              <div class="add-demand" @click.stop="onAddDemand(item.id)">
                加入需求清单
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>

    <!-- <div class="company">
      <div class="title">合作院校</div>
      <div class="item">
        <img src="@/assets/images/浙江经济职业技术学院.png" alt="" />
        <img src="@/assets/images/杭州职业技术学院.png" alt="" />
        <img src="@/assets/images/浙江树人学院.png" alt="" />
        <img src="@/assets/images/合肥共达.png" alt="" />
      </div>
      <div class="item">
        <img src="@/assets/images/浙江工业大学之江学院.png" alt="" />
        <img src="@/assets/images/中国计量大学.png" alt="" />
        <img src="@/assets/images/浙江经贸职业技术学院.png" alt="" />
      </div>
    </div> -->
  </div>
</template>

<script>
import { courseTypes as _courseTypes } from "../../utils/options";
export default {
  name: "Home",
  data() {
    return {
      bannerImgList: [],
      categoryList: [],
      homeCategory: [],
      categoryCourseList: [],
    };
  },
  computed: {
    courseTypes() {
      return _courseTypes;
    },
  },
  created() {
    this.$request
      .getBannerListApi({ size: 99, offset: 0, belonging: 2, location: 0 })
      .then((res) => {
        this.bannerImgList = res.result.bannerInfos || [];
      });
    new Promise(async () => {
      await this.$request.getCourseCategoriesApi().then((res) => {
        const _cat = res.result.categoies || [];
        const _find = _cat.find((item) => item.name === "b端首页类目");
        this.homeCategory = _find ? _find.sub : [];
      });
      let _apiList = [];
      for (let item of this.homeCategory) {
        _apiList.push(
          this.$request.searchCourseGoodsListApi({
            offset: 0,
            size: 50,
            tags: ["homeCategory"],
            values: [item.name],
          })
        );
      }
      Promise.all(_apiList).then((result) => {
        result.forEach((item, index) => {
          this.categoryCourseList.push({
            name: this.homeCategory[index].name,
            list: item.result.courseInfos
          })
        })
      });
    });
  },
  methods: {
    onToLink(link) {
      window.open(link, "_self");
    },
    onToCourseCenter() {
      this.$router.push("/course-center");
    },
    onToCourseDetail(item) {
      this.$router.push(`/course-center/detail?id=${item}`);
    },
    bgSwiperChange(index) {
      this.$refs.mainSwiper.setActiveItem(index);
    },
    mainSwiperChange(index) {
      this.$refs.bgSwiper.setActiveItem(index);
    },
    onAddDemand(productId) {
      this.$store.dispatch("addShopCart", { productId, count: 1 });
    },
    onToDetail(courseId) {
      let path = location.origin + "/#";
      path += `/course-center/detail?courseId=${courseId}`;
      window.open(path, "_blank");
      // this.$router.push(`/course-center/detail?courseId=${courseId}`);
    },
  },
};
</script>

<style scoped lang="less">
.base-bg {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.home-wrp {
  position: relative;
  .swiper-wrp {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 1280px;
    width: 100%;
    .swiper-bg {
      width: 100%;
      .swiper-bg-item {
        height: 100%;
        width: 100%;
        cursor: pointer;
        z-index: 2;
      }
      .place-holder-img {
        width: 100%;
        opacity: 0;
        z-index: 1;
      }
    }
    // .swiper-main {
    //   margin: auto;
    //   width: 1200px;
    //   height: 520px;
    //   /deep/ .el-carousel__arrow {
    //     display: block;
    //   }
    //   /deep/ .el-carousel__item {
    //     text-align: center;
    //     border-radius: 8px;
    //     overflow: hidden;
    //   }
    //   .swiper-item {
    //     height: 100%;
    //     width: 100%;
    //     cursor: pointer;
    //   }
    // }
  }
  .course-wrp {
    margin: 50px 0;
    padding-bottom: 20px;
    &:extend(.base-bg);
    overflow: hidden;
    font-size: 0;
    &.course-wrp-type1 {
      background-image: url("@/assets/images/home-course-bg1.png");
    }
    &.course-wrp-type2 {
      background-image: url("@/assets/images/home-course-bg2.png");
    }
    &.course-wrp-type3 {
      background-image: url("@/assets/images/home-course-bg3.png");
    }
    &.course-wrp-type4 {
      background-image: url("@/assets/images/home-course-bg4.png");
    }
    .cont {
      width: 1200px;
      margin: 0 auto;
    }
    .title {
      margin-top: 40px;
      padding-left: 20px;
      width: 280px;
      height: 86px;
      line-height: 96px;
      font-weight: bold;
      font-size: 24px;
      font-style: italic;
      color: #1a1a1a;
      background-image: url("@/assets/images/course-title-icon.png");
      &:extend(.base-bg);
      box-sizing: border-box;
    }
    .list {
      display: flex;
      flex-wrap: wrap;
      font-size: 0;
      overflow: hidden;
      .item {
        position: relative;
        margin-right: 40px;
        margin-bottom: 20px;
        width: 270px;
        border-radius: 10px;
        overflow: hidden;
        cursor: pointer;
        img {
          width: 100%;
          height: 188px;
        }
        .detail {
          padding: 0 20px;
          height: 112px;
          background-color: #fff;
          overflow: hidden;
          .name {
            margin-top: 10px;
            margin-bottom: 6px;
            font-size: 18px;
            color: #1a1a1a;
            height: 40px;
            line-height: 1.1;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden; //溢出内容隐藏
            text-overflow: ellipsis; //文本溢出部分用省略号表示
            display: -webkit-box; //特别显示模式
            -webkit-line-clamp: 2; //行数
            line-clamp: 2;
            -webkit-box-orient: vertical; //盒子中内容竖直排列
          }
          // .desc {
          //   margin-top: 5px;
          //   font-size: 12px;
          //   color: #999;
          //   overflow: hidden;
          //   text-overflow: ellipsis;
          //   white-space: nowrap;
          // }
          .label {
            margin-top: 5px;
            font-size: 12px;
            color: #666;
            span {
              color: #f97320;
            }
          }
        }
        .add-demand {
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 44px;
          line-height: 44px;
          text-align: center;
          font-size: 24px;
          color: #fff;
          background-color: #ff6505;
          border-radius: 10px;
          opacity: 0;
          transition: all 0.1s;
        }
        &:nth-child(4n) {
          margin-right: 0;
        }
        &:hover .add-demand {
          opacity: 1;
        }
      }
    }
  }
  .company {
    margin: 60px auto 40px;
    width: 1200px;
    overflow: hidden;
    .title {
      margin-bottom: 10px;
      width: 127px;
      height: 86px;
      line-height: 96px;
      text-align: center;
      font-weight: bold;
      font-size: 24px;
      color: #1a1a1a;
      &:extend(.base-bg);
      background-image: url("@/assets/images/company-icon.png");
    }
    .item {
      margin-bottom: 40px;
      img {
        margin-right: 24px;
        &:last-child {
          margin-right: 0;
        }
      }
      &:last-child {
        img {
          margin-right: 34px;
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
}
</style>
