var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"course-intro-wrp"},[_c('div',{staticClass:"introduce"},[_c('div',{staticClass:"menu"},_vm._l((_vm.introMenuList),function(item){return _c('div',{key:item.value,staticClass:"item",class:{ active: _vm.selectedIntroMenu === item.value, hide: item.hide },on:{"click":function($event){return _vm.onChooseMenu(item.value)}}},[_c('div',{staticClass:"text"},[_vm._v(_vm._s(item.text))]),_c('div',{staticClass:"line"})])}),0),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedIntroMenu === 'intro'),expression:"selectedIntroMenu === 'intro'"}],staticClass:"intro-info"},[_c('div',{staticClass:"intro"},[_vm._m(0),_c('div',{staticClass:"value"},[_vm._v(" "+_vm._s(_vm.descData.intro)+" ")])]),_vm._m(1),_c('div',{ref:"descRef",staticClass:"desc-ref"})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedIntroMenu === 'catalogue'),expression:"selectedIntroMenu === 'catalogue'"}],staticClass:"catalogue"},[_c('el-collapse',{attrs:{"accordion":""},model:{value:(_vm.activeName),callback:function ($$v) {_vm.activeName=$$v},expression:"activeName"}},_vm._l((_vm.chapterInfos),function(item){return _c('el-collapse-item',{key:item.id,attrs:{"name":item.id}},[_c('template',{slot:"title"},[_c('div',{staticClass:"chapter-head"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(item.title))]),_c('img',{staticClass:"chapter-head-icon",class:{ active: _vm.activeName === item.id },attrs:{"src":require("@/assets/images/arrow-bottom.png"),"alt":""}})])]),_vm._l((item.lessonDataPacks),function(v){return _c('div',{key:v.id,staticClass:"inside-item"},[(v.lessonMode === 1)?_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/images/replay-course-icon.png"),"alt":""},on:{"click":function($event){return _vm.onPlay(v.id)}}}):_vm._e(),(
                v.lessonMode === 2 &&
                !isNaN(v.liveStatus) &&
                v.liveStatus !== 99
              )?_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/images/live-course-icon.png"),"alt":""},on:{"click":function($event){return _vm.onPlay(v.id)}}}):_vm._e(),_c('div',{staticClass:"info"},[_c('div',{staticClass:"p-name"},[_vm._v(" "+_vm._s(v.title)+" ")]),_c('div',{staticClass:"time"},[_vm._v(" "+_vm._s(_vm.courseTypes[v.lessonMode])),(v.lessonMode === 2)?_c('span',[_vm._v(_vm._s(v.liveStart ? " | " + v.liveStart : ""))]):_vm._e()])])])})],2)}),1)],1)]),_c('div',{staticClass:"other-info"},[_vm._m(2),_c('div',{staticClass:"teachers"},_vm._l((_vm.teacherList),function(item){return _c('div',{key:item.id,staticClass:"item"},[_c('img',{staticClass:"teacher-img",attrs:{"src":item.thumb,"alt":""}}),_c('div',{staticClass:"detail"},[_c('div',{staticClass:"name-wrp"},[_c('span',{staticClass:"name"},[_vm._v(_vm._s(item.name))])]),_c('div',{staticClass:"teach-lv"},_vm._l((5),function(v){return _c('img',{key:v,staticClass:"teach-lv-img",attrs:{"src":require("@/assets/images/teach-lv-img.png"),"alt":""}})}),0),_c('div',{staticClass:"teach-intro"},[_vm._v(" "+_vm._s(item.describe)+" ")])])])}),0)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/images/desc-icon.png"),"alt":""}}),_vm._v(" 课程描述 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/images/intro-icon.png"),"alt":""}}),_vm._v(" 课程介绍 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"other-info-title"},[_c('span',{staticClass:"line"}),_c('span',{staticClass:"text"},[_vm._v("课程老师")])])
}]

export { render, staticRenderFns }