import { isLogin as _isLogin } from "../utils/auth";
import { setUserInfo, getUserInfo } from "../utils/auth";
export default {
  state: {
    isLogin: false,
    userName: getUserInfo()
      ? getUserInfo().organInfo
        ? getUserInfo().organInfo.organName || ""
        : ""
      : "",
  },
  mutations: {
    checkIsLogin(state) {
      state.isLogin = _isLogin();
    },
    changeUserInfo(state, data) {
      setUserInfo(data);
      state.userName = data.organInfo ? data.organInfo.organName || "" : "";
    },
  },
};
