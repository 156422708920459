const coursePatch = (res) => {
  const _result = {
    isSuccess: res.isSuccess,
    result: {
      courseInfos: res.result.productInfos || [],
    },
  };
  _result.result.courseInfos.forEach((item) => {
    item.mode = item.tags?.mode || undefined;
  });
  return _result;
};

export { coursePatch };
