<template>
  <div class="privacyPolicyWrp">
    <div class="title">隐私政策</div>
    <div class="text">
      欢迎使用“竞游在线”提供的产品和服务！“竞游在线”<span class="bold"
        >中竞（浙江）教育科技有限公司</span
      >（或简称“我们”）创建和运营。我们深知个人信息对您的重要性，我们一向庄严承诺保护使用我们的产品和服务（以下统称“竞游在线服务”）之用户（以下统称“用户”或“您”）的个人信息及隐私安全。您在使用竞游在线服务时，我们可能会收集和使用您的相关个人信息（或简称“个人信息”）。
    </div>
    <div class="text fs_18">
      <span class="under-line"
        >《竞游在线隐私政策》（以下简称“本《隐私政策》”）适用于我们提供的所有竞游在线服务。当您使用我们竞游在线服务时，您同意接受本《隐私政策》，未满十四周岁的用户，</span
      ><span class="bold">请在法定监护人的陪同下阅读本</span
      ><span class="under-line">《隐私政策》。</span>
      <span class="bold">经监护人同意后，您方可使用竞游在线服务。</span>
    </div>
    <div class="text">
      我们希望通过本《隐私政策》向您说明我们在收集和使用您相关个人信息时对应的处理规则，以及我们为您提供的访问、更正、删除和保护这些个人信息的方式，以便更好的保障您的权益。
    </div>
    <div class="text under-line">
      【特别提示】请您在使用竞游在线服务前，仔细阅读（儿童请在监护人陪同下阅读）并了解本《隐私政策》，以做出适当选择。一旦您使用或在我们更新本《隐私政策》后继续使用我们的产品或服务，即意味着您同意本《隐私政策》并同意我们按照本《隐私政策》收集、使用、储存、共享、转让和披露您的相关个人信息。
    </div>
    <div class="text">
      本《隐私政策》将帮助您了解以下内容：
      <div>一、我们如何收集和使用您的个人信息</div>
      <div>二、我们如何使用COOKIES或同类技术</div>
      <div>三、我们可能共享、转让和披露的个人信息</div>
      <div>四、我们如何保留、储存和保护您的个人信息安全</div>
      <div>五、如何管理您的个人信息</div>
      <div>六、第三方服务</div>
      <div>七、通知和修订</div>
      <div>八、<span class="red">法律适用与管辖</span></div>
      <div>九、如何联系我们</div>
    </div>
    <div class="section-title">一、我们如何收集和使用您的个人信息</div>
    <div class="text">
      我们收集您的个人信息主要是为了您和其他用户能够更容易和更满意地使用竞游在线服务。而这些个人信息有助于我们实现这一目标。
    </div>
    <div class="node-title">
      （一）我们将通过以下途径收集和获得您的个人信息：
    </div>
    <div class="text">
      <div class="p-title">1、您提供的个人信息。</div>
      <div>
        （1）您在注册竞游在线服务的帐号或使用竞游在线服务时，向我们提供的个人信息并设置登录密码。<span
          class="bold"
          >如果您仅需使用浏览、了解竞游在线，您不需要注册账户及提供上述信息。</span
        >如果您不提供这些信息，并不会影响您使用竞游在线的基本功能；
      </div>
      <div>
        （2）您通过竞游在线服务向其他方提供的共享个人信息，以及您使用竞游在线服务时所储存的个人信息。
      </div>
      <div class="under-line">
        请注意，如您在竞游在线服务中其他用户可见的公开区域内上传或发布的个人信息中、您对其他人上传或发布的信息作出的回应中公开您的个人信息，该个人信息可能会被他人收集并加以使用。当您发现他人不正当地收集或使用您的个人信息时，您可以通过本《隐私政策》第九条【如何联系我们】约定的联系方式联络我们。
      </div>
      <div class="p-title">
        2、其他方共享的您的个人信息。亦即其他方使用竞游在线服务时所提供有关您的共享个人信息。
      </div>
      <div class="p-title">
        3、我们获取的您的个人信息。您在使用竞游在线服务时，我们收集、汇总、记录的个人信息。
      </div>
    </div>
    <div class="node-title">
      （二）我们会出于以下目的，收集和使用您以下类型的个人信息：
    </div>
    <div class="text">
      <div class="p-title">1、帮助您完成注册、登录及注销</div>
      <div>
        为便于我们为您提供竞游在线完整的服务，您需要<span
          class="bold under-line"
          >登录（敏感个人信息）或提供第三方账号登录竞游在线，</span
        >包括手机号码、电子邮箱地址，并创建您的帐号、用户名和密码。如果您仅需使用浏览、查询、搜索的基本服务，您不需要注册成为竞游在线用户及提供上述个人信息。
      </div>
      <div>
        在竞游在线服务注册、登录或后续使用过程中，如果您提供其他额外个人信息（包括您的昵称、头像、性别、您的学习兴趣），将有助于我们给您提供个性化的推荐和更优的服务体验，但如果您不提供这些个人信息，并不会影响您使用竞游在线服务的基本功能。
      </div>
      <div>
        如果您不再使用竞游在线服务，在符合服务协议约定条件及国家相关法律法规规定的情况下，我们也提供注册帐号的注销，通过本《隐私政策》第九条【如何联系我们】约定的联系方式提出注销申请。当您的帐号注销或被删除后，与该帐号相关的竞游在线服务项下的全部服务资料和数据将被删除或匿名化处理，但法律法规另有规定的除外。
      </div>
      <div class="p-title">2、实现身份认证</div>
      <div>
        依照相关法律法规规定及监管要求，或为满足用户身份真实核验、保障系统和服务安全及其他特定服务功能的需要，我们可能需要您提供真实身份信息（包括姓名、身份证、联系方式）和其他帐号信息，以便核验您的身份，协助您解决问题或记录相关问题的处理方案及结果。如果您不提供上述信息，我们可能无法向您提供相关的功能及服务。
      </div>
      <div>
        为实现身份认证的目的，您同意并授权我们可以自行或委托第三方向有关身份认证机构（包括个人征信机构、政府部门）提供、查询、核对您的前述身份信息。
      </div>
      <div class="p-title">3、维护基础功能的正常运行</div>
      <div>
        在您使用我们服务过程中，为识别帐号异常状态、了解产品适配性，向您提供浏览、搜索基本服务，维护基础功能的正常运行，我们可能会自动收集、储存关于您使用的服务以及使用方式的信息并将这些信息进行关联，这些信息包括：
      </div>
      <div class="fs_18">
        <span class="under-line">（1）日志信息：</span
        >当您使用我们的服务时，我们可能会自动收集您对我们服务的详细使用情况，作为有关网络日志保存。日志信息包括<span
          class="under-line"
          >您的登录帐号、搜索查询内容、IP地址、浏览器的类型、电信运营商、网络环境、使用的语言、访问日期和时间及您访问的网页浏览记录、Push打开记录、崩溃记录、停留时长、刷新记录、发布记录、关注、订阅、收藏及分享。</span
        >
      </div>
      <div class="fs_18">
        <span class="under-line">（2）设备信息：</span
        >我们可能会根据您在软件安装及使用中授予的具体权限，接收并记录您所使用的设备相关信息（包括设备标识符（IMEI）、设备序列号（Serial
        Number）、OPEN
        UDID、SIM卡IMSI识别码、设备机型、操作系统及版本、客户端版本、设备分辨率、包名、设备设置、MAC、Android
        ID、IDFA、UUID、AAID、IDFV、OAID、MEID、UAID）、已安装APP信息/运行中进程信息、设备所在位置相关信息（包括IP
        地址、GPS位置以及能够提供相关个人信息的WLAN接入点、蓝牙和基站传感器信息）。
      </div>
      <div class="fs_18">
        同时为了收集上述基本的个人设备信息，我们将会申请访问您的设备信息的权限，我们收集这些信息是为了向您提供我们的基本服务和基础功能，如您拒绝提供上述权限将可能导致您无法使用我们的产品与服务。
      </div>
      <div class="fs_18">
        请注意，单独的设备信息、日志信息是无法识别特定自然人身份的信息。如果我们将这类非个人信息与其他信息结合用于识别特定自然人身份，或者将其与个人信息结合使用，则在结合使用期间，这类非个人信息将被视为个人信息，除取得您授权或法律法规另有规定外，我们会将该类个人信息做匿名化、去标识化处理。这些信息在结合使用期间，将作为您的个人信息按照本隐私政策处理与保护。
      </div>
      <div class="p-title">4、向您提供商品或服务</div>
      <div>
        （1）个人信息的共享与发布：您在使用竞游在线服务时、上传和/或发布个人信息以及进行相关行为（例如您在竞游在线服务中发布提问、课程直播中发言、分享、商品/服务评价）时，我们将收集您上传、发布或形成的个人信息，并有权展示您的昵称、头像和发布内容。
      </div>
      <div>
        （2）商品或服务交付：当您在我们的商品或服务中订购具体商品或服务时，我们会通过系统为您生成购买该商品或服务的订单。在部分服务项目中，为便于向您交付商品或服务，您可能需提供收货人<span
          class="under-line"
          >个人身份信息、姓名、收货地址、邮政编码、收货人、联系电话、支付状态信息。</span
        >如果您拒绝提供此类个人信息，我们将无法完成相关交付服务。如您通过竞游在线服务为其他人订购商品或服务，您需要提供该实际订购人的前述个人信息。<span
          class="under-line"
          >向我们提供该实际订购人的前述个人信息之前，您需确保您已经取得其授权同意。在您使用竞游在线服务课程服务时，我们会记录您的个人学习信息：您的当前学习阶段、课程学习进度、测验结果、学习时长。我们将应用上述个人信息分析您的学习状态，为您或我们的服务优化提供参考。</span
        >
      </div>
      <div>
        （3）为完成订单支付、交付商品或服务、确认交易状态及为您提供售后与争议解决服务，我们会通过您基于交易所选择的交易对象、支付机构、物流公司收集与交易进度相关的您的<span
          class="under-line"
          >帐号、订单、交易、支付、物流信息，</span
        >或将您的交易信息共享给上述服务提供者。
      </div>
      <div>
        （4）订单管理：为展示您的帐户的订单信息及保障您的售后权益，竞游在线会收集您在使用竞游在线过程中产生的<span
          class="under-line"
          >订单信息、交易和消费记录、虚拟财产信息</span
        >用于向您展示及便于您对订单进行管理。
      </div>
      <div>
        （5）客服与售后服务：<span class="under-line"
          >当您联系我们的客服或使用其他用户响应功能时（包括提出售中售后申请、个人信息保护投诉或建议、其他客户投诉和需求），我们可能需要您提供必要的个人信息以匹配并核验您的用户身份，以便保障您的帐号与系统安全。我们可能还会保存您的联系方式（您与我们联系时使用的或您向我们主动提供的其他联系方式）、您与我们的通信/通话记录和内容、与您需求相关联的其他必要信息，以便与您联系或帮助您解决问题，或记录相关问题的处理方案及结果。</span
        >
      </div>
      <div class="p-title">5、我们通过间接方式收集到的您的个人信息：</div>
      <div>
        我们可能从管理方、第三方合作伙伴获取您授权共享的相关个人信息。我们可能从第三方获取您授权共享的帐户个人信息（头像、昵称、登录时间）并在您同意本《隐私政策》后将您的第三方帐户与您的竞游在线服务帐户绑定，使您可以通过第三方帐户直接登录并使用我们的产品与/或服务。我们将在符合相关法律法规规定，并依据与关联方或第三方合作伙伴的约定、确信其提供的个人信息来源合法的前提下，收集并使用您的这些个人信息。
      </div>
      <div class="p-title">6、向您提供商品或服务的信息展示和推送</div>
      <div>（1）为您展示和推送商品或服务</div>
      <div>
        基于您向我们提供的信息、我们可能收集的信息及我们通过间接方式收集到的您的信息（包括您的<span
          class="under-line"
          >浏览及搜索记录、设备信息、位置信息、订单信息、手机号码、您参与竞游在线线上或线下活动填写问卷调查上的个人信息</span
        >），我们可能会基于上述一项或几项信息的结合，进行推荐算法建模、程序化广告推荐算法建模、用户行为分析及用户画像，用于提取您的<span
          class="under-line"
          >浏览、搜索偏好、行为习惯、位置信息</span
        >相关特征，以便向您提供更契合您需求的页面展示和推荐个性化内容。
      </div>
      <div class="under-line">
        （2）向您发出通知。我们可能在必需时（例如当我们由于系统维护而暂停某一单项服务、变更、终止提供某一单项服务时）向您发出与服务有关的通知，<span
          class="bold"
          >用于用户消息告知、身份验证、安全验证、用户使用体验调研的用途。此外，我们也可能通过短信、电话的方式，向您发送您可能感兴趣的服务、功能或活动。</span
        >
        如您不希望继续接收我们推送的消息，您可要求我们停止推送，例如：根据短信退订指引要求我们停止发送推广短信，或在系统中进行设置，不再接收我们推送的消息；但我们依法律规定或竞游在线的服务协议约定发送消息的情形除外。
      </div>
      <div class="p-title">7、为您提供安全保障</div>
      <div class="under-line">
        为提高您使用我们及合作伙伴提供服务的安全性，保护您或其他用户或公众的人身财产安全免遭侵害，更好地预防钓鱼网站、欺诈、网络漏洞、计算机病毒、网络攻击、网络侵入的安全风险，更准确地识别违反法律法规或竞游在线服务相关协议规则的情况，我们可能会收集、使用或整合您的帐户信息、交易信息、设备信息、日志信息以及我们关联公司、合作伙伴取得您授权或依据法律共享的个人信息，来综合判断您帐户及交易风险、进行身份验证、检测及防范安全事件，并依法采取必要的记录、审计、分析、处置措施。
      </div>
      <div class="p-title">8、用户体验改进计划</div>
      <div>
        <span class="bold"
          >我们希望提供给您的产品和服务是完善的，所以我们会不断改进我们的产品和服务，我们可能将通过竞游在线服务所收集的个人信息，来诊断系统问题，优化产品体验：</span
        >在您使用竞游在线服务时所收集的您的个人信息，可能在我们的其他服务中用于向您提供特定内容或向您展示与您相关的、而非普遍推送的信息；我们可能让您参与有关竞游在线服务的调查，帮助我们改善现有服务或设计新服务；同时，我们可能将您的个人信息用于软件更新。<span
          class="bold"
          >如果我们要将您的个人信息用于本政策中未载明的其他用途或额外收集未提及的其他个人信息，我们会依法通过页面提示、交互流程、网站公告或其他方式另行征得您的同意。在此过程中，您可通过本政策第九条【如何联系我们】约定的联系方式联络我们。</span
        >
      </div>
      <div class="p-title">9、其他用途</div>
      <div class="bold under-line">
        您了解并同意，在收集您的个人信息后，我们可能通过技术手段对数据进行去标识化处理，去标识化处理的信息将无法识别您的身份，在此情况下我们有权使用已经去标识化的信息，对用户数据库进行分析并予以商业化的利用，而无需另行获得您的同意。
      </div>
      <div class="bold under-line">
        请您注意，如果我们要将您的个人信息用于本政策中未载明的其他用途或额外收集未提及的其他个人信息，我们会另行事先请您同意（确认同意的方式：勾选、弹窗、站内信、邮件、短信方式）。一旦您同意，该额外用途将视为本政策的一部分，该额外个人信息也将适用本政策。
      </div>
      <div class="p-title">10、征得授权同意的例外</div>
      <div>
        根据相关法律法规规定，以下情形中收集您的个人信息无需征得您的授权同意：
      </div>
      <div>（1） 与个人信息控制者履行法律法规规定的义务相关的；</div>
      <div>（2） 与国家安全、国防安全直接相关的；</div>
      <div>（3） 与公共安全、公共卫生、重大公共利益直接相关的；</div>
      <div>（4） 与刑事侦查、起诉、审判和判决执行直接相关的；</div>
      <div>
        （5）
        出于维护个人信息主体或其他个人的生命、财产相关的重大合法权益但又很难得到本人授权同意的；
      </div>
      <div>（6） 所涉及的个人信息是个人信息主体自行向社会公众公开的；</div>
      <div>（7） 根据个人信息主体要求签订和履行合同所必需的；</div>
      <div>
        （8）
        从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开渠道；
      </div>
      <div>
        （9）
        维护所提供产品或服务的安全稳定运行所必需的，包括发现、处置产品或服务的故障；
      </div>
      <div class="p-title">
        11、您理解并同意，竞游在线服务可能需要您在您的设备中开启特定的访问权限，以实现这些权限所涉及个人信息的收集和使用。
      </div>
      <div>
        （1）我们访问您的位置信息，是为了根据您的位置信息为您提供更契合您需求的页面展示和产品服务，在投屏时获取您的无线网名称；
      </div>
      <div>
        （2）我们访问您的摄像头，是为了使您可以使用摄像头进行扫码、拍摄，用于实现登录、图片评论、上传课程作业/资料、直播互动功能；
      </div>
      <div>
        （3）我们访问您的相册，是为了使您可以实现您设备中的照片、图片或视频的取用与上传，便于您进行更换头像、发表评论/分享；
      </div>
      <div>
        （4）我们访问您的麦克风，是为了使您可以实现口语练习、语音回复、语音评论、直播互动功能，在这些功能中我们会收集您的录音内容以识别您的具体需求；
      </div>
      <div>
        （5）您开启通知权限后，是为了使您及时接收到我们的课程更新提醒、直播提醒以及课程讲师与您的即时沟通消息；
      </div>
      <div>
        （6）我们访问设备的运行中进程信息，是为了保证多进程应用正常运行；
      </div>
      <div>
        （7）您使用竞游在线服务中的课程服务，可能会生成视频缓存文件，我们会申请访问该视频缓存文件，是为了使您更为流畅的体验我们的课程服务或为您提供课程离线观看功能及共享登录功能；
      </div>
      <div>
        （8）在您开启日历权限后，我们将收集您的日历信息用于向您提供课程提醒；
      </div>
      <div>
        （9）如我们访问您的设备信息，是为了保障在投屏、登录、页面展示时您的信息的安全性，为您提供更流畅的页面展示和产品服务；
      </div>
      <div>
        （10）如我们访问您的悬浮窗权限，是为了使您可以实现屏幕取词、悬浮播放功能；
      </div>
      <div>
        （11）我们访问您的广告追踪权限，是为了统计站内广告的浏览和点击数据，以便为您提供更合适的展示页面。
      </div>
      <div>
        当您需要关闭该功能时，大多数移动设备都会支持您的这项需求，具体方法请参考或联系您移动设备的服务商或生产商。请您注意，您开启任一权限即代表您授权我们可以收集和使用相关个人信息来为您提供对应服务，您一旦关闭任一权限即代表您取消了授权，我们将不再基于对应权限继续收集和使用相关个人信息，也无法为您提供该权限所对应的服务。但是，您关闭权限的决定不会影响此前基于您的授权所进行的个人信息收集及使用。
      </div>
      <div class="p-title">12、有关敏感个人信息的提示</div>
      <div class="under-line">
        个人敏感信息，是指一旦泄露、非法提供或滥用可能危害人身和财产安全，极易导致个人名誉、身心健康受到损害或歧视性待遇的个人信息。以上由您提供或我们收集您的个人信息中，可能包含您的个人敏感信息，包括银行账号、交易和消费记录、虚拟财产信息、系统帐号、邮箱地址及其有关的密码、电话号码、网页浏览记录、位置信息。请您谨慎并留意个人敏感信息，您同意我们可以按本《隐私政策》所述的目的和方式来处理您的个人敏感信息。
      </div>
    </div>
    <div class="section-title">二、我们如何使用COOKIES或同类技术</div>
    <div class="node-title">（一）COOKIES</div>
    <div class="text">
      <div>
        我们或我们的第三方合作伙伴可能通过COOKIES获取和使用您的个人信息，并将该个人信息储存为日志信息。
      </div>
      <div>
        通过使用COOKIES，我们向您提供简单易行并富个性化的网络体验。一个COOKIES是少量的数据，它们从一个网络服务器送至您的浏览器并存在计算机硬盘上。我们使用COOKIES是为了让您可以受益。比如，为使得竞游在线的登录过程更快捷，您可以选择把用户名存在一个COOKIES中。这样下次当您要登录竞游在线的服务时能更加方便快捷。COOKIES能帮助我们确定您连接的页面和内容，您在竞游在线特定服务上花费的时间和您所选择的竞游在线服务。
      </div>
      <div>
        COOKIES使得我们能更好、更快地为您服务，并且使您在竞游在线服务上的经历更富个性化。然而，您应该能够控制COOKIES是否以及怎样被你的浏览器接受。请查阅您的浏览器附带的文件以获得更多这方面的个人信息。
      </div>
      <div>
        （1）记住您的身份。COOKIES有助于我们辨认您作为我们的注册用户的身份，或保存您向我们提供有关您的喜好或其他个人信息；
      </div>
      <div>我们使用自己的COOKIES，可能用于以下用途：</div>
      <div>
        （1）记住您的身份。COOKIES有助于我们辨认您作为我们的注册用户的身份，或保存您向我们提供有关您的喜好或其他个人信息；
      </div>
      <div>
        （2）分析您使用我们服务的情况。我们可利用COOKIES来了解您使用竞游在线服务进行什么活动、或哪些服务最受欢迎；
      </div>
      <div>
        （3）广告优化。COOKIES有助于我们根据您的个人信息，向您提供与您相关的广告而非进行普遍的广告投放。
      </div>
      <div>
        我们为上述目的使用COOKIES的同时，可能将通过COOKIES收集的非个人身份信息汇总提供给广告商和其他伙伴，用于分析您和其他用户如何使用竞游在线服务并用于广告服务。
      </div>
      <div>
        竞游在线服务上可能会有广告商和其他合作方放置的COOKIES或同类技术。这些COOKIES可能会收集与您相关的非个人身份信息，以用于分析用户如何使用该服务、向您发送您可能感兴趣的广告，或用于评估广告服务的效果。这些第三方COOKIES或同类技术收集和使用该个人信息不受本《隐私政策》约束，而是受到其自身的个人信息保护声明约束，我们不对第三方的COOKIES或同类技术承担责任。您可以通过浏览器或用户选择机制拒绝或管理COOKIES。但请您注意，如果您停用COOKIES或同类技术，我们有可能无法为您提供最佳、契合其需求或与其年龄相符的服务体验，某些服务也可能无法正常使用。同时，您仍然将收到广告，只是这些广告与您的相关性会降低。
      </div>
    </div>
    <div class="node-title">（二）网站信标和像素标签</div>
    <div>
      除 COOKIES或同类技术外，我们还会在网站上使用网站信标和像素标签其他同类技术。我们向您发送的电子邮件可能含有链接至我们网站内容的地址链接，如果您点击该链接，我们则会跟踪此次点击，帮助我们了解您的产品或服务偏好以便于我们主动改善客户服务体验。网站信标通常是一种嵌入到网站或电子邮件中的透明图像。借助于电子邮件中的像素标签，我们能够获知电子邮件是否被打开。如果您不希望自己的活动以这种方式被追踪，则可以随时从我们的寄信名单中退订。
    </div>
    <div class="section-title">三、我们可能共享、转让或披露的个人信息</div>
    <div class="node-title">（一）共享</div>
    <div class="text">
      <div>
        您同意我们依据本政策向我们的关联方或相关法人团体、与我们合作的一些商业合作伙伴（合称“信息接收方”）共享您的个人信息。我们将积极帮助您了解信息接收方对个人信息的存储、使用情况，以及您享有的访问、更正、删除、注销帐户的权利。除以下情形外，未经您同意，我们不会与信息接收方之外的任何第三方共享您的个人信息：
      </div>
      <div class="p-title under-line bold">
        1、为实现我们的服务/功能所必须进行的共享。我们可能向信息接收方共享您的个人信息，以实现您需要的核心功能或提供您需要的服务，包括（1）向物流服务商提供对应的订单信息；（2）向提供支付服务的第三方支付机构共享对应的交易订单信息和支付金额信息；（3）其他与我们的服务/功能相关的供应商、服务提供商和业务合作伙伴所必须进行的共享。
      </div>
      <div class="p-title under-line">
        <span class="bold under-line">2、与我们的部分合作伙伴的必要共享。</span
        >为了必要/合理的业务的顺利开展、满足您的要求、履行我们在相关用户协议或本政策中的义务和行使我们的权利或遵守法律规定的目的，我们可能需要向部分合作伙伴共享您的信息，包括：（1）向提供数据服务（包括数据统计、数据分析）的合作伙伴共享<span
          class="bold"
          >日志信息、设备信息</span
        >；（2）向提供产品分析服务（包括应用崩溃分析、网络性能分析、域名解析优化）的合作伙伴共享<span
          class="bold"
          >您的产品使用情况（包括崩溃、闪退）的统计性数据</span
        >；（3）其他与我们的部分合作伙伴的必要共享。
      </div>
      <div class="p-title">
        3、与我们的关联方的必要共享。为便于我们基于统一的帐号体系向您提供一致化服务以及便于您进行统一管理、个性化推荐、保障系统和帐号安全，您的个人信息可能会在我们和我们的关联方之间进行必要共享，包括：（1）为了帮助您节省时间、简化操作流程，您在注册我们的<span
          class="bold"
          >竞游在线</span
        >手机帐号/邮箱帐号后无需重复注册即可登录部分服务，您的帐号相关信息会在我们和我们的关联方的产品内部进行必要共享；（2）为了使您在我们的及我们关联方产品与/或服务有一致性和个性化的浏览、广告的服务体验，您知悉并同意，您的帐号信息、设备信息、相关浏览数据这些能反映您使用习惯和爱好的信息可能会与我们的关联方共享。
      </div>
      <div class="p-title under-line">
        4、实现本《隐私政策》第一条“我们如何收集和使用您的个人信息”部分所述目的；
      </div>
      <div class="p-title">
        5、履行我们在本《隐私政策》或我们与您达成的其他协议中的义务和行使我们的权利；
      </div>
      <div class="p-title under-line">
        6、向委托我们进行推广的合作伙伴的第三方共享，目的是为了使该委托方了解推广的覆盖面和有效性。比如我们可以告知该委托方有多少人看了他们的推广信息或在看到这些信息后购买了委托方的商品，或者向他们提供不能识别个人身份的统计信息，帮助他们了解其受众或顾客。
      </div>
      <div class="p-title">
        7、在法律法规允许的范围内，为了遵守法律、维护我们及我们的关联方或合作伙伴、您或其他竞游在线用户或社会公众利益、财产或安全免遭损害，比如为防止欺诈违法活动和减少信用风险，我们可能与其他公司和组织交换个人信息。不过,这并不包括违反本《隐私政策》中所作的承诺而为获利目的出售、出租、共享或以其它方式披露的个人信息。
      </div>
      <div class="p-title">8、应您合法需求，协助处理您与他人的纠纷或争议；</div>
      <div class="p-title">9、应您的监护人合法要求而提供您的个人信息；</div>
      <div class="p-title">
        10、根据与您签署的单项服务协议（包括在线签署的电子协议以及相应的平台规则）或其他的法律文件约定所提供；
      </div>
      <div class="p-title">11、基于学术研究而提供；</div>
      <div class="p-title">12、基于符合法律法规的社会公共利益而提供。</div>
      <div>
        我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息。对我们与之共享个人信息的公司、组织和个人，我们会与其签署严格的保密协定，要求他们按照我们的说明、本《隐私政策》以及其他任何相关的保密和安全措施来处理个人信息。
      </div>
    </div>
    <div class="node-title">（二）转让</div>
    <div class="p-title">
      <span class="under-line"
        >1、随着我们业务的持续发展，我们有可能进行合并、收购、资产转让或类似的交易，而您的个人信息有可能作为此类交易的一部分而被转移。</span
      >我们会要求新的持有您个人信息的公司、组织继续受本《隐私政策》的约束，否则,我们将要求该公司、组织重新向您征求授权同意。
    </div>
    <div class="p-title">
      2、在获得您的明确同意或您明确表示继续使用新的公司提供的服务后，我们会向其他方转让您的个人信息。<span
        class="red"
        >[1]</span
      >
    </div>
    <div class="node-title">（三）披露</div>
    <div class="text">
      <div>
        我们仅会在以下情况下，且采取符合业界标准的安全防护措施的前提下，才会披露您的个人信息：
      </div>
      <div class="p-title">
        1、根据您的需求，在您明确同意的披露方式下披露您所指定的个人信息；
      </div>
      <div class="p-title under-line">
        2、根据法律、法规的要求、强制性的行政执法或司法要求所必须提供您个人信息的情况下，我们可能会依据所要求的个人信息类型和披露方式披露您的个人信息。在符合法律法规的前提下，当我们收到上述披露个人信息的请求时，我们会要求接收方必须出具与之相应的法律文件，如传票或调查函。我们坚信，对于要求我们提供的个人信息，应该在法律允许的范围内尽可能保持透明。我们对所有的请求都进行了慎重的审查，以确保其具备合法依据，且仅限于执法部门因特定调查目的且有合法权利获取的数据。
      </div>
    </div>
    <div class="node-title">
      （四）共享、转让、披露个人信息时事先征得授权同意的例外
    </div>
    <div class="text">
      <div>
        以下情形中，共享、转让、披露您的个人信息无需事先征得您的授权同意：
      </div>
      <div class="p-title">
        1、与个人信息控制者履行法律法规规定的义务相关的；
      </div>
      <div class="p-title">2、与国家安全、国防安全有关的；</div>
      <div class="p-title">3、与公共安全、公共卫生、重大公共利益有关的；</div>
      <div class="p-title">
        4、与犯罪侦查、起诉、审判和判决执行相关的司法或行政执法有关的；
      </div>
      <div class="p-title">
        5、出于维护您或其他个人的生命、财产的重大合法权益但又很难得到本人同意的；
      </div>
      <div class="p-title">6、您自行向社会公众公开的个人信息；</div>
      <div class="p-title">
        7、从合法公开披露的信息中收集个人信息的，包括合法的新闻报道、政府信息公开的渠道。
      </div>
      <div class="bold">
        根据法律规定，共享、转让经去标识化处理的个人信息，且确保数据接收方无法复原并重新识别个人信息主体的，不属于个人信息的对外共享、转让及公开披露行为，对此类数据的保存及处理将无需另行向您通知并征得您的同意。
      </div>
    </div>
    <div class="section-title">四、我们如何保储存和保护您的个人信息安全</div>
    <div class="node-title">
      （一）<span class="bold under-line">除法律法规或监管部门另有规定外</span
      >，我们仅在本《隐私政策》所述目的所必需期间最短时限内存储您的个人信息。例如《中华人民共和国电子商务法》要求商品和服务信息、交易信息保存时间自交易完成之日起不少于三年。就竞游在线所涉及的用户行为日志，我们将进行匿名化处理。我们判断前述期限的标准包括：
    </div>
    <div class="p-title">
      1、完成与您相关的交易目的、维护相应交易及业务记录、应对您可能的查询或投诉；
    </div>
    <div class="p-title">2、保证平台为您提供服务的安全和质量；</div>
    <div class="p-title">3、您是否同意更长的留存期间；</div>
    <div class="p-title">4、是否存在保留期限的其他特别约定；</div>
    <div class="p-title">5、法律法规或监管部门规定的期限。</div>
    <div class="text">
      如我们终止服务或运营，我们将及时停止继续收集您个人信息的活动，同时会遵守相关法律法规要求提前向您通知，并在本政策所述目的所必须期间、法律法规及监管要求的时限届满后，或终止服务或运营后对您的个人信息进行删除或匿名化处理，法律法规或监管部门另有规定的除外。
    </div>
    <div class="node-title">
      （二）我们在中华人民共和国境内运营中收集和产生的个人信息，存储在中国境内。以下情形除外：
    </div>
    <div class="p-title">1、法律法规有明确规定；</div>
    <div class="p-title">2、获得您的授权同意；</div>
    <div class="p-title">
      3、您使用竞游在线服务，且需要向境外传输您的个人信息完成交易的；
    </div>
    <div class="text">
      针对以上情形，我们会确保依据本《隐私政策》及国家法律法规要求对您的个人信息提供足够的保护。
    </div>
    <div class="node-title">
      （三）我们非常重视个人信息安全，成立了专门的安全团队，并采取一切合理可行的措施，保护您的个人信息：
    </div>
    <div class="text">
      <div class="p-title">1、数据安全技术措施 </div>
      <div>
        我们会采用符合业界标准的安全防护措施，包括建立合理的制度规范、安全技术来防止您的个人信息遭到未经授权的访问使用、修改,避免数据的损坏或丢失。网络服务采取了多种加密技术，包括在某些服务中，我们将利用加密技术（例如SSL）来保护您的个人信息，采取加密技术对您的个人信息进行加密保存，并通过隔离技术进行隔离。
        在个人信息使用时，包括个人信息展示、个人信息关联计算，我们会采用多种数据脱敏技术增强个人信息在使用中的安全性。采用严格的数据访问权限控制和多重身份认证技术保护个人信息，避免数据被违规使用。
      </div>
      <div class="p-title">2、我们为保护个人信息采取的其他安全措施</div>
      <div>
        （1）我们通过建立数据分类分级制度、数据安全管理规范、数据安全开发规范来管理规范个人信息的存储和使用。
      </div>
      <div>
        （2）我们通过个人信息接触者保密协议、监控和审计机制来对数据进行全面安全控制。
      </div>
      <div>
        （3）我们还会举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识和安全意识。
      </div>
      <div>
        （4）我们仅允许有必要知晓这些个人信息的我们及我们关联方的员工、合作伙伴访问您的个人信息，并为此设置了严格的访问权限控制和监控机制。我们同时要求可能接触到您的个人信息的所有人员履行相应的保密义务。如果未能履行这些义务，可能会被追究法律责任或被中止与我们的合作关系。
      </div>
      <div class="p-title">
        3、互联网并非绝对安全的环境，而且电子邮件、即时通讯、社交软件或其他服务软件与其他用户的交流方式无法确定是否完全加密，我们建议您使用此类工具时请使用复杂密码，并注意保护您的个人信息安全。
      </div>
      <div class="p-title">
        4、我们将尽力确保或担保您发送给我们的任何个人信息的安全性。如果我们的物理、技术、或管理防护设施遭到破坏，导致个人信息被非授权访问、公开披露、篡改、或毁坏，导致您的合法权益受损，我们将承担相应的法律责任。
      </div>
    </div>
    <div class="node-title">（四）安全事件处置</div>
    <div class="text">
      <div>
        在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施。我们同时将及时将事件相关情况以邮件、信函、短信、电话、推送通知的方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。
      </div>
      <div class="under-line">
        请您理解，由于技术的限制以及风险防范的局限，即便我们已经尽量加强安全措施，也无法始终保证个人信息百分之百的安全。您需要了解，您接入竞游在线服务所用的系统和通讯网络，有可能因我们可控范围外的情况而发生问题。
      </div>
    </div>
    <div class="section-title">五、如何管理您的个人信息</div>
    <div class="text">
      <div>
        我们鼓励您更新和修改您的个人信息以使其更准确有效，也请您理解，您更正、删除、撤回授权或停止使用竞游在线服务的决定，并不影响我们此前基于您的授权而开展的个人信息处理。
      </div>
      <div>您可以通过以下方式来管理您的个人信息： </div>
      <div class="p-title">
        1、帐户个人信息：如果您希望访问您的帐户中的个人基本信息（包括头像、昵称、简介、性别、年龄、姓名、常用邮箱、QQ邮箱、手机），您可以登录帐号通过“帐号-个人主页”进行访问。
      </div>
      <div class="p-title">
        2、订单个人信息：您可以通过登录“帐号”-“我的订单”查看您的所有订单状态。
      </div>
      <div class="p-title">
        3、优惠券：您可以通过登录“帐号”-“优惠券”查看您的优惠券。
      </div>
    </div>
    <div class="node-title">（二）更正或更新您的个人信息</div>
    <div class="text">
      如果您希望更正或更新您的帐户中的个人基本信息（包括头像、昵称、收货地址、手机号），您可以登录帐号通过“帐号-个人主页-编辑”进行修改。
    </div>
    <div class="node-title">（三）改变、撤回您授权同意的范围</div>
    <div class="text">
      <div>
        您总是可以选择是否披露个人信息。有些个人信息是使用竞游在线服务所必需的，但大多数其他个人信息的提供是由您决定的。您可以通过删除个人信息、关闭设备功能、注销账号的方式改变或撤回您授权我们继续收集个人信息的范围。
      </div>
      <div>
        当您撤回授权后，我们无法继续为您提供撤回授权所对应的服务，也不再处理您相应的个人信息。但您撤回授权的决定，不会影响此前基于您的授权而开展的个人信息处理。
      </div>
    </div>
    <div class="node-title">（四）注销您的帐号</div>
    <div class="text">
      <div>
        如果您不再使用竞游在线服务，在符合服务协议约定条件及国家相关法律法规规定的情况下，<span
          class="bold"
          >您可通过【如何联系我们】进行处理或操作</span
        >。
      </div>
      <div>
        一般情况下，我们将在十五个工作日内为您注销帐号，届时我们将停止为您提供竞游在线服务。当您的帐号注销或被删除后，与该帐号相关的竞游在线服务项下的全部服务资料和数据将被删除或匿名化处理，但法律法规另有规定的除外。
      </div>
    </div>
    <div class="node-title bold">（五）自动决策结果申诉</div>
    <div class="text">
      在某些业务功能中，我们可能仅依据信息系统、算法在内的非人工自动决策机制作出决定。如果这些决定显著影响您的合法权益，您有权通过本隐私政策【第九条】载明的方式与我们联系，以要求我们作出解释或对信息系统自动决策结果进行投诉。
    </div>
    <div class="node-title">（六）公开与分享</div>
    <div class="text">
      我们的多项服务可让您不仅与您的社交网络、也与使用该服务的所有用户公开分享您的相关信息。例如，您在竞游在线服务中其他用户可见的公开区域内上传或发布的个人信息，<span
        class="under-line bold"
        >您对其他人上传或发布的信息作出的回应中公开您的个人信息，该个人信息可能会被他人收集并加以使用，只要您不删除您所公开或共享的个人信息，有关个人信息可能一直留存在公众领域；即使您删除共享个人信息，有关个人信息仍可能由其他用户或不受我们控制的第三方独立地缓存、复制或储存，或由其他用户或该等第三方在公众领域保存。如您将个人信息通过上述渠道公开或共享，由此造成您的个人信息泄露，我们不承担责任。因此，我们提醒并请您慎重考虑是否通过上述渠道公开或共享您的个人信息。当您发现他人不正当地收集或使用您的个人信息时，您可以通过本政策第九条【如何联系我们】约定的联系方式联络我们。</span
      >
    </div>
    <div class="node-title bold">(七）响应您的上述请求</div>
    <div class="text">
      <div>
        为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情况收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际（例如，涉及备份磁带上存放的信息）的请求，我们可能会予以拒绝。
      </div>
      <div>在以下情形中，我们将无法响应您的请求：</div>
      <div class="p-title">1、与我们履行法律法规规定的义务相关的；</div>
      <div class="p-title">2、与国家安全、国防安全直接相关的；</div>
      <div class="p-title">
        3、与公共安全、公共卫生、重大公共利益直接相关的；
      </div>
      <div class="p-title">4、与刑事侦查、起诉、审判和执行判决直接相关的；</div>
      <div class="p-title">
        5、我们有充分证据表明您存在主观恶意或滥用权利的；
      </div>
      <div class="p-title">
        6、出于维护您或其他个人的生命、财产相关的重大合法权益但又很难得到本人同意的；
      </div>
      <div class="p-title">
        7、响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的；
      </div>
      <div class="p-title">8、涉及商业秘密的。</div>
    </div>
    <div class="section-title">六、第三方服务</div>
    <div class="text">
      <div>
        竞游在线服务可能链接至第三方提供的社交媒体或其他服务（包括网站或其他服务形式）。包括：
      </div>
      <div class="node-title">
        （一）您可利用“分享”键将某些竞游在线服务内容分享到第三方服务，或您将第三方服务内容分享到竞游在线服务。这些功能可能会收集您的信息（包括您的日志信息），并可能在您的电脑装置COOKIES，从而正常运行上述功能；
      </div>
      <div class="text">
        <div class="node-title">
          （二）我们通过广告或我们服务的其他方式向您提供链接，使您可以链接至第三方的服务或网站；
        </div>
      </div>
      <div class="node-title">
        （三）其他接入第三方服务的情形。<span class="bold"
          >为实现本政策中声明的目的，我们可能会接入第三方服务商提供的SDK或其他类似的应用程序，并将我们依照本政策收集的您的某些信息共享给该等第三方服务商，以便提供更好的客户服务和用户体验。</span
        >目前，我们接入的第三方服务商主要包括以下几种类型：
      </div>
      <div class="text">
        <div>（1）用于广告相关服务，包括广告展示、广告数据监测/统计；</div>
        <div>（2）用于消息推送功能，包括手机厂商Push推送、特定事件提醒；</div>
        <div>
          （3）用于支付相关服务，包括订单支付、交易行为核验、收入结算、支付信息汇总统计；
        </div>
        <div>
          （4）用于在您同意的情况下获取设备位置权限、搜集设备信息和日志信息；
        </div>
        <div>
          （5）用于第三方授权服务，包括第三方帐号登陆、将有关内容分享至第三方产品；
        </div>
        <div>
          （6）用于支持产品功能模块，包括在线直播、视频播放、语音识别、智能客服、内容存储；
        </div>
        <div>
          <span class="bold"
            >我们接入的部分第三方SDK或类似应用程序可能会收集您的个人信息，如您在我们的服务中使用这类由第三方提供的服务时，您同意将由其直接收集和处理您的信息。</span
          >我们会评估这类第三方服务收集个人信息的合法性、正当性、必要性，要求该等第三方对您的个人信息采取保护措施，并严格遵守相关法律法规与监管要求。
        </div>
        <div>
          <span class="bold"
            >如上所述服务由相关的第三方负责运营。您使用该等第三方服务（包括您向该第三方提供的任何个人信息），须受第三方自己的服务条款及个人信息保护声明（而非本政策）约束，您需要仔细阅读其条款。我们仅会出于正当、必要、特定的目的共享您的信息。我们会要求他们履行相关保密义务并采取相应的安全措施。</span
          >
        </div>
      </div>
    </div>
    <div class="section-title">七、通知和变更</div>
    <div class="text">
      <div class="bold">
        为了给您提供更好的服务，我们可能会根据竞游在线服务的更新情况及法律法规的相关要求适时修改本政策的条款，该修改构成本政策的一部分。我们会在本页面上公布对本政策所作的任何变更。如有重大变更，或该等变更会导致您在本隐私政策项下权利的实质减损，我们将在变更生效前，通过在页面显著位置（包括网站公告、推送通知、弹窗提示或其他方式）提示。特定产品和服务的隐私保护指引有关个人信息的处理目的、处理方式和处理的个人信息种类发生变更的，会重新取得您的同意。
      </div>
      <div class="bold">本政策所指的重大变更包括但不限于：</div>
      <div class="p-title">
        1、我们的服务模式发生重大变化。包括处理个人信息的目的、处理的个人信息类型、个人信息的使用方式；
      </div>
      <div class="p-title">
        2、我们在所有权结构方面发生重大变化。包括业务调整、破产并购引起的所有者变更；
      </div>
      <div class="p-title">
        3、个人信息共享、转让或公开披露的主要对象发生重大变化；
      </div>
      <div class="p-title">
        4、您参与个人信息处理方面的权利及其行使方式发生重大变化；
      </div>
      <div class="p-title">
        5、我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生重大变化；
      </div>
      <div class="p-title">
        6、其他重要的或可能严重影响您的个人权益的情况发生。
      </div>
      <div class="under-line">
        我们的任何修改都会将您的满意度置于首位。我们鼓励您在每次使用竞游在线服务时都查阅我们的隐私政策。
      </div>
      <div class="under-line">
        我们可能适时修改本《隐私政策》的条款，该修改构成本《隐私政策》的一部分。对于重大变更，我们会提供更显著的通知，您如果不同意该变更，可以选择停止使用竞游在线服务。
      </div>
      <div class="under-line">
        我们可能在必需时（例如当我们由于系统维护而暂停某一项服务时）发出与服务有关的公告。您可能无法取消这些与服务有关、性质不属于推广的公告。
        最后，您必须对您的帐号和密码信息负有保密义务。任何情况下，请小心妥善保管。
      </div>
    </div>
    <div class="section-title">八、法律适用与管辖</div>
    <div class="text bold">
      凡因本协议引起的或与本协议有关的任何争议,双方应协商解决。协商不成时，任何一方均可向被告所在地有管辖权的人民法院提起诉讼。
    </div>
    <div class="section-title">九、如何联系我们</div>
    <div class="text">
      我们设立了个人信息保护专职部门和个人信息保护负责人，如您有关于个人信息安全的投诉和举报，或您对本政策、您的个人信息的相关事宜有任何问题、意见或建议，以及有关本政策或竞游在线的隐私措施的问题，请与我们联系：<span
        class="red"
        >您可发送邮件至【liufj@zdjjy.com】、联络在线客服或写信至以下地址【杭州市滨江区滨安路
        435 号 电魂大厦 8 楼</span
      >
      中竞（浙江）教育科技有限公司
      个人信息保护负责人（收），邮编：310000】。一般情况下，我们将在收到您的问题、意见或建议，并验证您的用户身份后的十五日内予以处理。如您不满意我们的处理，还可以向履行个人信息保护职责的部门进行投诉、举报。
    </div>
  </div>
</template>

<script>
export default {
  name: "SoulProBPrivacyPolicy",

  data() {
    return {};
  },

  created() {
    document.title = "隐私政策";
  },

  methods: {},
};
</script>

<style lang="less" scoped>
.privacyPolicyWrp {
  margin: 0 auto;
  padding: 50px 0 100px;
  width: 1200px;
  overflow: hidden;
  .title {
    display: block;
    margin-bottom: 10px;
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    color: #1a1a1a;
  }
  .text {
    margin-bottom: 30px;
    line-height: 1.7;
    font-size: 16px;
    color: #4c4c4c;
  }
  .bold {
    font-weight: bold;
    font-size: 18px;
    color: #333;
  }
  .under-line {
    text-decoration: underline;
  }
  .fs_18 {
    font-size: 18px;
  }
  .red {
    color: #ff0000;
  }
  .section-title {
    padding-top: 20px;
    margin-bottom: 8px;
    font-weight: bold;
    font-size: 22px;
    color: #1a1a1a;
  }
  .node-title {
    margin-bottom: 8px;
    font-size: 20px;
    color: #1a1a1a;
  }
  .p-title {
    margin-bottom: 2px;
    font-size: 18px;
    color: #1a1a1a;
  }
}
</style>