<template>
  <div class="course-intro-wrp">
    <div class="introduce">
      <div class="menu">
        <div
          class="item"
          :class="{ active: selectedIntroMenu === item.value, hide: item.hide }"
          v-for="item in introMenuList"
          :key="item.value"
          @click="onChooseMenu(item.value)"
        >
          <div class="text">{{ item.text }}</div>
          <div class="line"></div>
        </div>
      </div>
      <div class="intro-info" v-show="selectedIntroMenu === 'intro'">
        <div class="intro">
          <div class="title">
            <img src="@/assets/images/desc-icon.png" alt="" class="icon" />
            课程描述
          </div>
          <div class="value">
            {{ descData.intro }}
          </div>
        </div>
        <div class="title">
          <img src="@/assets/images/intro-icon.png" alt="" class="icon" />
          课程介绍
        </div>
        <div class="desc-ref" ref="descRef"></div>
      </div>
      <div class="catalogue" v-show="selectedIntroMenu === 'catalogue'">
        <el-collapse v-model="activeName" accordion>
          <el-collapse-item
            v-for="item in chapterInfos"
            :name="item.id"
            :key="item.id"
          >
            <template slot="title">
              <div class="chapter-head">
                <div class="title">{{ item.title }}</div>
                <img
                  src="@/assets/images/arrow-bottom.png"
                  alt=""
                  class="chapter-head-icon"
                  :class="{ active: activeName === item.id }"
                />
              </div>
            </template>
            <div
              class="inside-item"
              v-for="v in item.lessonDataPacks"
              :key="v.id"
            >
              <img
                src="@/assets/images/replay-course-icon.png"
                alt=""
                class="icon"
                v-if="v.lessonMode === 1"
                @click="onPlay(v.id)"
              />
              <img
                src="@/assets/images/live-course-icon.png"
                alt=""
                class="icon"
                v-if="
                  v.lessonMode === 2 &&
                  !isNaN(v.liveStatus) &&
                  v.liveStatus !== 99
                "
                @click="onPlay(v.id)"
              />
              <div class="info">
                <div class="p-name">
                  {{ v.title }}
                </div>
                <div class="time">
                  {{ courseTypes[v.lessonMode]
                  }}<span v-if="v.lessonMode === 2">{{
                    v.liveStart ? " | " + v.liveStart : ""
                  }}</span>
                </div>
              </div>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
    </div>
    <div class="other-info">
      <div class="other-info-title">
        <span class="line"></span>
        <span class="text">课程老师</span>
      </div>
      <div class="teachers">
        <div class="item" v-for="item in teacherList" :key="item.id">
          <img :src="item.thumb" alt="" class="teacher-img" />
          <div class="detail">
            <div class="name-wrp">
              <span class="name">{{ item.name }}</span>
            </div>
            <div class="teach-lv">
              <img
                v-for="v in 5"
                :key="v"
                src="@/assets/images/teach-lv-img.png"
                alt=""
                class="teach-lv-img"
              />
            </div>
            <div class="teach-intro">
              {{ item.describe }}
            </div>
          </div>
        </div>
      </div>
      <!-- <template v-if="recommendCourse.length">
        <div class="other-info-title">
          <span class="line"></span>
          <span class="text">推荐课程</span>
        </div>
        <div class="recommend">
          <div
            class="item"
            v-for="item in recommendCourse"
            :key="item.id"
            @click="onToCourseDetail(item.id)"
          >
            <img :src="item.courseThumb" alt="" class="course-img" />
            <div class="detail">
              <div class="name">{{ item.courseName }}</div>
              <div class="intro">
                {{ item.briefDescribe }}
              </div>
              <div class="money">￥{{ item.minPrice }}</div>
            </div>
          </div>
        </div>
      </template> -->
    </div>
  </div>
</template>

<script>
import { courseTypes as _options } from "../../utils/options";
export default {
  name: "courseIntro",
  props: {
    descData: {
      default() {
        return {};
      },
      type: Object,
    },
    changeId: {
      type: Function,
      default(id) {
        this.$router.push("/course-center/detail?id=" + id);
      },
    },
    onPlay: {
      type: Function,
      default() {},
    },
    isInMyCourse: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      introMenuList: [
        {
          text: "课程介绍",
          value: "intro",
        },
        {
          text: "目录",
          value: "catalogue",
        },
      ],
      selectedIntroMenu: "intro",
      courseId: "",
      activeName: "",
      chapterInfos: [],
      loading: false,
      teacherList: [],
      recommendCourse: [],
    };
  },
  computed: {
    courseTypes() {
      return _options;
    },
  },
  methods: {
    onToCourseDetail(id) {
      this.changeId(id);
    },
    onChooseMenu(item) {
      this.selectedIntroMenu = item;
    },
  },
  watch: {
    descData: {
      deep: true,
      immediate: false,
      handler(newValue) {
        if (newValue.readMe) {
          this.$refs.descRef.innerHTML = newValue.readMe;
        }
        if (newValue.teachers) {
          this.teacherList = newValue.teachers;
        }
        if (newValue.recommendCourse) {
          this.recommendCourse = newValue.recommendCourse;
        }

        if (newValue.chapterInfos) {
          // let num = 0;
          newValue.chapterInfos.forEach((item, index) => {
            item.id = index;
            // item.lessons.forEach((v, i) => {
            //   v.id = num;
            //   v.info = newValue.dataPacks[num] || {};
            //   num++;
            // });
          });
          this.chapterInfos = newValue.chapterInfos || [];
        }
      },
    },
  },
};
</script>

<style lang="less" scoped>
.course-intro-wrp {
  display: flex;
  margin-bottom: 60px;
  margin-top: 60px;
  .introduce {
    margin-right: 40px;
    width: 730px;
    .menu {
      display: flex;
      align-items: flex-end;
      margin-bottom: 40px;
      .item {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 30px;
        cursor: pointer;
        .text {
          font-size: 18px;
          color: #808080;
        }
        .line {
          display: none;
          margin-top: 2px;
          width: 110%;
          height: 2px;
          background-color: #e85900;
          border-radius: 1px;
        }
        &.active {
          .text {
            color: #e85900;
          }
          .line {
            display: block;
          }
        }
        &.hide {
          display: none;
        }
      }
    }
    .intro-info {
      .title {
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        font-size: 18px;
        color: #1a1a1a;
        .icon {
          margin-right: 8px;
          width: 20px;
          height: 20px;
        }
      }
      .intro {
        margin-bottom: 40px;
        padding: 20px;
        background-color: #ffeee4;
        border-radius: 10px;
        .value {
          line-height: 1.6;
          font-size: 16px;
          color: #666;
        }
      }
      .desc-ref {
        line-height: 1.6;
        font-size: 16px;
        color: #333;
        /deep/ img {
          max-width: 100%;
        }
      }
      .intro-img {
        margin-bottom: 20px;
        width: 100%;
      }
    }
    .catalogue {
      /deep/ .el-collapse-item__header {
        margin-bottom: 10px;
      }
      .chapter-head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        .title {
          font-size: 18px;
          color: #1a1a1a;
        }
        .chapter-head-icon {
          width: 24px;
          height: 24px;
          transition: all 0.3s;
          transform: rotate(0);
          &.active {
            transform: rotate(180deg);
          }
        }
      }
      .inside-item {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        padding-left: 20px;
        height: 87px;
        background-color: #ffeee4;
        border-radius: 10px;
        .icon {
          margin-right: 20px;
          width: 33px;
          height: 33px;
          cursor: pointer;
        }
        .info {
          .p-name {
            margin-bottom: 4px;
            font-size: 16px;
            color: #807771;
          }
          .time {
            font-size: 16px;
            color: #998e88;
          }
        }
      }
      .item {
        margin-bottom: 10px;
        overflow: hidden;
        &.opened {
          .head .icon {
            transform: rotate(180deg);
          }
          .cont {
            max-height: 800px;
          }
        }
        .head {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 15px;
          cursor: pointer;
          .section-name {
            font-size: 18px;
            color: #1a1a1a;
          }
          .icon {
            transition: all 0.3s;
            width: 24px;
            height: 24px;
          }
        }
        .cont {
          transition: all 0.3s;
          max-height: 0px;
        }
      }
    }
    .mentoring {
      .ask-wrp {
        /deep/ .el-textarea__inner:focus {
          border-color: #ff5f19;
        }
        .ask-handle {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          margin-top: 20px;
          margin-bottom: 20px;
          .imgs {
            display: flex;
            flex-wrap: wrap;
            flex: 1;
            margin-right: 30px;
            .img {
              position: relative;
              img {
                margin-right: 10px;
                width: 80px;
                height: 80px;
                border-radius: 8px;
                overflow: hidden;
              }
              .del-icon {
                position: absolute;
                right: 9px;
                top: -2px;
                width: 16px;
                height: 16px;
                line-height: 10px;
                text-align: center;
                font-size: 12px;
                color: #fff;
                background-color: rgba(0, 0, 0, 0.5);
                border-radius: 50%;
                cursor: pointer;
              }
            }
          }
          .handle {
            display: flex;
            align-items: center;
            .put-img-icon {
              width: 17px;
              height: 17px;
            }
            .put-img-btn {
              position: relative;
              top: -2px;
              margin-left: 10px;
              margin-right: 20px;
              font-size: 14px;
              color: #ff5f19;
              cursor: pointer;
            }
            .submit-btn {
              width: 90px;
              height: 34px;
              line-height: 34px;
              text-align: center;
              font-size: 18px;
              color: #fff;
              background-color: #ff5f19;
              border-radius: 17px;
              cursor: pointer;
            }
          }
        }
      }
      .mentoring-list {
        padding-bottom: 40px;
        .item {
          display: flex;
          padding: 24px 0;
          border-bottom: 1px solid rgba(255, 95, 25, 0.1);
          .mentoring-icon {
            margin-top: 4px;
            margin-right: 16px;
            width: 17px;
            height: 17px;
          }
          .detail {
            flex: 1;
            margin-right: 20px;
            line-height: 1.6;
            .question {
              margin-bottom: 16px;
              height: max-content;
              .text {
                min-height: 50px;
                font-size: 16px;
                color: #1a1a1a;
              }
            }
            .answer {
              height: max-content;
              font-size: 16px;
              .text {
                min-height: 50px;
                color: #666;
              }
              .label {
                color: #00e396;
              }
            }
            .imgs {
              position: relative;
              display: flex;
              flex-wrap: wrap;
              margin-top: 10px;
              img {
                margin-right: 10px;
                width: 100px;
                height: 100px;
                border-radius: 10px;
              }
            }
            .close {
              height: 50px;
              text-overflow: -o-ellipsis-lastline;
              overflow: hidden; //溢出内容隐藏
              text-overflow: ellipsis; //文本溢出部分用省略号表示
              display: -webkit-box; //特别显示模式
              -webkit-line-clamp: 2; //行数
              line-clamp: 2;
              -webkit-box-orient: vertical; //盒子中内容竖直排列
            }
            .open1,
            .open2 {
              text-align: right;
              font-size: 12px;
              color: #ffaf8c;
              cursor: pointer;
              z-index: 2;
              transition: all 0.15s;
              &:hover {
                color: #ff5f19;
              }
            }
            .open1 {
              margin-top: -18px;
              margin-right: -138px;
            }
            .open2 {
              margin-top: -18px;
              margin-right: -138px;
            }
          }
          .time {
            margin-top: 7px;
            height: 20px;
            font-size: 12px;
            color: #b3b3b3;
          }
        }
      }
      .empty {
        margin: 100px auto;
        text-align: center;
        font-size: 18px;
        color: #333;
      }
    }
  }
  .other-info {
    flex: 1;
    overflow: hidden;
    .other-info-title {
      display: flex;
      align-items: center;
      margin-bottom: 40px;
      .line {
        margin-right: 10px;
        height: 18px;
        width: 4px;
        background-color: #e85900;
        border-radius: 2px;
      }
      .text {
        font-size: 20px;
        color: #1a1a1a;
      }
    }
    .teachers {
      margin-bottom: 56px;
      .item {
        display: flex;
        margin-bottom: 28px;
        .teacher-img {
          margin-right: 20px;
          height: 120px;
          width: 120px;
          border-radius: 50%;
          overflow: hidden;
        }
        .detail {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .name-wrp {
            display: flex;
            align-items: flex-end;
            margin-bottom: 4px;
            line-height: 1;
            .name {
              margin-right: 20px;
              font-size: 18px;
              color: #1a1a1a;
            }
            .category {
              font-size: 14px;
              color: #f05c00;
            }
          }
          .teach-lv {
            display: flex;
            margin-bottom: 6px;
            .teach-lv-img {
              margin-right: 8px;
              width: 24px;
              height: 24px;
            }
          }
          .teach-intro {
            line-height: 1.7;
            font-size: 14px;
            color: #808080;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden; //溢出内容隐藏
            text-overflow: ellipsis; //文本溢出部分用省略号表示
            display: -webkit-box; //特别显示模式
            -webkit-line-clamp: 3; //行数
            line-clamp: 3;
            -webkit-box-orient: vertical; //盒子中内容竖直排列
          }
        }
      }
    }
    .recommend {
      .item {
        display: flex;
        margin-bottom: 40px;
        cursor: pointer;
        .course-img {
          margin-right: 20px;
          width: 161px;
          height: 113px;
        }
        .detail {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .name {
            font-size: 18px;
            color: #1a1a1a;
          }
          .intro {
            margin-bottom: 10px;
            font-size: 12px;
            color: #808080;
            line-height: 1.1;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden; //溢出内容隐藏
            text-overflow: ellipsis; //文本溢出部分用省略号表示
            display: -webkit-box; //特别显示模式
            -webkit-line-clamp: 2; //行数
            line-clamp: 2;
            -webkit-box-orient: vertical; //盒子中内容竖直排列
          }
          .money {
            font-weight: bold;
            font-size: 18px;
            color: #ff5f19;
          }
        }
      }
    }
  }
}
</style>
