<template>
  <div class="shop-cart-wrp">
    <div class="title-text">需求清单</div>
    <div class="cart-cont">
      <template v-if="goodsList.length">
        <div class="table-wrp">
          <table>
            <tr>
              <th>
                <div class="check-all" @click="onCheckAll()">
                  <img
                    src="@/assets/images/check-gray.png"
                    class="check-icon"
                    v-show="!allChecked"
                  />
                  <img
                    src="@/assets/images/check-light.png"
                    class="check-icon"
                    v-show="allChecked"
                  />
                  全选
                </div>
              </th>
              <th>课程</th>
              <th>推荐授课方式</th>
              <!-- <th>课时数</th> -->
              <th>操作</th>
            </tr>
            <tr v-for="item in goodsList" :key="item.id">
              <td class="check" @click="onCheckItem(item)">
                <img
                  src="@/assets/images/check-gray.png"
                  class="check-icon"
                  v-show="!item.checked"
                />
                <img
                  src="@/assets/images/check-light.png"
                  class="check-icon"
                  v-show="item.checked"
                />
              </td>
              <td class="base-info">
                <div class="base-info-wrp">
                  <img :src="item.product.tags.courseThumb" alt="" />
                  <div class="detail">
                    <span class="name">{{ item.product.name }}</span>
                    <div class="intro">
                      {{ item.product.tags.briefDescribe }}
                    </div>
                  </div>
                </div>
              </td>
              <td class="course-type">
                {{ courseTypes[item.product.tags.mode] }}课
              </td>
              <!-- <td class="num">{{ item.product.lessonCount }}</td> -->
              <td class="handle">
                <div class="btn" @click="onDelGoods(item.id)">删除</div>
              </td>
            </tr>
          </table>
        </div>
        <div class="submit-wrp">
          <div class="title">需求详情：</div>
          <el-input
            type="textarea"
            :rows="6"
            placeholder="请输入您的需求详情"
            v-model="demandText"
          >
          </el-input>
          <div class="ps-text">完成课程订单的支付后需要向我们提交相关资料（学生名单、课程分配计划表、如有直播则“直播排期表”等）</div>
          <div class="submit-btn" @click="onToSubmitOrder()">提交需求</div>
        </div>
      </template>
      <div class="empty" v-if="!goodsList.length && show">
        <img src="@/assets/images/empty-demand.png" alt="" />
        <div class="text">咦，怎么没有需求清单呀~</div>
      </div>
    </div>
  </div>
</template>

<script>
import { courseTypes as _options } from "../../utils/options";
export default {
  name: "shopCart",
  data() {
    return {
      show: false,
      loading: false,
      goodsList: [],
      demandText: "",
    };
  },
  computed: {
    courseTypes() {
      return _options;
    },
    allChecked() {
      return this.goodsList.every((item) => item.checked);
    },
    checkedGoods() {
      return this.goodsList.filter((item) => item.checked);
    },
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      this.onGetCartGoodsList();
    },
    onGetCartGoodsList() {
      this.$request.onGetShopCartApi({ size: 99, offset: 0 }).then((res) => {
        const _data = res.result.cartItems || [];
        _data.forEach((item) => (item.checked = false));
        this.goodsList = _data;
        this.show = true;
      });
    },
    onDelGoods(id) {
      if (this.loading) return;
      this.$confirm("确认删除该商品吗？", "确认删除", {
        confirmButtonText: "确定",
        showCancelButton: false,
      }).then(() => {
        this.loading = true;
        this.$request
          .onDelCartGoodsApi({ cartIds: id })
          .then(() => {
            this.$message.success("删除成功");
            this.onGetCartGoodsList();
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      });
    },
    onCheckItem(item) {
      item.checked = !item.checked;
    },
    onCheckAll() {
      const _allChecked = this.allChecked;
      this.goodsList.forEach((item) => (item.checked = !_allChecked));
    },
    onToSubmitOrder() {
      if (!this.checkedGoods || !this.checkedGoods.length) {
        return this.$message.warning("请先选择商品");
      }
      if (this.loading) return;
      this.loading = true;
      let goodsIds = [];
      this.checkedGoods.forEach((item) => goodsIds.push(item.id));
      this.$request
        .onCreateOrderApi({ cartIds: goodsIds, description: this.demandText })
        .then((res) => {
          this.$message.success("需求提交成功");
          this.loading = false;
          this.onGetCartGoodsList();
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="less" scoped>
.shop-cart-wrp {
  background-image: url("@/assets/images/cart-bg.png");
  background-size: 1200px, 244px;
  background-repeat: no-repeat;
  background-position: top;
  overflow: hidden;
  .title-text {
    margin-top: 84px;
    margin-bottom: 18px;
    padding-left: 60px;
    line-height: 1;
    font-size: 26px;
    color: #1a1a1a;
  }
  .cart-cont {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    margin: 0 auto 60px;
    width: 1160px;
    height: calc(100vh - 216px);
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 0 6px rgba(219, 124, 65, 0.1);
    overflow: auto;
    .table-wrp {
      table {
        width: 100%;
      }
      tr {
        &:first-child {
          height: 60px;
          text-align: center;
        }
      }
      th {
        line-height: 60px;
        font-size: 16px;
        color: #333;
      }
      .check-all {
        display: flex;
        align-items: center;
        position: relative;
        left: 28px;
        top: 8px;
        white-space: nowrap;
        cursor: pointer;
        .check-icon {
          margin-right: 10px;
        }
      }
      td {
        vertical-align: middle;
        height: 140px;
        text-align: center;
        font-size: 18px;
        color: #808080;
        .value {
          font-weight: bold;
          color: #ff5f19;
        }
      }
      .check {
        width: 80px;
        cursor: pointer;
      }
      .base-info {
        width: 560px;
      }
      .base-info-wrp {
        display: flex;
        width: 600px;
        box-sizing: content-box;
        img {
          margin-right: 20px;
          width: 180px;
          height: 104px;
          border-radius: 8px;
          overflow: hidden;
        }
        .detail {
          overflow: hidden;
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .name {
            text-align: left;
            font-size: 18px;
            color: #1a1a1a;
          }
          .intro {
            text-align: left;
            font-size: 14px;
            color: #808080;
            line-height: 1.6;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden; //溢出内容隐藏
            text-overflow: ellipsis; //文本溢出部分用省略号表示
            display: -webkit-box; //特别显示模式
            -webkit-line-clamp: 2; //行数
            line-clamp: 2;
            -webkit-box-orient: vertical; //盒子中内容竖直排列
          }
        }
      }
      .course-type {
        width: 160px;
      }
      .num {
        padding: 0 30px;
      }
      .handle {
        width: 160px;
        cursor: pointer;
        transition: all 0.15s;
        &:hover {
          color: #ff1919;
        }
      }
    }
    .empty img {
      width: 144px;
      height: 100px;
    }
  }
  .submit-wrp {
    position: relative;
    margin-top: 60px;
    padding: 0 60px;
    .title {
      font-size: 18px;
      color: #1a1a1a;
    }
    /deep/ .el-textarea {
      margin-top: 20px;
      .el-textarea__inner:focus {
        border-color: #ffaf8c;
      }
    }
    .ps-text {
      margin-top: 10px;
      color: #666;
      font-size: 14px;
    }
    .submit-btn {
      margin-left: auto;
      margin-bottom: 20px;
      width: 180px;
      height: 50px;
      line-height: 50px;
      text-align: center;
      font-size: 24px;
      color: #fff;
      background-color: #ffb080;
      border-radius: 25px;
      cursor: pointer;
      transition: all 0.15s;
      &:hover {
        background-color: #ff5f19;
      }
    }
  }
}
</style>
