import axios from "axios";
import { getToken } from "../utils/auth";
import Element from "element-ui";
import { coursePatch } from "../utils/patch.js";
import store from "../store/index";
import { clearToken, clearUserInfo } from "../utils/auth";
import RESMSG from "./message";

const instance = axios.create({
  baseURL: "",
  // baseURL: "/api",
  timeout: 10000,
  headers: {
    "Content-Type": "application/json; charset=utf-8",
  },
});

// 请求拦截
instance.interceptors.request.use(
  (config) => {
    const token = getToken() || "";
    if (token) {
      if (!config.headers) {
        config.headers = {};
      }
      config.headers.accesstoken = token; // header 里添加 accesstoken
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 响应拦截
instance.interceptors.response.use(
  (response) => {
    if (response.status === 200) {
      if (response.data.isSuccess) {
        if (response.request.responseURL.includes("/api/product/search/")) {
          response.data = coursePatch(response.data);
        }
        return response;
      } else {
        if (RESMSG[response.data.code]) {
          response.data.message = RESMSG[response.data.code];
          if (response.data.code === "E0006") {
            clearToken();
            clearUserInfo();
            store.commit("checkIsLogin");
          }
        }

        Element.Message.error(response.data.message);
        return Promise.reject(response.data);
      }
    } else {
      Element.Message.error(response.data.message);
      return Promise.reject(response.data);
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);

/*
 *封装get方法
 *@param{String} url [请求地址]
 *@param{Object} params 请求参数
 */
export function Get(url, params) {
  return new Promise((resolve, reject) => {
    instance
      .get(url, {
        params,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

/**
 *封装post方法
 *@param{String} url 请求地址
 *@param{Object} params 请求参数
 */
export function Post(url, params) {
  return new Promise((resolve, reject) => {
    instance
      .post(url, params)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

/**
 *封装put方法
 *@param{String} url 请求地址
 *@param{Object} params 请求参数
 */
export function Put(url, params) {
  return new Promise((resolve, reject) => {
    instance
      .put(url, params)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

/**
 *封装patch方法
 *@param{String} url 请求地址
 *@param{Object} params 请求参数
 */
export function Patch(url, params) {
  return new Promise((resolve, reject) => {
    instance
      .put(url, params)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

/**
 *封装delete方法
 *@param{String} url [请求地址]
 *@param{Object} params [请求参数]
 */
export function Delete(url, params) {
  return new Promise((resolve, reject) => {
    instance
      .delete(url, {
        params: params,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
