import axios from "../api/axios";
import Element from "element-ui";
import { isLogin as _isLogin } from "../utils/auth";
export default {
  state: {
    categoryList: [],
    submitOrderType: "",
    submitOrderGoods: [],
  },
  actions: {
    addShopCart(context, params) {
      const isLogin = _isLogin();
      if (!isLogin) {
        return Element.Message.warning("请先登录账户");
      }
      axios.onAddShopCartApi(params).then((res) => {
        Element.Message.success("添加成功");
      });
    },
    onUpdateProgressAction(_, params) {
      axios.onUpdateProgressApi(params).then(() => {
        Element.Message.success("该节学习已完成");
      });
    },
  },
  mutations: {
    setCategoryList(state, data) {
      state.categoryList = data || [];
    },
    setSubmitOrderType(state, data) {
      state.submitOrderType = data || "";
    },
    setSubmitOrderGoodsList(state, data) {
      state.submitOrderGoods = data || [];
    },
    panelScrollToTop(_, data) {
      const panelRef = document.getElementsByClassName("panel-cont")[0];
      panelRef && panelRef.scrollTo(0, data || 0);
    },
  },
};
