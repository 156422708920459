<template>
  <div></div>
</template>

<script>
export default {
  name: "payPage",
  mounted() {
    this.$nextTick(() => {
      const formStr = localStorage.getItem("payForm");
      document.getElementById("app").innerHTML = formStr;
      setTimeout(() => {
        document.forms["alipaysubmit"].submit();
        localStorage.removeItem("payForm");
      });
    });
  },
};
</script>

<style lang="scss" scoped></style>
