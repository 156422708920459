<template>
  <div class="play-room-wrp">
    <div class="room">
      <div class="dplayer-wrp" :class="{ hide: !videoProgressBarVsb }">
        <div class="play-cont show" id="dplayer" ref="player"></div>
      </div>
      <div class="chapterInfos">
        <el-collapse accordion v-model="chapterName">
          <el-collapse-item
            v-for="item in chapterInfos"
            :key="item.id"
            :name="item.id"
          >
            <template slot="title">
              <div class="name">{{ item.title }}</div>
            </template>
            <div class="node" v-for="v in item.lessonDataPacks" :key="v.id">
              <div class="node-icon">
                <img
                  src="@/assets/images/play-room-node-icon.png"
                  alt=""
                  @click="onPlay(v.id)"
                  v-if="
                    v.lessonMode === 1 ||
                    (v.lessonMode === 2 &&
                      !isNaN(v.liveStatus) &&
                      v.liveStatus !== 99)
                  "
                />
              </div>

              <div class="node-detail">
                <div
                  class="node-name"
                  :class="{ active: lessonIndex === v.id }"
                >
                  {{ v.title }}
                </div>
                <div class="handle-item finish-time" v-if="v.lessonMode === 2">
                  <span class="label">直播</span>
                  <span class="label">{{ liveStatus[v.liveStatus] }}</span>
                </div>
                <div class="handle-item" v-if="v.homeworks && v.homeworks.length">
                  <span class="label">课后作业</span>
                  <img
                    src="@/assets/images/download-icon.png"
                    alt=""
                    class="download-icon"
                    @click="downloadFile(v.homeworks[0].path)"
                  />
                </div>
                <div class="handle-item" v-if="v.coursewares && v.coursewares.length">
                  <span class="label">课件下载</span>
                  <img
                    src="@/assets/images/download-icon.png"
                    alt=""
                    class="download-icon"
                    @click="downloadFile(v.coursewares[0].path)"
                  />
                </div>
              </div>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
    </div>
    <CourseIntro
      :descData="moreCourseDetailData"
      :onPlay="onPlay"
      :isInMyCourse="true"
    ></CourseIntro>
  </div>
</template>

<script>
import HLS from "hls.js/dist/hls.js";
import DPlayer from "dplayer";
import CourseIntro from "./courseIntro.vue";
import { liveStatus as _liveStatus } from "../../utils/options";
export default {
  name: "playRoom",
  components: { CourseIntro },
  data() {
    return {
      courseCategory: [],
      courseId: "",
      classCourseId: "",
      chapterInfos: [],
      chapterName: "",
      lessonIndex: null,
      dp: null,
      videoProgressBarVsb: true,
      moreCourseDetailData: {},
    };
  },
  computed: {
    liveStatus() {
      return _liveStatus;
    },
  },
  created() {
    this.courseId = this.$route.query.courseId;
    this.classCourseId = this.$route.query.classCourseId;

    // 课程详情
    this.initCourseInfoData();
  },
  mounted() {
    this.loadVideo("");
  },
  methods: {
    loadVideo(url) {
      const type = url ? "customHls" : "auto";
      this.dp = new DPlayer({
        element: this.$refs.player,
        theme: "#ff5f19", // 风格颜色，例如播放条，音量条的颜色
        loop: false, // 是否自动循环
        lang: "zh-cn", // 语言，'en', 'zh-cn', 'zh-tw'
        hotkey: true, // 是否支持热键，调节音量，播放，暂停等
        preload: "auto", // 自动预加载
        mutex: true,
        video: {
          url,
          type,
          customType: {
            customHls: function (video, player) {
              const hls = new HLS();
              hls.loadSource(video.src);
              hls.attachMedia(video);
            },
          },
        },
      });
      document.querySelector(".dplayer-menu").remove(); //隐藏右键菜单
    },
    onPlay(lessonIndex) {
      this.$request
        .onToLiveRoomApi({ classCourseId: this.classCourseId, courseId: this.courseId, lessonId: lessonIndex })
        .then((res) => {
          this.lessonIndex = lessonIndex;
          if (res.result.liveRoomUrl) {
            window.open(res.result.liveRoomUrl, "_blank");
          } else if (res.result.playbackUrlMap) {
            this.loadVideo(res.result.playbackUrlMap.playbackUrlMap.m3u8[0]);
          } else {
            const videoUrl =
              res.result.playInfoList && res.result.playInfoList.length
                ? res.result.playInfoList[0].playURL
                : "";

            this.loadVideo("");
            this.dp.switchVideo({
              url: videoUrl,
            });
          }
        });
    },
    initCourseInfoData() {
      new Promise(async () => {
        await this.$request
          .getOrganCourseDetailApi({ classCourseId: this.classCourseId })
          .then((res) => {
            this.courseInfo = res.result.productInfo || {};
            this.chapterInfos = res.result.chapters || [];
            this.chapterInfos.forEach((item, index) => {
              item.id = index;
            });
            this.moreCourseDetailData = {
              ...this.moreCourseDetailData,
              ...{
                readMe: decodeURIComponent(this.courseInfo.tags.readMe),
                intro: this.courseInfo.tags.briefDescribe || "",
                chapterInfos: this.chapterInfos,
                teachers: res.result.teachers || [],
              },
            };
            setTimeout(() => {
              if (this.chapterInfos.length) {
                const defaultLesson = this.chapterInfos[0].lessonDataPacks[0];
                if (
                  defaultLesson.lessonMode === 2 &&
                  (isNaN(defaultLesson.liveStatus) ||
                    defaultLesson.liveStatus === 99)
                )
                  return;
                this.chapterName = defaultLesson.id;
                this.onPlay(defaultLesson.id);
              }
            });
          });
      });
    },
    downloadFile(url) {
      const a = document.createElement("a");
      a.href = url;
      a.click();
    },
  },
};
</script>

<style lang="less" scoped>
.play-room-wrp {
  padding-top: 845px;
  .room {
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    height: 845px;
    width: 100vw;
    .dplayer-wrp {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #b9a99f;
      &.hide {
        /deep/ .dplayer-bar-wrap {
          // display: none;
        }
      }
    }
    .play-cont {
      height: 100%;
      width: 100%;
    }
    .chapterInfos {
      padding: 20px;
      width: 320px;
      background-color: #1b2128;
      box-sizing: border-box;
      overflow-x: hidden;
      overflow-y: auto;
      /deep/ .el-collapse-item__header {
        line-height: 26px;
        background-color: #1b2128;
      }
      /deep/ .el-collapse-item__wrap {
        background-color: #1b2128;
      }
      .name {
        margin-top: 10px;
        margin-bottom: 20px;
        font-size: 20px;
        color: #fff;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .node {
        display: flex;
        margin-bottom: 36px;
        width: 100%;
        &:last-child {
          margin-bottom: 10px;
        }
        .node-icon {
          margin-right: 16px;
          width: 21px;
          height: 21px;
          img {
            width: 100%;
            height: 100%;
            cursor: pointer;
          }
        }
        .node-detail {
          flex: 1;
          overflow: hidden;
          .node-name {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin-bottom: 10px;
            line-height: 1;
            font-size: 16px;
            color: #ccc;
            &.active {
              color: #ff5f19;
            }
          }
          .handle-item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 170px;
            .label {
              font-size: 14px;
              color: #ccc;
            }
            .download-icon {
              width: 13px;
              height: 13px;
              cursor: pointer;
            }
            &.finish-time {
              .label {
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
