<template>
  <div class="header-wrp">
    <div class="header-placeholder"></div>
    <div class="header">
      <div class="cont">
        <div class="imgs">
          <div class="icon">
            <img
              src="../assets/images/logo-head.png"
              @click="$router.push('/')"
            />
          </div>
          <img
            src="../assets/images/logo-head2.png"
            alt=""
            class="detail-icon"
          />
        </div>
        <div class="handle-wrp">
          <div class="shop-cart" @click="$router.push('/')">
            <img src="../assets/images/home-icon.png" />
          </div>
          <div class="line"></div>
          <div class="shop-cart" @click="$router.push('/course-center/demand')">
            <img src="../assets/images/cart1.png" />
          </div>

          <div class="line"></div>
          <div class="text-study" @click="onToStudyCenter()">管理中心</div>
          <div class="login" v-if="!$store.state.auth.isLogin">
            <div class="line"></div>
            <div class="login-btn" @click="onOpenLoginModel()">请登录</div>
          </div>
          <div class="user-name" v-if="$store.state.auth.isLogin">
            {{ userName }}
          </div>
          <el-popover
            placement="bottom"
            trigger="hover"
            v-model="popoverVsb"
            v-if="$store.state.auth.isLogin"
          >
            <div
              class="avatar-item"
              v-for="item in avatarList"
              :key="item.id"
              @click="onHandleUserBtn(item.id)"
            >
              <span>{{ item.text }}</span>
            </div>
            <div slot="reference" class="avatar">
              <img src="../assets/images/avatar1.png" alt="" />
            </div>
          </el-popover>
          <img
            class="avatar"
            src="../assets/images/avatar0.png"
            alt=""
            v-else
          />
        </div>
      </div>
    </div>
    <login-model
      v-if="isShowLoginModel"
      :isActive="isLoginModelLight"
      :close="onCloseModel"
      class="login-model"
    ></login-model>
  </div>
</template>

<script>
import { clearUserInfo, clearToken } from "../utils/auth";
import LoginModel from "../views/home/login.vue";
export default {
  name: "Header",
  components: {
    LoginModel,
  },
  data() {
    return {
      avatarList: [
        {
          id: 1,
          text: "账户信息",
        },
        {
          id: 2,
          text: "订单管理",
        },
        {
          id: 3,
          text: "安全退出",
        },
      ],
      isShowLoginModel: false,
      isLoginModelLight: false,
      popoverVsb: false,
    };
  },
  computed: {
    userName() {
      return this.$store.state.auth.userName || "";
    },
  },
  mounted() {
    this.$store.commit("checkIsLogin");
    const loginNow = this.$route.query.loginNow === "true";
    if (loginNow) {
      setTimeout(() => {
        this.onOpenLoginModel();
      });
      let path = this.$route.path;
      this.$router.push(path);
    }
  },
  methods: {
    onOpenLoginModel() {
      this.isShowLoginModel = true;
      setTimeout(() => {
        this.isLoginModelLight = true;
      });
    },
    onCloseModel() {
      this.isLoginModelLight = false;
      setTimeout(() => {
        this.isShowLoginModel = false;
      }, 300);
    },
    onToStudyCenter() {
      this.$router.push("/manage-center");
    },
    onHandleUserBtn(id) {
      this.popoverVsb = false;
      if (id === 1) {
        this.$router.push("/manage-center/my-info");
      } else if (id === 2) {
        this.$router.push("/manage-center/course-order");
      } else {
        this.$request.logoutApi().then(() => {
          clearUserInfo();
          clearToken();
          this.$store.commit("checkIsLogin");
          this.$router.push("/");
          this.$message.success("账号退出成功");
        });
      }
    },
  },
};
</script>

<style scoped lang="less">
.base-center {
  display: flex;
  align-items: center;
}
.header-placeholder {
  height: 80px;
}
.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
  justify-content: center;
  height: 80px;
  width: 100%;
  background-color: #26160b;
  z-index: 99;
  .cont {
    width: 100%;
    max-width: 1200px;
    justify-content: space-between;
    &:extend(.base-center);
    .imgs {
      display: flex;
      align-items: center;
      .icon {
        width: 71px;
        height: 69px;
        border-radius: 10px;
        &:extend(.base-center);
        justify-content: center;
        img {
          height: 68px;
          width: 70px;
          cursor: pointer;
        }
      }
      .detail-icon {
        margin-left: 30px;
        width: 293px;
        height: 42px;
      }
    }
    .handle-wrp {
      &:extend(.base-center);
      .line {
        margin: 0 20px;
        height: 21px;
        width: 1px;
        background-color: #fff;
      }
      .shop-cart {
        width: 28px;
        height: 28px;
        cursor: pointer;
        img {
          height: 100%;
          width: 100%;
        }
      }
      .text-promotion {
        font-size: 24px;
        color: #e85900;
        line-height: 1;
        cursor: pointer;
      }
      .text-study {
        font-size: 20px;
        color: #fff;
        line-height: 1;
        cursor: pointer;
        transition: all 0.15s;
        &:hover {
          color: #ffa56d;
        }
      }
      .login {
        &:extend(.base-center);
        line-height: 1;
        span {
          margin: 0 7px;
          font-size: 20px;
        }
        .login-btn {
          width: 70px;
          height: 27px;
          line-height: 26px;
          text-align: center;
          border-radius: 13px;
          background-color: #f45d00;
          color: #fff;
          font-size: 18px;
          cursor: pointer;
        }
      }
      .user-name {
        position: relative;
        right: -10px;
        margin-left: 10px;
        width: 78px;
        text-align: right;
        font-size: 16px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      .avatar {
        margin-left: 20px;
        cursor: pointer;
        height: 56px;
        width: 54px;
        img {
          height: 56px;
          width: 54px;
          border-radius: 50%;
          overflow: hidden;
        }
      }
    }
  }
}
</style>
