import { Get, Post, Put, Patch, Delete } from "./request.js";

export default {
  // login
  loginApi: (params) => {
    return Post("/api/login/", params);
  },
  logoutApi: (params) => {
    return Post("/api/logout/", params);
  },
  sendLoginCodeApi: (params) => {
    return Post("/api/sms/send/login/", params);
  },
  getUserInfoApi: (params) => {
    return Post("/api/personal/detail/", params);
  },
  // home
  getBannerListApi: (params) => {
    return Post("/api/banner/query/", params);
  },
  // myInfo
  changeUserInfoApi(params) {
    return Post("/api/personal/modify/", params);
  },
  // account
  sendBindPhoneCodeApi: (params) => {
    return Post("/api/sms/send/bindphone/", params);
  },
  bindPhoneApi: (params) => {
    return Post("/api/personal/bindphone/", params);
  },
  sendOldPhoneCodeApi: (params) => {
    return Post("/api/sms/send/modifyphone/", params);
  },
  sendNewPhoneCodeApi: (params) => {
    return Post("/api/sms/send/setnewphone/", params);
  },
  changePhoneApi: (params) => {
    return Post("/api/personal/modifyphone/", params);
  },
  // changeCode
  changePasswordApi: (params) => {
    return Post("/api/personal/password/modify/", params);
  },
  sendChangeCodeCodeApi: (params) => {
    return Post("/api/sms/send/modifypasswordbyphone/", params);
  },
  changeCodeBYPhoneCode: (params) => {
    return Post("/api/personal/password/modifybyphone/", params);
  },
  // myCourse
  getMyCourseListDataApi: (params) => {
    return Post("/api/coursemanage/list/", params);
  },
  getClassByCourseApi: (params) => {
    return Post("/api/coursemanage/classbycourse/", params);
  },
  getCourseDetailApi: (params) => {
    return Post("/api/studycenter/coursedetail/", params);
  },
  onToStudyApi: (params) => {
    return Post("/api/studycenter/study/", params);
  },
  onUpdateProgressApi: (params) => {
    return Post("/api/studycenter/updateprogress/", params);
  },
  onToLiveRoomApi: (params) => {
    return Post("/api/studycenter/enterlive/", params);
  },
  // class-list
  onGetClassListApi: (params) => {
    return Post("/api/classes/list/", params);
  },
  onGetClassCourseListApi: (params) => {
    return Post("/api/classes/querycourse/", params);
  },
  onGetClassCourseProgressListApi: (params) => {
    return Post("/api/coursemanage/progresslist/", params);
  },
  // 获取单个视频资料详情
  getVedioDetailApi: (params) => {
    return Post("/api/vod/getplaylist/", params);
  },
  onToLiveRoomApi: (params) => {
    return Post("/api/classes/enterclasslesson/", params);
  },
  // student-list
  onGetStudentListApi: (params) => {
    return Post("/api/student/list/", params);
  },
  onGetStudentDetailApi: (params) => {
    return Post("/api/student/detail/", params);
  },
  onGetStudentProgressApi: (params) => {
    return Post("/api/study/progress/", params);
  },
  onGetStudentCourseProgressApi: (params) => {
    return Post("/api/study/detail/", params);
  },

  // courseCenter
  getCourseCategoriesApi: (params) => {
    // return Post("/api/course/categories/", params);
    return Post("/api/product/categories/", params);
  },
  getCourseGoodsListApi: (params) => {
    return Post("/api/course/list/", params);
  },
  searchCourseGoodsListApi: (params) => {
    // return Post("/api/course/search/", params);
    return Post("/api/product/search/", params);
  },
  getCourseGoodsDetailApi: (params) => {
    return Post("/api/product/detail/", params);
    // return Post("/api/course/detail/", params);
  },
  getOrganCourseDetailApi: (params) => {
    return Post("/api/classes/getclasscoursedetail/", params);
  },
  getCourseGoodsChaptersApi: (params) => {
    return Post("/api/classes/getclasscoursedetail/", params);
  },
  getCourseListApi: (params) => {
    return Post("/api/course/list/", params);
  },
  onAddShopCartApi: (params) => {
    return Post("/api/cart/add/", params);
  },
  onGetShopCartApi: (params) => {
    return Post("/api/cart/getcart/", params);
  },
  onEditCartGoodsNumApi: (params) => {
    return Post("/api/cart/editcart/", params);
  },
  onDelCartGoodsApi: (params) => {
    return Post("/api/cart/del/", params);
  },
  onCreateOrderApi: (params) => {
    return Post("/api/order/create/", params);
  },
  onGetOrderDetailApi: (params) => {
    return Post("/api/order/detail/", params);
  },
  onGetPayUrlApi: (params) => {
    return Post("/api/order/pay/", params);
  },
  onGetPayResultApi: (params) => {
    return Post("/api/order/orderquery/", params);
  },
  testPay: (params) => {
    return Get("/api/payment/callback_test/", params);
  },
  // my-order
  getOrderListApi: (params) => {
    return Post("/api/order/list/", params);
  },
  onCancleOrderApi: (params) => {
    return Post("/api/order/ordercancel/", params);
  },
  onDelOrderApi: (params) => {
    return Post("/api/order/orderdelete/", params);
  },
  // student-consume
  onGetConsumeListApi: (params) => {
    return Post("/api/student/ordertotalbymonth/", params);
  },
  onGetConsumeDetailApi: (params) => {
    return Post("/api/student/orderbymonth/", params);
  },
};
