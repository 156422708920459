const sexTypeList = [
  {
    text: "男",
    value: 1,
  },
  {
    text: "女",
    value: 2,
  },
  {
    text: "保密",
    value: 0,
  },
];

const courseTypeList = [
  {
    text: "录播",
    value: 1,
  },
  {
    text: "直播",
    value: 2,
  },
  {
    text: "实训",
    value: 3,
  },
  {
    text: "直播+录播",
    value: 4,
  },
];

const courseTypes = {
  1: "录播",
  2: "直播",
  3: "实训",
  4: "直播+录播",
};

const liveStatus = {
  0: "未开始",
  1: "进行中",
  2: "已结束",
  98: "已删除",
  99: "未建立",
};

const grades = {
  1: "A",
  2: "B",
  3: "C",
  4: "D",
  5: "E",
  6: "F",
};

const orderStatus = {
  1: "待确认",
  2: "支付中",
  3: "已完成",
  4: "订单失效",
  5: "物流派送中",
  6: "订单已完成",
  97: "待付款",
  98: "订单超时",
  99: "已取消",
};

export {
  sexTypeList,
  courseTypeList,
  courseTypes,
  liveStatus,
  grades,
  orderStatus,
};