<template>
  <div class="course-center-wrp">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "courseCenter",
};
</script>

<style lang="less">
.course-center-wrp {
  margin: 0 auto;
  width: 1200px;
}
</style>