<template>
  <div class="class-list-wrp">
    <div class="module-title">班级列表</div>
    <div class="panel-cont">
      <div class="query">
        <div class="left">
          <div class="item">
            <span class="label">班级：</span>
            <el-input
              v-model="className"
              placeholder="请输入班级名称"
            ></el-input>
          </div>
        </div>
        <div class="right">
          <div class="btn search" @click="resetIndex()">查询</div>
          <div
            class="btn reset"
            :class="{ light: className }"
            @click="onReset()"
          >
            重置
          </div>
        </div>
      </div>
      <div class="table-wrp" v-if="sourceData.length">
        <el-table :data="sourceData" style="width: 100%">
          <el-table-column prop="className" label="班级">
          </el-table-column>
          <!-- <el-table-column prop="studentCount" label="人数" align="center">
          </el-table-column> -->
          <el-table-column prop="" label="操作" width="200">
            <template slot-scope="scope">
              <div class="btns">
                <div class="btn" @click="onToStudentList(scope.row.classId)">
                  学生详情
                </div>
                <div
                  class="btn"
                  @click="
                    onToCourseList(scope.row.classId, scope.row.className)
                  "
                >
                  查看课程
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          v-if="pageData.total > pageData.size"
          layout="prev, pager, next"
          :total="pageData.total"
          :current-page.sync="pageData.index"
          :page-size="pageData.size"
          @current-change="onPageChange"
        >
        </el-pagination>
      </div>
      <div class="empty" v-if="!sourceData.length && show">
        <img src="@/assets/images/empty-class.png" alt="" />
        <div class="text">{{ emptyText }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "classList",

  data() {
    return {
      emptyText: "还没有添加任何班级哦~",
      show: false,
      className: "",
      pageData: {
        index: 1,
        size: 10,
        total: 1,
      },
      sourceData: [],
    };
  },

  created() {
    this.onGetClassList();
  },

  methods: {
    resetIndex() {
      this.pageData.index = 1;
      this.onGetClassList();
    },
    onGetClassList() {
      this.$request
        .onGetClassListApi({
          offset: (this.pageData.index - 1) * this.pageData.size,
          size: this.pageData.size,
          className: this.className || undefined,
        })
        .then((res) => {
          this.sourceData = res.result.organClassInfos || [];
          this.emptyText = this.className
            ? "暂无该班级哦~"
            : "还没有添加任何班级哦~";
          this.pageData.total = res.result.total;
          this.show = true;
        });
    },
    onPageChange(index) {
      this.pageData.index = index;
      this.$store.commit("panelScrollToTop");
      this.getCourseList();
    },
    onReset() {
      this.className = "";
      this.resetIndex();
    },
    onToStudentList(classId) {
      this.$router.push("/manage-center/student-list?classId=" + classId);
    },
    onToCourseList(classId, className) {
      this.$router.push(
        `/manage-center/class-course-list?classId=${classId}&className=${className}`
      );
    },
  },
};
</script>

<style lang="less" scoped>
.class-list-wrp {
  .query {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0;
    .left {
      display: flex;
      align-items: center;
      .item {
        display: flex;
        align-items: center;
      }
      .label {
        white-space: nowrap;
        margin-left: 20px;
        line-height: 1;
        font-size: 16px;
        color: #1a1a1a;
      }
    }
    .right {
      display: flex;
      .btn {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 20px;
        width: 62px;
        height: 30px;
        box-sizing: border-box;
        border-radius: 10px;
        cursor: pointer;
        &.search {
          font-size: 16px;
          color: #fff;
          background-color: #ffaf8c;
          transition: all 0.15s;
          &:hover {
            background-color: #ff5f19;
          }
        }
        &.reset {
          border: 1px solid #ccc;
          color: #ccc;
          &.light {
            border-color: #ffaf8c;
            color: #ffaf8c;
            transition: all 0.15s;
            &:hover {
              border-color: #ff5f19;
              color: #ff5f19;
            }
          }
        }
      }
    }
  }
  // .table-wrp {
  //   padding: 0 20px;
  // }
  .empty img {
    width: 119px;
    height: 111px;
  }
  /deep/ .el-table {
    &::after,
    &::before {
      display: none;
    }
    tr:hover td.el-table__cell {
      background-color: #fff;
    }
    td,
    th {
      padding-left: 20px;
      height: 66px;
      font-size: 16px;
      border-color: rgba(255, 95, 25, 0.1);
      .cell {
        padding: 0;
      }
    }
    th.is-leaf,
    td.is-leaf {
      border-color: rgba(255, 95, 25, 0.1);
    }
    th {
      color: #1a1a1a;
    }
    td {
      color: #4d4d4d;
      .btns {
        display: flex;
        align-items: center;
      }
      .btn {
        margin-right: 20px;
        color: #ffaf8c;
        cursor: pointer;
        transition: all 0.15s;
        &:hover {
          color: #ff5f19;
        }
      }
      .light {
        color: #ff5f19;
      }
      .gray {
        color: #808080;
      }
    }
  }
  /deep/ .el-pagination {
    margin-top: 40px;
    margin-bottom: 40px;
    text-align: center;
  }
}
</style>
