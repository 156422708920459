import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth'
import route from './route'
import course from "./course";

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    route,
    course
  }
});
