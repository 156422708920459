const UserInfo = "userInfo";
const AccessToken = "accesstoken";
const isLogin = () => {
  return !!localStorage.getItem(AccessToken);
}
const getUserInfo = () => {
  const _userInfo = localStorage.getItem(UserInfo);
  return _userInfo !== "undefined" ? JSON.parse(_userInfo) : undefined;
};
const setUserInfo = (data) => {
  localStorage.setItem(UserInfo, JSON.stringify(data));
};
const clearUserInfo = () => {
  localStorage.removeItem(UserInfo);
};

const getToken = () => {
  return localStorage.getItem(AccessToken);
};
const setToken = (data) => {
  localStorage.setItem(AccessToken, data);
};
const clearToken = () => {
  localStorage.removeItem(AccessToken);
};

export {
  isLogin,
  getUserInfo,
  setUserInfo,
  clearUserInfo,
  getToken,
  setToken,
  clearToken,
};