import { render, staticRenderFns } from "./userAgreement.vue?vue&type=template&id=317bcc1c&scoped=true&"
import script from "./userAgreement.vue?vue&type=script&lang=js&"
export * from "./userAgreement.vue?vue&type=script&lang=js&"
import style0 from "./userAgreement.vue?vue&type=style&index=0&id=317bcc1c&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "317bcc1c",
  null
  
)

export default component.exports