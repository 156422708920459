<template>
  <div class="course-list-wrp">
    <div class="module-title">{{ className || courseName }}</div>
    <div class="panel-cont">
      <template v-if="classId && sourceData.length">
        <div class="head-wrp">
          <div class="thumb">课程</div>
          <div class="name">课程名称</div>
          <div class="type">课程类型</div>
          <div class="num">课时数</div>
          <div class="handle">操作</div>
        </div>
        <div class="item" v-for="item in sourceData" :key="item.id">
          <div class="thumb">
            <img :src="item.course.thumb" alt="">
          </div>
          <div class="name">{{ item.course.name }}</div>
          <div class="type">{{ courseTypes[item.course.mode] }}</div>
          <div class="num">{{ item.course.lessonCount }}</div>
          <div class="handle">
            <div class="btn" @click.stop="onToPlayRoom(item.course.id, item.id)">观看课时</div>
          </div>
        </div>
      </template>
      <template v-if="courseId && sourceData.length">
        <div class="head-wrp">
          <div class="class">班级名称</div>
          <div class="num">班级人数</div>
        </div>
        <div class="item" v-for="item in sourceData" :key="item.classId">
          <div class="class">{{ item.className }}</div>
          <div class="num">{{ item.studentCount }}</div>
        </div>
      </template>
      <div class="empty" v-if="!sourceData.length && show">
        <img src="@/assets/images/empty-course.png" alt="" />
        <div class="text">还没有任何数据哦~</div>
      </div>
    </div>
  </div>
</template>

<script>
import { courseTypes as _courseTypes } from "../../utils/options";
export default {
  name: "SoulProCMyCourse",
  data() {
    return {
      show: false,
      type: "class",
      classId: "",
      className: "",
      pageData: {
        index: 1,
        size: 10,
        total: 1,
      },
      sourceData: [],
    };
  },
  computed: {
    courseTypes() {
      return _courseTypes;
    },
  },
  created() {
    this.classId = this.$route.query.classId || "";
    this.className = this.$route.query.className || "";
    this.courseId = this.$route.query.courseId || "";
    this.courseName = this.$route.query.courseName || "";
    if (this.classId) {
      this.getCourseList();
    } else {
      this.getClassList();
    }
  },
  methods: {
    getCourseList() {
      this.$request
        .onGetClassCourseListApi({
          classId: this.classId,
        })
        .then((res) => {
          this.sourceData = res.result.classCourses || [];
          this.show = true;
        });
    },
    getClassList() {
      this.$request
        .getClassByCourseApi({ id: this.courseId })
        .then((res) => {
          this.sourceData = res.result.classInfos || [];
          this.show = true;
        });
    },
    onToPlayRoom(courseId, classCourseId) {
      this.$router.push(`/course-center/play-room?courseId=${courseId}&classCourseId=${classCourseId}`);
    },
  },
};
</script>

<style lang="less" scoped>
.course-list-wrp {
  .head-wrp,
  .item {
    display: flex;
    height: 60px;
    line-height: 60px;
    font-size: 16px;
    color: #666;
    overflow: hidden;
    .thumb {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 20px;
      width: 150px;
      img {
        width: 120px;
        height: 84px;
        border-radius: 6px;
      }
    }
    .class {
      padding-left: 20px;
      width: 260px;
      text-align: left;
    }
    .name {
      flex: 1;
      width: 500px;
      text-align: left;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .type {
      flex: 1;
      width: 300px;
      text-align: center;
    }
    .num {
      flex: 1;
      width: 300px;
      text-align: center;
    }
    .handle {
      display: flex;
      justify-content: flex-end;
      padding-right: 20px;
      min-width: 120px;
    }
  }
  .head-wrp {
    font-weight: bold;
    font-size: 16px;
    border-bottom: 1px solid rgba(255, 95, 25, 0.1);
  }
  .item {
    height: 100px;
    line-height: 100px;
    border-bottom: 1px solid rgba(255, 95, 25, 0.1);
    .handle .btn {
      color: #ffaf8c;
      cursor: pointer;
      transition: all 0.15s;
      &:hover {
        color: #ff5f19;
      }
    }
  }
  .empty {
    display: block;
    margin: 110px auto 0;
    width: 234px;
    height: 294px;
  }
}
</style>
